// component
import Iconify from '../components/Iconify';
// ----------------------------------------------------------------------
const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;


const navConfig = [
  {
    title: "Trang chủ",
    path: '',
    icon: getIcon('eva:home-outline'),
  },
  {
    title: "Giỏ hàng",
    path: '/ordercard',
    icon: getIcon('eva:shopping-cart-outline'),
  },
  {
    title: "Đặt hàng",
    path: '/orderbillcard',
    icon: getIcon('eva:book-open-outline'),
  },
  {
    title: "Nội dung",
    path: '/codelist',
    icon: getIcon('eva:settings-outline'),
  },
  {
    title: "Sản phẩm",
    path: '/listproducts',
    icon: getIcon('eva:keypad-outline'),
  },
  {
    title: "Hóa Đơn",
    path: '/listbills',
    icon: getIcon('eva:book-open-outline'),
  },
  {
    title: "Thu chi",
    path: '/monneymanager',
    icon: getIcon('eva:cube-outline'),
  },
  {
    title: "Khách hàng",
    path: '/accountmainternance',
    icon: getIcon('eva:people-outline'),
  },
  {
    title: "Tài khoản",
    path: '/accountmanagement',
    icon: getIcon('eva:people-outline'),
  },
  {
    title: 'Đăng nhập',
    path: '/login',
    icon: getIcon('eva:person-outline'),
  },
  {
    title: 'Đăng kí',
    path: '/register',
    icon: getIcon('eva:person-add-outline'),
  },
];
export default navConfig;