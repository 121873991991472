import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Grid, Button, Typography } from '@mui/material';
import constS from '../const/constS';
import ExButtonIconBef from './ExButtonIconBef';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function ExModalRuler({ isShow, DataSource, refClose }) {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  const item = DataSource.filter((p) => String(p.code).includes(isShow));
  let listDescription = [];
  if (item && item.length > 0) {
    listDescription = item[0].image.split(constS.END, 100);
  }
  let title = {};
  if (listDescription && listDescription.length > 0) {
    title = listDescription[0];
    listDescription = listDescription.filter((e) => e !== title)
  }

  return (
    <div>
      <Dialog
        maxWidth={800}
        open={isShow !== constS.BLANK ? true : false}
        PaperComponent={PaperComponent}
      >
        <DialogTitle key={"1"}>
          <Grid container rowSpacing={0} spacing={0} sx={{ m: 0, p: 0 }}>
            {title && <Grid item md={isMobile ? 9 : 10}>
              <Button onTouchStart={refClose} onClick={refClose} sx={{ p: 0, textAlign: "left" }}>
                <Typography variant="button" fontSize={isMobile ? constS.fontSizeM : constS.fontSize} color="limegreen">{title}</Typography>
              </Button>
            </Grid>}
            <Grid item md={isMobile ? 3 : 2} sx={{ textAlign: "right" }}>
              <ExButtonIconBef fullWidth title={"Đóng"} icon='\static\illustrations\cancle.png'
                onClick={refClose} onTouchStart={refClose} />
            </Grid>
            {listDescription.map((item) => (
              <Grid item md={12} sx={{ mb: 1, fontWeight: "normal", fontSize: isMobile ? constS.fontSizeM : constS.fontSize }}>{item}</Grid>
            ))}
          </Grid>
        </DialogTitle>
      </Dialog>
    </div>
  );
}
