import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, push, set, onValue, update } from "firebase/database";
import firebaseDB from '../initfirebase'
import { Container, Typography, Button, Paper, Grid, InputLabel } from '@mui/material';
import { useEffect, useState } from 'react';
import { merge } from 'lodash';
import Page from "../components/Page";
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import QrCodeOutlinedIcon from '@mui/icons-material/QrCodeOutlined';
import constM from '../const/constM';
import constS from '../const/constS';
import { useNavigate } from 'react-router-dom';
import ExTextField from "../components/ExTextField";
import ExNumber from "../components/ExNumber";
import ExComboBox from "../components/ExComboBox";
import ExMessageOK from "../components/ExMessageOK";
import ExButton from "../components/ExButton";
import ExMessageYes from "../components/ExMessageYes";
import ExCardProduct from "../components/ExCardProduct";
import ExButtonIconBef from "../components/ExButtonIconBef";
import ExButtonIconAft from "../components/ExButtonIconAft";
// ----------------------------------------------------------------------

const defaultCombo = { code: constS.ZERO, name: "" };

export default function ProductList() {
  const formatter = new Intl.NumberFormat('vi', { style: 'currency', currency: 'VND' });
  const date = new Date();
  const curDate = date.getFullYear().toString()
    + (date.getMonth() + 1).toString().padStart(2, "0")
    + date.getDate().toString().padStart(2, "0");

  const navigate = useNavigate();
  const [showYesMessage, setShowYesMessage] = useState(constS.BLANK);
  const [showOKMessage, setShowOKMessage] = useState(constS.BLANK);
  const [dataList, setDataList] = useState([]);
  const [loginUser, setLoginUser] = useState(null);
  const [listAccount, setListAccount] = useState([]);
  const [dataSelected, setDataSelected] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);

  const [dataOperatorTypeCombo, setDataOperatorTypeCombo] = useState([]);
  const [dataNameCombo, setDataNameCombo] = useState([]);
  const [dataTypeCombo, setDataTypeCombo] = useState([]);
  const [dataActiveCombo, setDataActiveCombo] = useState([]);

  const [valDefaultKey, setValDefaultKey] = useState(constS.BLANK);
  const [valDefault01, setValDefault01] = useState(defaultCombo);
  const [valDefault02, setValDefault02] = useState(constS.ZERO);
  const [valDefault04, setValDefault04] = useState(constS.ZERO);
  const [valDefault05, setValDefault05] = useState(constS.ZERO);
  const [valDefault06, setValDefault06] = useState(constS.ZERO);
  const [valDefault07, setValDefault07] = useState(constS.BLANK);
  const [valDefault08, setValDefault08] = useState(constS.BLANK);
  const [valDefault89, setValDefault89] = useState(defaultCombo);

  const [valButton01, setValButton01] = useState(constS.BLANK);
  const [valButton02, setValButton02] = useState(constS.BLANK);
  const [valButton03, setValButton03] = useState(constS.BLANK);
  const [valButton04, setValButton04] = useState(constS.BLANK);
  const [valButton05, setValButton05] = useState(constS.BLANK);
  const [valButton06, setValButton06] = useState(constS.BLANK);
  const [valButton07, setValButton07] = useState(constS.BLANK);
  const [valButton08, setValButton08] = useState(constS.BLANK);
  const [valButton09, setValButton09] = useState(constS.BLANK);
  const [valButton10, setValButton10] = useState(constS.BLANK);
  const [valButton11, setValButton11] = useState(constS.BLANK);
  const [valButton12, setValButton12] = useState(constS.BLANK);
  const [valButton13, setValButton13] = useState(constS.BLANK);
  const [valButton14, setValButton14] = useState(constS.BLANK);

  const [valDefaultCreateDate, setValDefaultCreateDate] = useState(constS.BLANK);
  const [valDefaultCreateUser, setValDefaultCreateUser] = useState(constS.BLANK);

  const db = getDatabase(firebaseDB);

  async function CheckDataLogin() {
    const auth = getAuth(firebaseDB);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        listAccount.forEach((row) => {
          if (row.Cols02 === user.email.substring(0, user.email.length - 10)) {
            setLoginUser(row);
            if (row && row.Cols03 !== "9") {
              navigate('/', { replace: true });
            }
            return;
          }
        });
      }

      if (!user) { navigate('/', { replace: false }); }
    });
  };

  async function fetchDataSearch(tbName) {
    const dbRef = ref(db, tbName);
    const result = [];
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        const obj = childSnapshot.exportVal();
        const item = dataNameCombo.filter((p) => String(p.code).includes(obj.Cols01))[0];
        result.push(merge({
          id: childSnapshot.key,
          Cols04Name: formatter.format(obj.Cols04),
          Cols05Name: formatter.format(obj.Cols05),
          Cols06Name: formatter.format(obj.Cols06),
          Cols01Name: (item ? item.name : constS.BLANK)
        }, obj));
      });
      const sortData = [].concat(result)
        .sort((a, b) => Number(a.Cols01) > Number(b.Cols01) ? 1 : -1)
      if (tbName === constS.ProductListTBN) {
        if (valDefault01.code !== constS.ZERO) {
          const data = sortData.filter(p => String(p.Cols01).includes(valDefault01.code));
          const datas = [].concat(data).splice(0, 10);
          setDataList(data);
          setCurrentData(datas);
          setCurrentPage(1);
        } else {
          let filter = "";
          if (valButton01 !== constS.BLANK || valButton07 !== constS.BLANK ||
            valButton02 !== constS.BLANK || valButton08 !== constS.BLANK ||
            valButton03 !== constS.BLANK || valButton09 !== constS.BLANK ||
            valButton04 !== constS.BLANK || valButton10 !== constS.BLANK ||
            valButton05 !== constS.BLANK || valButton11 !== constS.BLANK ||
            valButton06 !== constS.BLANK || valButton12 !== constS.BLANK) {
            const data = sortData.filter(p => (valButton01 !== constS.BLANK ? String(p.Cols03).includes(valButton01) : false) ||
              (valButton02 !== constS.BLANK ? String(p.Cols03).includes(valButton02) : false) ||
              (valButton03 !== constS.BLANK ? String(p.Cols03).includes(valButton03) : false) ||
              (valButton04 !== constS.BLANK ? String(p.Cols03).includes(valButton04) : false) ||
              (valButton05 !== constS.BLANK ? String(p.Cols03).includes(valButton05) : false) ||
              (valButton06 !== constS.BLANK ? String(p.Cols03).includes(valButton06) : false) ||
              (valButton07 !== constS.BLANK ? String(p.Cols03).includes(valButton07) : false) ||
              (valButton08 !== constS.BLANK ? String(p.Cols03).includes(valButton08) : false) ||
              (valButton09 !== constS.BLANK ? String(p.Cols03).includes(valButton09) : false) ||
              (valButton10 !== constS.BLANK ? String(p.Cols03).includes(valButton10) : false) ||
              (valButton11 !== constS.BLANK ? String(p.Cols03).includes(valButton11) : false) ||
              (valButton12 !== constS.BLANK ? String(p.Cols03).includes(valButton12) : false)
            );

            const datas = [].concat(data).splice(0, 10);
            setDataList(data);
            setCurrentData(datas);
            setCurrentPage(1);
          } else {
            if (valButton13 !== constS.BLANK ||
              valButton14 !== constS.BLANK) {
              filter = valButton13 + constS.COMPA + valButton14;
            }
            const data = sortData.filter(p => (valDefault89.code === constS.ZERO ? true : valDefault89.code === constS.Code1 ?
              Number(valDefault02) < Number(p.Cols02) : valDefault89.code === constS.Code2 ?
                Number(valDefault02) === Number(p.Cols02) : Number(valDefault02) > Number(p.Cols02)) &&
              (filter !== constS.BLANK ? filter.includes(String(p.Cols09)) : true)
            );

            const datas = [].concat(data).splice(0, 10);
            setDataList(data);
            setCurrentData(datas);
            setCurrentPage(1);
          }
        }
      };

    }, {
      onlyOnce: true
    });
    return result;
  };

  async function fetchDataInit(tbName) {
    const dbRef = ref(db, tbName);
    const result = [];
    onValue(dbRef, (snapshot) => {
      if (tbName === constS.ProductListTBN) {
        snapshot.forEach((childSnapshot) => {
          const obj = childSnapshot.exportVal();
          const item = dataNameCombo.filter((p) => String(p.code).includes(obj.Cols01))[0];
          result.push(merge({
            id: childSnapshot.key,
            Cols04Name: formatter.format(obj.Cols04),
            Cols05Name: formatter.format(obj.Cols05),
            Cols06Name: formatter.format(obj.Cols06),
            Cols01Name: (item ? item.name : constS.BLANK)
          }, obj));
        });
        const sortData = [].concat(result)
          .sort((a, b) => Number(a.Cols01) > Number(b.Cols01) ? 1 : -1)

        const curpage = Number(currentPage);
        const last = Number(curpage) * 10 > sortData.length ? sortData.length : Number(curpage) * 10;
        const first = Number(curpage - 1) * 10;
        const data = [].concat(sortData).splice(first, last - first);
        setDataList(sortData);
        setCurrentData(data);
      };

      if (tbName === constS.AccountListTBL) {
        snapshot.forEach((childSnapshot) => {
          result.push(childSnapshot.exportVal());
        });
        setListAccount(result);
      };

    }, {
      onlyOnce: true
    });
    return result;
  };

  async function fetchDataCombo(tbName) {
    const dbRef = ref(db, tbName);
    const result = [];
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        result.push(childSnapshot.exportVal());
      });
      const sortData = [].concat(result)
        .sort((a, b) => Number(a.code) > Number(b.code) ? 1 : -1)

      if (tbName === constS.OperatorTBL) {
        setDataOperatorTypeCombo(sortData);
      }
      if (tbName === constS.ProductNameTBL) {
        setDataNameCombo(sortData);
      }
      if (tbName === constS.ProductTypeTBL) {
        setDataTypeCombo(sortData);
      }
      if (tbName === constS.StatusActiveTBL) {
        setDataActiveCombo(sortData);
      }
    }, {
      onlyOnce: true
    });
    return result;
  };

  useEffect(() => {
    if (dataSelected) {
      const val03 = String(dataSelected.Cols03).split(constS.COMPA, 20);
      val03.forEach((item) => {
        if (item === constS.Button01) {
          setValButton01(item);
        }
        if (item === constS.Button02) {
          setValButton02(item);
        }
        if (item === constS.Button03) {
          setValButton03(item);
        }
        if (item === constS.Button04) {
          setValButton04(item);
        }
        if (item === constS.Button05) {
          setValButton05(item);
        }
        if (item === constS.Button06) {
          setValButton06(item);
        }
        if (item === constS.Button07) {
          setValButton07(item);
        }
        if (item === constS.Button08) {
          setValButton08(item);
        }
        if (item === constS.Button09) {
          setValButton09(item);
        }
        if (item === constS.Button10) {
          setValButton10(item);
        }
        if (item === constS.Button11) {
          setValButton11(item);
        }
        if (item === constS.Button12) {
          setValButton12(item);
        }
      });

      if (dataSelected.Cols09 === constS.Code1) {
        setValButton13(constS.Code1);
      }
      if (dataSelected.Cols09 === constS.Code2) {
        setValButton14(constS.Code2);
      }
    }
  }, [dataSelected]);

  useEffect(() => {
    if (dataNameCombo && dataNameCombo.length > 0) {
      fetchDataInit(constS.ProductListTBN);
    }
  }, [dataNameCombo]);

  useEffect(() => {
    fetchDataInit(constS.AccountListTBL);
  }, []);

  useEffect(() => {
    CheckDataLogin();
    fetchDataCombo(constS.OperatorTBL);
    fetchDataCombo(constS.ProductNameTBL);
    fetchDataCombo(constS.ProductTypeTBL);
    fetchDataCombo(constS.StatusActiveTBL);
  }, [listAccount]);

  const getFormData = (mode) => {
    let val03 = constS.BLANK;
    val03 = val03 + (valButton01 !== constS.BLANK ? valButton01 + constS.COMPA : "");
    val03 = val03 + (valButton02 !== constS.BLANK ? valButton02 + constS.COMPA : "");
    val03 = val03 + (valButton03 !== constS.BLANK ? valButton03 + constS.COMPA : "");
    val03 = val03 + (valButton04 !== constS.BLANK ? valButton04 + constS.COMPA : "");
    val03 = val03 + (valButton05 !== constS.BLANK ? valButton05 + constS.COMPA : "");
    val03 = val03 + (valButton06 !== constS.BLANK ? valButton06 + constS.COMPA : "");
    val03 = val03 + (valButton07 !== constS.BLANK ? valButton07 + constS.COMPA : "");
    val03 = val03 + (valButton08 !== constS.BLANK ? valButton08 + constS.COMPA : "");
    val03 = val03 + (valButton09 !== constS.BLANK ? valButton09 + constS.COMPA : "");
    val03 = val03 + (valButton10 !== constS.BLANK ? valButton10 + constS.COMPA : "");
    val03 = val03 + (valButton11 !== constS.BLANK ? valButton11 + constS.COMPA : "");
    val03 = val03 + (valButton12 !== constS.BLANK ? valButton12 + constS.COMPA : "");

    const formData = {
      Cols01: valDefault01.code,
      Cols02: valDefault02,
      Cols03: val03,
      Cols04: valDefault04,
      Cols05: valDefault05,
      Cols06: valDefault06,
      Cols07: valDefault07,
      Cols08: valDefault08,
      Cols09: valButton13 !== constS.BLANK ? valButton13 : valButton14,
      Cols96: curDate,
      Cols97: loginUser.Cols02,
      Cols98: mode === constS.MODE_ADD ? curDate : valDefaultCreateDate,
      Cols99: mode === constS.MODE_ADD ? loginUser.Cols02 : valDefaultCreateUser,
    };
    return formData;
  };

  const onHandleSearch = () => {
    if (valDefault01.code === constS.ZERO && valDefault89.code === constS.ZERO &&
      valButton01 === constS.BLANK && valButton08 === constS.BLANK &&
      valButton02 === constS.BLANK && valButton09 === constS.BLANK &&
      valButton03 === constS.BLANK && valButton10 === constS.BLANK &&
      valButton04 === constS.BLANK && valButton11 === constS.BLANK &&
      valButton05 === constS.BLANK && valButton12 === constS.BLANK &&
      valButton06 === constS.BLANK && valButton13 === constS.BLANK &&
      valButton07 === constS.BLANK && valButton14 === constS.BLANK) {
      setShowOKMessage("Vui lòng nhập điều kiện tìm kiếm!");
      return;
    }

    fetchDataSearch(constS.ProductListTBN);
  };

  const onHandleAdd = () => {
    if (valDefault01.code === constS.ZERO) {
      setShowOKMessage("Vui lòng chọn tên sản phẩm!");
      return;
    }

    const list = dataList.filter((p) => String(p.Cols01).includes(valDefault01.code));
    if (list && list.length > 0) {
      setShowOKMessage("Sản phẩm đã tồn tại không thể thêm mới!");
      return;
    }

    if (valDefault02 === constS.BLANK) {
      setShowOKMessage("Vui lòng nhập số lượng tồn kho!");
      return;
    }

    if (valDefault04 === constS.BLANK || Number(valDefault04) <= 0) {
      setShowOKMessage("Vui lòng nhập giá nhập hàng!");
      return;
    }

    if (valDefault05 === constS.BLANK || Number(valDefault05) <= 0) {
      setShowOKMessage("Vui lòng nhập giá bán lẻ!");
      return;
    }

    if (valDefault06 === constS.BLANK || Number(valDefault06) <= 0) {
      setShowOKMessage("Vui lòng nhập giá bán sỉ!");
      return;
    }

    if (valDefault07 === constS.BLANK) {
      setShowOKMessage("Vui lòng nhập mô tả!");
      return;
    }

    if (valDefault08 === constS.BLANK) {
      setShowOKMessage("Vui lòng nhập hình ảnh!");
      return;
    }

    if (valButton01 === constS.BLANK && valButton08 === constS.BLANK &&
      valButton02 === constS.BLANK && valButton09 === constS.BLANK &&
      valButton03 === constS.BLANK && valButton10 === constS.BLANK &&
      valButton04 === constS.BLANK && valButton11 === constS.BLANK &&
      valButton05 === constS.BLANK && valButton12 === constS.BLANK &&
      valButton06 === constS.BLANK &&
      valButton07 === constS.BLANK) {
      setShowOKMessage("Vui lòng chọn phân loại sản phẩm!");
      return;
    }

    if (valButton13 === constS.BLANK && valButton14 === constS.BLANK) {
      setShowOKMessage("Vui lòng chọn hoạt động sản phẩm!");
      return;
    }

    if (valButton13 !== constS.BLANK && valButton14 !== constS.BLANK) {
      setShowOKMessage("Vui lòng chọn một loại hoạt động!");
      return;
    }

    const cusTypes = ref(db, constS.ProductListTBN);
    const newCusTypes = push(cusTypes);
    set(newCusTypes, getFormData(constS.MODE_ADD));
    fetchDataInit(constS.ProductListTBN);
    setShowYesMessage("Đăng kí thông tin thành công!");
  };

  const onHandleUpdate = () => {
    if (valDefaultKey === constS.BLANK) {
      setShowOKMessage("Vui lòng chọn mã sản phẩm!");
      return;
    }

    if (valDefault01.code === constS.ZERO) {
      setShowOKMessage("Vui lòng chọn tên sản phẩm!");
      return;
    }

    if (valDefault02 === constS.BLANK) {
      setShowOKMessage("Vui lòng nhập số lượng tồn kho!");
      return;
    }

    if (valDefault04 === constS.BLANK || Number(valDefault04) <= 0) {
      setShowOKMessage("Vui lòng nhập giá nhập hàng!");
      return;
    }

    if (valDefault05 === constS.BLANK || Number(valDefault05) <= 0) {
      setShowOKMessage("Vui lòng nhập giá bán lẻ!");
      return;
    }

    if (valDefault06 === constS.BLANK || Number(valDefault06) <= 0) {
      setShowOKMessage("Vui lòng nhập giá bán sỉ!");
      return;
    }

    if (valDefault07 === constS.BLANK) {
      setShowOKMessage("Vui lòng nhập mô tả!");
      return;
    }

    if (valDefault08 === constS.BLANK) {
      setShowOKMessage("Vui lòng nhập hình ảnh!");
      return;
    }

    if (valButton01 === constS.BLANK && valButton08 === constS.BLANK &&
      valButton02 === constS.BLANK && valButton09 === constS.BLANK &&
      valButton03 === constS.BLANK && valButton10 === constS.BLANK &&
      valButton04 === constS.BLANK && valButton11 === constS.BLANK &&
      valButton05 === constS.BLANK && valButton12 === constS.BLANK &&
      valButton06 === constS.BLANK &&
      valButton07 === constS.BLANK) {
      setShowOKMessage("Vui lòng chọn phân loại sản phẩm!");
      return;
    }

    if (valButton13 === constS.BLANK && valButton14 === constS.BLANK) {
      setShowOKMessage("Vui lòng chọn hoạt động sản phẩm!");
      return;
    }

    if (valButton13 !== constS.BLANK && valButton14 !== constS.BLANK) {
      setShowOKMessage("Vui lòng chọn một loại hoạt động!");
      return;
    }


    update(ref(db, `/` + constS.ProductListTBN + `/${valDefaultKey}/`), getFormData(''));
    fetchDataInit(constS.ProductListTBN);
    setShowYesMessage("Cập nhật thông tin thành công!");
  };

  const onHandleClear = () => {
    setValDefaultKey(constS.BLANK);
    setValDefault01(defaultCombo);
    setValDefault02(constS.BLANK);
    setValDefault04(constS.BLANK);
    setValDefault05(constS.BLANK);
    setValDefault06(constS.BLANK);
    setValDefault07(constS.BLANK);
    setValDefault08(constS.BLANK);

    setValDefault89(defaultCombo);

    setValButton01(constS.BLANK);
    setValButton02(constS.BLANK);
    setValButton03(constS.BLANK);
    setValButton04(constS.BLANK);
    setValButton05(constS.BLANK);
    setValButton06(constS.BLANK);
    setValButton07(constS.BLANK);
    setValButton08(constS.BLANK);
    setValButton09(constS.BLANK);
    setValButton10(constS.BLANK);
    setValButton11(constS.BLANK);
    setValButton12(constS.BLANK);
    setValButton13(constS.BLANK);
    setValButton14(constS.BLANK);

    setDataSelected([]);
    fetchDataInit(constS.ProductListTBN);
  };

  const refColsKey = (e) => {
    setValDefaultKey(e.target.value);
  };

  const refCols01 = (event, value) => {
    if (value !== null) { setValDefault01(value); }
    else {
      setValDefault01(defaultCombo);
    }
  };

  const refCols02 = (e) => {
    setValDefault02(e.target.value);
  };

  const refCols04 = (e) => {
    setValDefault04(e.target.value);
  };

  const refCols05 = (e) => {
    setValDefault05(e.target.value);
  };

  const refCols06 = (e) => {
    setValDefault06(e.target.value);
  };

  const refCols07 = (e) => {
    setValDefault07(e.target.value);
  };

  const refCols08 = (e) => {
    setValDefault08(e.target.value);
  };

  const refCols89 = (event, value) => {
    if (value !== null) { setValDefault89(value); }
    else {
      setValDefault89(defaultCombo);
    }
  };

  const refButton01 = (code, val) => {
    if (val === constS.BLANK) {
      setValButton01(code);
    }
    else {
      setValButton01(constS.BLANK);
    }
  };

  const refButton02 = (code, val) => {
    if (val === constS.BLANK) {
      setValButton02(code);
    }
    else {
      setValButton02(constS.BLANK);
    }
  };

  const refButton03 = (code, val) => {
    if (val === constS.BLANK) {
      setValButton03(code);
    }
    else {
      setValButton03(constS.BLANK);
    }
  };

  const refButton04 = (code, val) => {
    if (val === constS.BLANK) {
      setValButton04(code);
    }
    else {
      setValButton04(constS.BLANK);
    }
  };

  const refButton05 = (code, val) => {
    if (val === constS.BLANK) {
      setValButton05(code);
    }
    else {
      setValButton05(constS.BLANK);
    }
  };

  const refButton06 = (code, val) => {
    if (val === constS.BLANK) {
      setValButton06(code);
    }
    else {
      setValButton06(constS.BLANK);
    }
  };

  const refButton07 = (code, val) => {
    if (val === constS.BLANK) {
      setValButton07(code);
    }
    else {
      setValButton07(constS.BLANK);
    }
  };

  const refButton08 = (code, val) => {
    if (val === constS.BLANK) {
      setValButton08(code);
    }
    else {
      setValButton08(constS.BLANK);
    }
  };

  const refButton09 = (code, val) => {
    if (val === constS.BLANK) {
      setValButton09(code);
    }
    else {
      setValButton09(constS.BLANK);
    }
  };

  const refButton10 = (code, val) => {
    if (val === constS.BLANK) {
      setValButton10(code);
    }
    else {
      setValButton10(constS.BLANK);
    }
  };

  const refButton11 = (code, val) => {
    if (val === constS.BLANK) {
      setValButton11(code);
    }
    else {
      setValButton11(constS.BLANK);
    }
  };

  const refButton12 = (code, val) => {
    if (val === constS.BLANK) {
      setValButton12(code);
    }
    else {
      setValButton12(constS.BLANK);
    }
  };

  const refButton13 = (code, val) => {
    if (val === constS.BLANK) {
      setValButton13(code);
    }
    else {
      setValButton13(constS.BLANK);
    }
  };

  const refButton14 = (code, val) => {
    if (val === constS.BLANK) {
      setValButton14(code);
    }
    else {
      setValButton14(constS.BLANK);
    }
  };

  const refOK = () => {
    setShowOKMessage(constS.BLANK);
    setShowYesMessage(constS.BLANK);
  };

  const onClickSelected = (val) => {
    setDataSelected(val);
    setValDefaultKey(val.id);

    if (val.Cols01 === constS.ZERO) {
      setValDefault01(defaultCombo);
    }
    else {
      dataNameCombo.forEach((ele) => {
        if (ele.code === val.Cols01) { setValDefault01(ele); };
      });
    };

    setValDefault02(val.Cols02);
    setValButton01(constS.BLANK);
    setValButton02(constS.BLANK);
    setValButton03(constS.BLANK);
    setValButton04(constS.BLANK);
    setValButton05(constS.BLANK);
    setValButton06(constS.BLANK);
    setValButton07(constS.BLANK);
    setValButton08(constS.BLANK);
    setValButton09(constS.BLANK);
    setValButton10(constS.BLANK);
    setValButton11(constS.BLANK);
    setValButton12(constS.BLANK);
    setValButton13(constS.BLANK);
    setValButton14(constS.BLANK);

    setValDefault04(val.Cols04);
    setValDefault05(val.Cols05);
    setValDefault06(val.Cols06);
    setValDefault07(val.Cols07);
    setValDefault08(val.Cols08);

    setValDefaultCreateDate(val.Cols98);
    setValDefaultCreateUser(val.Cols99);
  };

  const onClickPrev = () => {
    if (currentPage === 1) {
      return;
    }
    const curpage = currentPage - 1;
    const last = Number(curpage) * 10;
    const first = Number(last) - 10;
    const data = [].concat(dataList).splice(first, last - first);
    setCurrentData(data);
    setCurrentPage(curpage);
  };

  const onClickNext = () => {
    if (Number(currentPage) * 10 > dataList.length) {
      return;
    }
    const curpage = Number(currentPage) + 1;
    const last = Number(curpage) * 10 > dataList.length ? dataList.length : Number(curpage) * 10;
    const first = Number(curpage - 1) * 10;
    const data = [].concat(dataList).splice(first, last - first);
    setCurrentData(data);
    setCurrentPage(curpage);
  };

  return [
    <Page title={constM.listProducts}>
      <ExMessageYes message={showYesMessage} refOK={refOK}></ExMessageYes>
      <ExMessageOK message={showOKMessage} refOK={refOK}></ExMessageOK>
      <Container maxWidth="xl">
        <Typography variant={"h1"}>{constM.listMainternance + " " + constM.listProducts}</Typography>
        <Paper variant="outlined">
          <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 2, fontSize: 40 }}>
            <Grid item md={11.5}>{"Mã sản phẩm"}</Grid>
            <Grid item md={11.5}>
              <ExTextField
                id={constS.id} inputRef={refColsKey} valDefault={valDefaultKey}
                placeholder={constM.placeKey} Icon={<QrCodeOutlinedIcon />} maxLength={100} disabled={true}></ExTextField>
            </Grid>
            <Grid item md={11.5}>{"Tên sản phẩm"}</Grid>
            <Grid item md={11.5}>
              <ExComboBox
                id={constS.Cols01} comboRef={refCols01} valDefault={valDefault01}
                placeholder={constM.placeName} DataSource={dataNameCombo}></ExComboBox>
            </Grid>
            <Grid item md={4}>{"Tồn kho"}</Grid>
            <Grid item md={5}>
              <ExNumber
                id={constS.Cols02} inputRef={refCols02} valDefault={valDefault02}
                placeholder={constM.placeInventory} Icon={<Inventory2OutlinedIcon />} maxLength={100}></ExNumber>
            </Grid>
            <Grid item md={2.5}>
              <ExComboBox
                id={constS.Cols89} comboRef={refCols89} valDefault={valDefault89}
                placeholder={constM.placeOperatorType} DataSource={dataOperatorTypeCombo}></ExComboBox>
            </Grid>
            <Grid item md={4}>{"Giá nhập hàng"}
            </Grid>
            <Grid item md={7.5}>
              <ExNumber
                id={constS.Cols04} inputRef={refCols04} valDefault={valDefault04}
                placeholder={constM.placeImportPrice} Icon={<AddCardOutlinedIcon />} maxLength={100}></ExNumber>
            </Grid>
            <Grid item md={4}>{"Giá bán lẻ"}
            </Grid>
            <Grid item md={7.5}>
              <ExNumber
                id={constS.Cols05} inputRef={refCols05} valDefault={valDefault05}
                placeholder={constM.placeRetailPrice} Icon={<AttachMoneyOutlinedIcon />} maxLength={100}></ExNumber>
            </Grid>
            <Grid item md={4}>{"Giá bán sỉ"}
            </Grid>
            <Grid item md={7.5}>
              <ExNumber
                id={constS.Cols06} inputRef={refCols06} valDefault={valDefault06}
                placeholder={constM.placeWholesalePrice} Icon={<LocalAtmOutlinedIcon />} maxLength={100}></ExNumber>
            </Grid>

            <Grid item md={11.5}>{"Mô tả"}
            </Grid>
            <Grid item md={11.5}>
              <ExTextField
                id={constS.Cols07} inputRef={refCols07} valDefault={valDefault07}
                placeholder={constM.placeDescription} multiline={true} maxLength={20000}></ExTextField>
            </Grid>
            <Grid item md={11.5}>{"Hình ảnh"}
            </Grid>
            <Grid item md={11.5}>
              <ExTextField
                id={constS.Cols08} inputRef={refCols08} valDefault={valDefault08}
                placeholder={constM.placeImage} multiline={true} maxLength={20000}></ExTextField>
            </Grid>

            <Grid item md={11.5}>{"Phân loại sản phẩm"}</Grid>
            {dataTypeCombo[1] && <Grid item ><ExButton props={dataTypeCombo[1]} inputRef={refButton01} valDefault={valButton01} /></Grid>}
            {dataTypeCombo[2] && <Grid item ><ExButton props={dataTypeCombo[2]} inputRef={refButton02} valDefault={valButton02} /></Grid>}
            {dataTypeCombo[3] && <Grid item  ><ExButton props={dataTypeCombo[3]} inputRef={refButton03} valDefault={valButton03} /></Grid>}
            {dataTypeCombo[4] && <Grid item  ><ExButton props={dataTypeCombo[4]} inputRef={refButton04} valDefault={valButton04} /></Grid>}
            {dataTypeCombo[5] && <Grid item  ><ExButton props={dataTypeCombo[5]} inputRef={refButton05} valDefault={valButton05} /></Grid>}
            {dataTypeCombo[6] && <Grid item  ><ExButton props={dataTypeCombo[6]} inputRef={refButton06} valDefault={valButton06} /></Grid>}
            {dataTypeCombo[7] && <Grid item  ><ExButton props={dataTypeCombo[7]} inputRef={refButton07} valDefault={valButton07} /></Grid>}
            {dataTypeCombo[8] && <Grid item  ><ExButton props={dataTypeCombo[8]} inputRef={refButton08} valDefault={valButton08} /></Grid>}
            {dataTypeCombo[9] && <Grid item ><ExButton props={dataTypeCombo[9]} inputRef={refButton09} valDefault={valButton09} /></Grid>}
            {dataTypeCombo[10] && <Grid item><ExButton props={dataTypeCombo[10]} inputRef={refButton10} valDefault={valButton10} /></Grid>}
            {dataTypeCombo[11] && <Grid item ><ExButton props={dataTypeCombo[11]} inputRef={refButton11} valDefault={valButton11} /></Grid>}
            {dataTypeCombo[12] && <Grid item  ><ExButton props={dataTypeCombo[12]} inputRef={refButton12} valDefault={valButton12} /></Grid>}
            <Grid item md={11.5}>{"Trạng thái sản phẩm"}</Grid>
            {dataActiveCombo[1] && <Grid item ><ExButton props={dataActiveCombo[1]} inputRef={refButton13} valDefault={valButton13} /></Grid>}
            {dataActiveCombo[2] && <Grid item ><ExButton props={dataActiveCombo[2]} inputRef={refButton14} valDefault={valButton14} /></Grid>}
            <Grid item md={11.5}></Grid>
            <Grid item>
              <ExButtonIconBef title={"Tìm kiếm"} icon='\static\illustrations\search.png' onClick={onHandleSearch} />
            </Grid>
            <Grid item>
              <ExButtonIconBef title={"Đăng kí"} icon='\static\illustrations\add.png' onClick={onHandleAdd} />
            </Grid>
            <Grid item>
              <ExButtonIconBef title={"Chỉnh sửa"} icon='\static\illustrations\edit.png' onClick={onHandleUpdate} />
            </Grid>
            <Grid item>
              <ExButtonIconBef title={"Xóa nhập"} icon='\static\illustrations\delete.png' onClick={onHandleClear} />
            </Grid>
          </Grid>
        </Paper>

        <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 2 }}>
          {currentData.map((item) => (
            <Grid item md={12}>
              <ExCardProduct DataSource={item} onClick={onClickSelected} selectedId={valDefaultKey}></ExCardProduct>
            </Grid>
          ))}

          <Grid item md={6} sx={{ textAlign: "left" }}>
            <ExButtonIconBef title={"Trang trước"} icon='\static\illustrations\prev.png' onClick={onClickPrev} />
          </Grid>
          <Grid item md={6} sx={{ textAlign: "right" }}>
            <ExButtonIconAft title={"Trang sau"} icon='\static\illustrations\next.png' onClick={onClickNext} />
          </Grid>

        </Grid>
      </Container>
    </Page >
  ];
}
