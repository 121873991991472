import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import { Grid, InputBase } from '@mui/material';
import { useEffect, useState } from 'react';

export default function ExNumber({ id, inputRef, inputRefCapture, valDefault, placeholder, Icon, maxLength, disabled, marginRight, endofString }) {
  const [value, changeValue] = useState(valDefault);
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  const fonts = isMobile ? 50 : 31;

  useEffect(() => {
    changeValue(valDefault);
  }, [valDefault]);

  const onHandleChange = (e) => {
    changeValue(e.target.value);
  };

  return (
    <div>
      <Paper
        component="colgroup"
        sx={{ p: '0px 4px', display: 'flex', alignItems: 'stretch', mr: marginRight || 0 }}
      >
        <IconButton sx={{ p: '3px' }} aria-label="menu">
          {Icon}
        </IconButton>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12}>
            <InputBase
              size='small'
              placeholder={placeholder}
              value={value}
              onBlur={inputRef}
              onChange={onHandleChange}
              onChangeCapture={inputRefCapture}
              id={id}
              sx={{ ml: 0, flex: 0, height: 'fix-webkit-fill-available', fontSize: fonts }}
              inputProps={{ maxLength: maxLength }}
              display="stretch"
              disabled={disabled || false}
              fullWidth
              onKeyDown={(ev) => {
                if (ev.key !== '0' && ev.key !== '1' && ev.key !== '2' && ev.key !== '3' &&
                  ev.key !== '4' && ev.key !== '5' && ev.key !== '6' && ev.key !== '7' &&
                  ev.key !== '8' && ev.key !== '9' &&
                  ev.key !== 'ArrowLeft' && ev.key !== 'ArrowRight' && ev.key !== 'F12' &&
                  ev.key !== 'Delete' && ev.key !== 'Backspace' && ev.key !== 'Tab' && ev.key !== 'End' && ev.key !== 'Home') {
                  ev.preventDefault();
                }
              }}
            />
          </Grid>
        </Grid>
        {endofString && <IconButton sx={{ p: '0px', font: 'status-bar' }} aria-label="menu">{endofString}</IconButton>}
      </Paper>
    </div >
  );
}