import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, push, set, onValue, update } from "firebase/database";
import firebaseDB from '../initfirebase'
import { useEffect, useState } from 'react';
// import { useRef } from 'react';
import { Grid, Container, Typography, Button, Chip, Avatar, Divider, Paper, CardMedia } from '@mui/material';
import Page from "../components/Page";
import { useNavigate } from 'react-router-dom';
import constM from '../const/constM';
import constS from '../const/constS';
import ExButtonIconBef from "../components/ExButtonIconBef";
// ----------------------------------------------------------------------

export default function CodeList() {
  const navigate = useNavigate();
  const db = getDatabase(firebaseDB);
  const [listAccount, setListAccount] = useState([]);

  async function CheckDataLogin() {
    const auth = getAuth(firebaseDB);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        listAccount.forEach((row) => {
          if (row.Cols02 === user.email.substring(0, user.email.length - 10)) {
            if (row && row.Cols03 !== "9") {
              navigate('/', { replace: false });
            }
            return;
          }
        });
      }

      if (!user) { navigate('/', { replace: false }); }
    });
  };

  async function fetchDataInit(tbName) {
    const dbRef = ref(db, tbName);
    const result = [];
    onValue(dbRef, (snapshot) => {
      if (tbName === constS.AccountListTBL) {
        snapshot.forEach((childSnapshot) => {
          result.push(childSnapshot.exportVal());
        });
        setListAccount(result)
      }

    }, {
      onlyOnce: true
    });
    return result;
  };

  useEffect(() => {
    fetchDataInit(constS.AccountListTBL);
  }, []);

  useEffect(() => {
    CheckDataLogin();
  }, [listAccount]);

  const onClickTypeSearch = () => {
    navigate('/codemainternance', { replace: true, state: constM.placeType + " " + constM.placeSearch });
  };

  const onClickNameProduct = () => {
    navigate('/codemainternance', { replace: true, state: constM.placeName + " " + constM.placeProduct });
  };

  const onClickTypeProduct = () => {
    navigate('/codemainternance', { replace: true, state: constM.placeType + " " + constM.placeProduct });
  };

  const onClickStatusBill = () => {
    navigate('/codemainternance', { replace: true, state: constM.placeStatus + " " + constM.placeBill });
  };

  const onClickSex = () => {
    navigate('/codemainternance', { replace: true, state: constM.placeCustomerSex });
  };

  const onClickPermission = () => {
    navigate('/codemainternance', { replace: true, state: constM.placePermission });
  };

  const onClickActive = () => {
    navigate('/codemainternance', { replace: true, state: "Trạng thái hoạt động" });
  };

  const onClickRuler = () => {
    navigate('/codemainternance', { replace: true, state: "Thêm nội dung chính sách" });
  };

  const onClickMonneyManager = () => {
    navigate('/codemainternance', { replace: true, state: "Phân loại thu chi" });
  };

  const onClickMonthManager = () => {
    navigate('/codemainternance', { replace: true, state: "Danh sách tháng" });
  };

  const onClickYearManager = () => {
    navigate('/codemainternance', { replace: true, state: "Danh sách năm" });
  };



  return (
    <Page title={constM.masterCodeLists}>
      <Container maxWidth="xl">
        <Typography variant={"h1"}>{constM.listMainternance + " " + constM.masterCodeLists}</Typography>
        <Paper variant="outlined">
          <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 2 }}>
            <Grid item>
              <ExButtonIconBef title={"Toán tử tìm kiếm"}
                icon='\static\illustrations\datawarehouse.png' onClick={onClickTypeSearch} />
            </Grid>
            <Grid item>
              <ExButtonIconBef title={"Sản phẩm"}
                icon='\static\illustrations\datawarehouse.png' onClick={onClickNameProduct} />
            </Grid>
            <Grid item>
              <ExButtonIconBef title={"Loại sản phẩm"}
                icon='\static\illustrations\datawarehouse.png' onClick={onClickTypeProduct} />
            </Grid>
            <Grid item>
              <ExButtonIconBef title={"Trạng thái hóa đơn"}
                icon='\static\illustrations\datawarehouse.png' onClick={onClickStatusBill} />
            </Grid>
            <Grid item>
              <ExButtonIconBef title={"Loại giới tính"}
                icon='\static\illustrations\datawarehouse.png' onClick={onClickSex} />
            </Grid>
            <Grid item>
              <ExButtonIconBef title={"Quyền hạn tài khoản"}
                icon='\static\illustrations\datawarehouse.png' onClick={onClickPermission} />
            </Grid>
            <Grid item>
              <ExButtonIconBef title={"Trạng thái hoạt động"}
                icon='\static\illustrations\datawarehouse.png' onClick={onClickActive} />
            </Grid>
            <Grid item>
              <ExButtonIconBef title={"Nội dung chính sách"}
                icon='\static\illustrations\datawarehouse.png' onClick={onClickRuler} />
            </Grid>
            <Grid item>
              <ExButtonIconBef title={"Phân loại thu chi"}
                icon='\static\illustrations\datawarehouse.png' onClick={onClickMonneyManager} />
            </Grid>
            <Grid item>
              <ExButtonIconBef title={"Danh sách tháng"}
                icon='\static\illustrations\datawarehouse.png' onClick={onClickMonthManager} />
            </Grid>
            <Grid item>
              <ExButtonIconBef title={"Danh sách năm"}
                icon='\static\illustrations\datawarehouse.png' onClick={onClickYearManager} />
            </Grid>
          </Grid>
        </Paper>
      </Container >
    </Page >
  );
}
