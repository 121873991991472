import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Avatar, Grid } from '@mui/material';
import constS from '../const/constS';
import ExButtonIconBef from './ExButtonIconBef';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function ExMessageOK({ message, refOK, isMobile }) {
  return (
    <div>
      <Dialog
        maxWidth={800}
        open={message !== constS.BLANK ? true : false}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <Grid container rowSpacing={0} spacing={0} sx={{ m: 0, p: 0 }}>
            <Grid item md={12}>
              <Button sx={{ p: 0, fontSize: 40, m: 0 }}>{"Thông báo lỗi"}</Button>
            </Grid>
            <Grid item md={2}>
              <Button sx={{ p: 0, fontSize: 40, m: 0 }}>
                <Avatar
                  alt="rectangle"
                  src="\static\illustrations\errorMessage.png"
                  sx={{ width: 100, height: 100 }}
                /></Button>
            </Grid>
            <Grid item md={10} sx={{ textAlign: "left", fontSize: 40 }}>{message}</Grid>
            <Grid item md={0.1}></Grid>
            <Grid item md={12} sx={{ textAlign: "right" }}>
              <ExButtonIconBef title={"Xác nhận"} icon='\static\illustrations\ok.png' onClick={refOK} onTouchStart={refOK} />
            </Grid>
          </Grid>
        </DialogTitle>
      </Dialog>
    </div>
  );
}
