import * as React from 'react';
import Card from '@mui/material/Card';
import { Grid } from "@mui/material";


export default function ExCardBillDetail({ DataSource, onClick, selectedId }) {
  return (
    <Card key={DataSource.id} onClick={() => onClick(DataSource)} sx={{
      height: 115, p: 0, m: 0,
      backgroundColor: selectedId === DataSource.id ? "greenyellow" : "white"
    }}>
      <Grid container rowSpacing={0} spacing={0} sx={{ m: 0, p: 0, fontSize: 40 }}>
        <Grid item md={12}>{"Tên : " + DataSource.Cols02Name}</Grid>
        <Grid item md={5}>{"ĐG : " + DataSource.Cols03Name}</Grid>
        <Grid item md={2}>{"SL : " + DataSource.Cols04}</Grid>
        <Grid item md={5}>{"TT : " + DataSource.Cols05Name}</Grid>
      </Grid>
    </Card >
  );
};