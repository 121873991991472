import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import { Grid, InputBase, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

export default function ExTextField({ id, inputRef, inputRefCapture, valDefault, Icon, placeholder, multiline, maxLength, disabled, endofString, isPassWord }) {
  const [value, changeValue] = useState(valDefault);
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  const fonts = isMobile ? 50 : 31;

  useEffect(() => {
    changeValue(valDefault);
  }, [valDefault]);

  const onHandleChange = (e) => {
    changeValue(e.target.value);
  };

  return (
    <div>
      {!multiline && <Paper
        component="colgroup"
        sx={{ p: '0px 4px', display: 'flex', alignItems: 'stretch' }}
      >
        <IconButton sx={{ p: '0px' }} aria-label="menu">
          {Icon}
        </IconButton>
        <Grid container spacing={0}>
          <Grid item md={12}>
            <InputBase
              placeholder={placeholder}
              value={value}
              type={!isPassWord ? 'text' : 'password'}
              onBlur={inputRef}
              onChangeCapture={inputRefCapture}
              onChange={onHandleChange}
              id={id}
              sx={{ ml: 0, flex: 0, height: 'fix-webkit-fill-available', fontSize: fonts }}
              inputProps={{ maxLength: maxLength }}
              disabled={disabled || false}
              fullWidth
              variant='outlined'
            />
          </Grid>
        </Grid>
        {endofString && <IconButton sx={{ p: '0px', font: 'status-bar' }} aria-label="menu">{endofString}</IconButton>}
      </Paper>
      }
      {
        multiline &&
        <Paper
          component="colgroup"
          sx={{ p: '0px 4px', display: 'flex', alignItems: 'stretch' }}
        >
          <Grid container spacing={0}>
            <Grid item md={12}>
              <InputBase
                placeholder={placeholder}
                value={value}
                onBlur={inputRef}
                onChangeCapture={inputRefCapture}
                onChange={onHandleChange}
                id={id}
                sx={{ ml: 0, flex: 0, height: 'fix-webkit-fill-available', fontSize: fonts }}
                inputProps={{ maxLength: maxLength }}
                disabled={disabled || false}
                multiline={multiline || false}
                fullWidth
                variant='outlined'
              />
            </Grid>
          </Grid>
        </Paper>
      }
    </div >
  );
}