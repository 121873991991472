import { Avatar, Button } from '@mui/material';

export default function ExButtonIconBef({ title, icon, onClick, fullWidth, disabled, onTouchStart }) {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  const fonts = isMobile ? 40 : 25;

  return (
    <Button fullWidth={fullWidth || false}
      disabled={disabled || false}
      sx={{ fontSize: fonts, mb: 1, backgroundColor: "#E8E8E8", color: "black", borderColor: "black" }}
      variant="outlined" onClick={onClick} onTouchStart={onTouchStart}><Avatar
        alt="rectangle"
        src={icon}
        sx={{ width: fonts, height: fonts }}
      />{title}</Button>
  );
}