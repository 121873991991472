import { getDatabase, ref, onValue, push, set } from "firebase/database";
import firebaseDB from '../initfirebase'
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { CardMedia, Card, Grid, Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';
import constS from '../const/constS';
import Typography from '@mui/material/Typography';
import { merge } from 'lodash';
import { useState, useEffect } from 'react';
import ExMessageOK from "./ExMessageOK";
import ExButtonIconBef from "./ExButtonIconBef";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
// noinspection JSCheckFunctionSignatures
const useStyles = styled({
  root: {
    position: "relative",
    width: 500,
    height: ({ fitToImageHeight }) => !fitToImageHeight && 500,
  },
  wrapper: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    height: "100%",
  },
  arrowWrapper: {},
  img: {},
});

export default function ExModalProduct({ isMobile, isShow, DataSource, dataTypeCombo, loginUser, refClose, props }) {
  const formatter = new Intl.NumberFormat('vi', { style: 'currency', currency: 'VND' });
  const date = new Date();
  const curDate = date.getDate().toString().padStart(2, "0")
    + "/" + (date.getMonth() + 1).toString().padStart(2, "0")
    + "/" + date.getFullYear().toString();
  const db = getDatabase(firebaseDB);
  const [showOKMessage, setShowOKMessage] = useState(constS.BLANK);

  let images = [];
  let autoPlay = true;
  if (DataSource.Cols08 && DataSource.Cols08.length > 0) {
    images = DataSource.Cols08.split(constS.END);
  }
  if (!images || !images.length) {
    throw new Error("images prop is required and cannot be empty.");
  }

  let defaultOptions = {
    arrows: true,
    autoPlay: true,
    arrowsColor: "dimgrey",
    arrowsBgColor: "transparent",
    arrowsBgHoverColor: "#B9B9B95E",
    alwaysShowArrows: false,
    fitToImageHeight: false,
  };
  let options = Object.assign({}, defaultOptions, props);
  const [currentImage, setCurrentImage] = useState(0);
  const [direction, setDirection] = useState("left");
  const [autoPlayTimeout, setAutoPlayTimeout] = useState();

  async function fetchData(tbName) {
    const dbRef = ref(db, tbName);
    const result = [];
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        result.push(merge({ id: childSnapshot.key }, childSnapshot.exportVal()));
      });
      const sortData = [].concat(result)
        .sort((a, b) => Number(a.Cols01) > Number(b.Cols01) ? 1 : -1)
      const data = sortData.filter(p => (String(p.Cols01).includes(DataSource.Cols01)) && (String(p.Cols06).includes(loginUser.Cols02)));
      if (!data || data.length <= 0) {
        const table = ref(db, constS.OrderCardTBL);
        const row = push(table);
        const rowData = {
          Cols01: DataSource.Cols01,
          Cols02: DataSource.Cols05,
          Cols03: DataSource.Cols08,
          Cols04: constS.Code1,
          Cols05: DataSource.Cols05,
          Cols06: loginUser.Cols02,
          Cols96: curDate,
          Cols97: loginUser.Cols02,
          Cols98: curDate,
          Cols99: loginUser.Cols02,
        }
        set(row, rowData);
      } else {
        setShowOKMessage("Sản phẩm đã có trong giỏ hàng!");
      };
    }, {
      onlyOnce: true
    });
    return result;
  };

  const getProductType = (val) => {
    let res = constS.BLANK;
    const valType = String(val).split(constS.COMPA, 20);
    valType.forEach((item) => {
      if (item !== constS.BLANK) {
        const data = dataTypeCombo.filter((p) => String(p.code).startsWith(item));
        if (data && data.length > 0) {
          res = (res === constS.BLANK) ? data[0].name : res + ", " + data[0].name;
        }

      }
    });
    return res;
  };

  const onClickOrderAdd = () => {
    if (!loginUser) {
      setShowOKMessage("Vui lòng đăng nhập để có thông tin giỏ hàng!");
      return;
    }

    if (Number(DataSource.Cols02) <= 0) {
      setShowOKMessage("Sản phẩm này tạm thời kho hết hàng!");
      return;
    }
    fetchData(constS.OrderCardTBL);
  };
  const refOK = () => {
    setShowOKMessage(constS.BLANK);
  };

  const getNextImage = () => (currentImage + 1) % images.length;
  const restartAutoPlay = () => {
    clearTimeout(autoPlayTimeout);
    setAutoPlayTimeout(null);
  };
  if (autoPlay && !autoPlayTimeout) {
    let timeout = setTimeout(() => {
      setDirection("left");
      setCurrentImage(getNextImage());
      restartAutoPlay();
    }, 4000);

    setAutoPlayTimeout(timeout);
  }

  const classes = useStyles(options);
  const listDescription = DataSource.Cols07.split(constS.END, 100);
  return (
    <div>
      <Dialog
        maxWidth={800}
        open={isShow}
        PaperComponent={PaperComponent}
      >
        {isMobile && <DialogTitle key="1">
          <ExMessageOK key={"2"} isMobile={isMobile} message={showOKMessage} refOK={refOK}></ExMessageOK>
          <Grid container rowSpacing={0} spacing={0} sx={{ m: 0, p: 0 }}>
            <div className={classes.root}>
              <div className={classes.wrapper}>
                <Grid item md={12}>
                  <Avatar
                    currentImage={currentImage}
                    src={images[currentImage]}
                    direction={direction}
                    sx={{ width: 860, height: 800, borderRadius: 0 }}
                    classes={{ img: classes.img }}
                  />
                </Grid>
              </div>
            </div>
            <Grid item md={12}>
              <Grid container rowSpacing={0} spacing={0} sx={{ m: 0, p: 0 }}>
                <Grid item md={6} sx={{ textAlign: "left", fontSize: constS.fontSizeM }}>{"Mã sản phẩm : " + DataSource.Cols01}</Grid>
                <Grid item md={6} sx={{ textAlign: "left", color: "red", fontSize: constS.fontSizeM }}>{"Tình trạng : " + (Number(DataSource.Cols02) > 0 ? "Còn hàng" : "Hết hàng")}</Grid>
                <Grid item md={12} sx={{ textAlign: "left", fontSize: constS.fontSizeM }}>{"Loại : " + getProductType(DataSource.Cols03)}</Grid>
                <Grid item md={6} sx={{ textAlign: "left", color: "red", fontSize: constS.fontSizeM }}>{"Giá bán : " + formatter.format(DataSource.Cols05)}</Grid>
                <Grid item md={2}>
                  <Button
                    disabled={Number(DataSource.Cols02) === 0}
                    sx={{ borderRadius: 10, fontSize: 40, mb: 1, textAlign: "center", backgroundColor: "white", color: "black", border: 0 }}
                    variant="outlined" onTouchStart={onClickOrderAdd} onClick={onClickOrderAdd}><Avatar
                      alt="circle"
                      src="\static\illustrations\order.png"
                      sx={{ width: 80, height: 80 }}
                    /></Button>
                </Grid>
                <Grid item md={4}>
                  <ExButtonIconBef fullWidth title={"Đóng"} icon='\static\illustrations\cancle.png'
                    onClick={refClose} onTouchStart={refClose} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={12} sx={{ mt: 1 }}>
              <Card sx={{ padding: 0, margin: 0, boxShadow: 0, border: 1, borderRadius: 2 }}>
                <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 0 }}>
                  <Grid item md={1}>
                    <Button sx={{ p: 0, fontSize: constS.fontSizeM, m: 0, height: 80 }}><CardMedia
                      component="img"
                      alt="rectangle"
                      image="\static\illustrations\giftbox.webp"
                    /></Button>
                  </Grid>
                  <Grid item md={11}>
                    <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 0 }}>
                      <Grid item md={12} sx={{ fontSize: constS.fontSizeM, color: "green" }}>{"Khuyến mãi đặc biệt !!!"}</Grid>
                      <Grid item md={12} sx={{ fontSize: constS.fontSizeM, mr: 2 }}>{"Miễn phí vận chuyển cho đơn hàng 1tr"}</Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Card >
            </Grid>

            <Grid item md={12}>
              <Button onTouchStart={refClose} onClick={refClose} sx={{ p: 0, textAlign: "left" }}><Typography variant="button" fontSize={constS.fontSizeM} color="limegreen">{"Mô tả chi tiết sản phẩm"}</Typography></Button>
            </Grid>
            {listDescription.map((item) => (
              <Grid item md={12} sx={{ mb: 1, fontWeight: "normal", fontSize: constS.fontSizeM }}>{item}</Grid>
            ))}
          </Grid>
        </DialogTitle>}

        {!isMobile && <DialogTitle style={{ cursor: 'move' }} sx={{ width: 600, height: "100%" }}>
          <ExMessageOK message={showOKMessage} refOK={refOK}></ExMessageOK>
          <Grid container rowSpacing={0} spacing={0} sx={{ m: 0, p: 0 }}>
            <div className={classes.root}>
              <div className={classes.wrapper}>
                <Grid item md={5.5}>
                  <Avatar
                    currentImage={currentImage}
                    src={images[currentImage]}
                    direction={direction}
                    sx={{ width: 250, height: 250, borderRadius: 0 }}
                    classes={{ img: classes.img }}
                  />
                </Grid>
              </div>
            </div>
            <Grid item md={0.5}></Grid>

            <Grid item md={6}>
              <Grid container rowSpacing={0} spacing={0} sx={{ m: 0, p: 0 }}>
                <Grid item md={12} sx={{ textAlign: "left", fontSize: constS.fontSize }}>{"Mã sản phẩm : " + DataSource.Cols01}</Grid>
                <Grid item md={12} sx={{ textAlign: "left", color: "red", fontSize: constS.fontSize }}>{"Tình trạng : " + (Number(DataSource.Cols02) > 0 ? "Còn hàng" : "Hết hàng")}</Grid>
                <Grid item md={12} sx={{ textAlign: "left", fontSize: constS.fontSize }}>{"Loại : " + getProductType(DataSource.Cols03)}</Grid>
                <Grid item md={12} sx={{ textAlign: "left", color: "red", fontSize: constS.fontSize }}>{"Giá bán : " + formatter.format(DataSource.Cols05)}</Grid>
                <Grid item md={4}>
                  <Button
                    disabled={Number(DataSource.Cols02) === 0}
                    sx={{ borderRadius: 10, fontSize: 40, mb: 1, textAlign: "center", backgroundColor: "white", color: "black", border: 0 }}
                    variant="outlined" onTouchStart={onClickOrderAdd} onClick={onClickOrderAdd}><Avatar
                      alt="circle"
                      src="\static\illustrations\order.png"
                      sx={{ width: 60, height: 60 }}
                    /></Button>
                </Grid>
                <Grid item md={8} sx={{ textAlign: "right" }}>
                  <ExButtonIconBef fullWidth title={"Đóng"} icon='\static\illustrations\cancle.png'
                    onClick={refClose} onTouchStart={refClose} />
                </Grid>

                <Grid item md={12} sx={{ mt: 1 }}>
                  <Card sx={{ padding: 0, margin: 0, boxShadow: 0, border: 1, borderRadius: 2 }}>
                    <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 0 }}>
                      <Grid item md={1}>
                        <Button sx={{ p: 0, fontSize: constS.fontSize, m: 0, height: 40 }}><CardMedia
                          component="img"
                          alt="rectangle"
                          image="\static\illustrations\giftbox.webp"
                        /></Button>
                      </Grid>
                      <Grid item md={11}>
                        <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 0 }}>
                          <Grid item md={12} sx={{ fontSize: constS.fontSize, color: "green" }}>{"Khuyến mãi đặc biệt !!!"}</Grid>
                          <Grid item md={12} sx={{ fontSize: constS.fontSize, mr: 2 }}>{"Miễn phí vận chuyển cho đơn hàng 1tr"}</Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card >
                </Grid>

              </Grid>
            </Grid>

            <Grid item md={12}>
              <Button onTouchStart={refClose} onClick={refClose} sx={{ p: 0, textAlign: "left" }}><Typography variant="button" fontSize={constS.fontSize} color="limegreen">{"Mô tả chi tiết sản phẩm"}</Typography></Button>
            </Grid>
            {listDescription.map((item) => (
              <Grid item md={12} sx={{ mb: 1, fontWeight: "normal", fontSize: constS.fontSize }}>{item}</Grid>
            ))}
          </Grid>
        </DialogTitle>}
      </Dialog>
    </div >
  );
}
