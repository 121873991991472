import * as React from 'react';
import Card from '@mui/material/Card';
import { Grid } from "@mui/material";


export default function ExCardCustomer({ DataSource, onClick, selectedId }) {
  return (
    <Card key={DataSource.id} onClick={() => onClick(DataSource)} sx={{
      height: 260, p: 0, m: 0,
      backgroundColor: selectedId === DataSource.id ? "greenyellow" : "white"
    }}>
      <Grid container rowSpacing={0} spacing={0} sx={{ m: 0, p: 0, fontSize: 40 }}>
        <Grid item md={12}>{"Mã : " + DataSource.id}</Grid>
        <Grid item md={6}>{"Tên : " + DataSource.Cols01}</Grid>
        <Grid item md={6}>{"ĐT : " + DataSource.Cols02}</Grid>
        <Grid item md={6}>{"Quyền Hạn : " + DataSource.Cols03Name}</Grid>
        <Grid item md={6}>{"Giới Tính : " + DataSource.Cols04Name}</Grid>
        <Grid item md={12}>{"Thử Điện Tử : " + DataSource.Cols05}</Grid>
      </Grid>
    </Card >
  );
};