import * as React from 'react';
import Card from '@mui/material/Card';
import { Grid } from "@mui/material";
import constS from '../const/constS';


export default function ExCardBill({ DataSource, onClick, selectedId }) {
  return (
    <Card key={DataSource.id} onClick={() => onClick(DataSource)} sx={{
      height: 300, p: 0, m: 0,
      backgroundColor: selectedId === DataSource.id ? "greenyellow" : "white"
    }}>
      <Grid container rowSpacing={0} spacing={0} sx={{ m: 0, p: 0, fontSize: 40 }}>
        <Grid item md={8}>{"Mã : " + DataSource.id}</Grid>
        <Grid item md={4}>{"ĐT : " + DataSource.Cols01}</Grid>
        <Grid item md={12}>{"Tên : " + DataSource.Cols02Name}</Grid>
        <Grid item md={4}>{"Giá tổng"}</Grid>
        <Grid item md={4}>{"Giảm giá"}</Grid>
        <Grid item md={4}>{"Khách trả"}</Grid>
        <Grid item md={4}>{DataSource.Cols03Name}</Grid>
        <Grid item md={4}>{DataSource.Cols04Name}</Grid>
        <Grid item md={4}>{DataSource.Cols05Name}</Grid>
        <Grid item md={6}>{"Phí ship : " + DataSource.Cols09Name}</Grid>
        <Grid item md={6}>{"Trạng thái : " + DataSource.Cols07Name}</Grid>
      </Grid>
    </Card >
  );
};