import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, push, set, onValue, update, remove } from "firebase/database";
import firebaseDB from '../initfirebase'
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, List, Collapse, ListItemText, ListItemIcon, ListItemButton } from '@mui/material';
//
import Iconify from './Iconify';
import constS from "../const/constS";
// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active, isMobile }) {
  const theme = useTheme();

  const isActiveRoot = active(item.path);

  const { title, path, icon, info, children } = item;

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium',
  };

  // if (children) {
  //   return (
  //     <>
  //       <ListItemStyle
  //         onClick={handleOpen}
  //         sx={{
  //           ...(isActiveRoot && activeRootStyle),
  //           fontSize: 35,
  //         }}
  //       >
  //         <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
  //         <ListItemText fontSize={35}  disableTypography primary={title} />
  //         {info && info}
  //         <Iconify
  //           icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
  //           sx={{ width: 16, height: 16, ml: 1, fontSize:35 }}
  //         />
  //       </ListItemStyle>

  //       <Collapse in={open} timeout="auto" unmountOnExit>
  //         <List component="div" disablePadding>
  //           {children.map((item) => {
  //             const { title, path } = item;
  //             const isActiveSub = active(path);

  //             return (
  //               <ListItemStyle
  //                 key={title}
  //                 component={RouterLink}
  //                 to={path}
  //                 sx={{
  //                   ...(isActiveSub && activeSubStyle),
  //                   fontSize: 35,
  //                 }}
  //               >
  //                 <ListItemIconStyle>
  //                   <Box
  //                     component="span"
  //                     sx={{
  //                       width: 4,
  //                       height: 4,
  //                       display: 'flex',
  //                       borderRadius: '50%',
  //                       alignItems: 'center',
  //                       justifyContent: 'center',
  //                       bgcolor: 'text.disabled',
  //                       transition: (theme) => theme.transitions.create('transform'),
  //                       ...(isActiveSub && {
  //                         transform: 'scale(2)',
  //                         bgcolor: 'primary.main',
  //                       }),
  //                     }}
  //                   />
  //                 </ListItemIconStyle>
  //                 <ListItemText disableTypography primary={title} />
  //               </ListItemStyle>
  //             );
  //           })}
  //         </List>
  //       </Collapse>
  //     </>
  //   );
  // }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
        height: isMobile ? 80 : 50
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText sx={{ fontSize: isMobile ? constS.fontSizeM : constS.fontSize }} disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, ...other }) {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  const { pathname } = useLocation();
  const [loginUser, setLoginUser] = useState(null);
  const [listAccount, setListAccount] = useState([]);
  const db = getDatabase(firebaseDB);

  async function CheckDataLogin() {
    const auth = getAuth(firebaseDB);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        listAccount.forEach((row) => {
          if (row.Cols02 === user.email.substring(0, user.email.length - 10)) {
            setLoginUser(row);
            return;
          }
        });
      }
    });
  };

  async function fetchData(tbName) {
    const dbRef = ref(db, tbName);
    const result = [];
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        result.push(childSnapshot.exportVal());
      });
      setListAccount(result)
    }, {
      onlyOnce: true
    });
    return result;
  };

  useEffect(() => {
    fetchData(constS.AccountListTBL);
  }, []);

  useEffect(() => {
    CheckDataLogin();
  }, [listAccount]);

  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  if (!loginUser) {
    return (<>
      <Box {...other}>
        <List disablePadding>
          {navConfig.map((item) => (
            item.title === "Trang chủ" && <NavItem isMobile={isMobile} key={item.title} item={item} active={match} /> ||
            item.title === "Đăng nhập" && <NavItem isMobile={isMobile} key={item.title} item={item} active={match} /> ||
            item.title === "Đăng kí" && <NavItem isMobile={isMobile} key={item.title} item={item} active={match} />
          ))}
        </List>
      </Box>
    </>);
  };

  const isAdmin = loginUser && Number(loginUser.Cols03) === 9;
  const isGuest = loginUser && Number(loginUser.Cols03) <= 5;

  return (
    <Box {...other}>
      {isAdmin && <List disablePadding>
        {navConfig.map((item) => (
          item.title === "Trang chủ" && <NavItem isMobile={isMobile} key={item.title} item={item} active={match} /> ||
          item.title === "Giỏ hàng" && <NavItem isMobile={isMobile} key={item.title} item={item} active={match} /> ||
          item.title === "Đặt hàng" && <NavItem isMobile={isMobile} key={item.title} item={item} active={match} /> ||
          item.title === "Sản phẩm" && <NavItem isMobile={isMobile} key={item.title} item={item} active={match} /> ||
          item.title === "Khách hàng" && <NavItem isMobile={isMobile} key={item.title} item={item} active={match} /> ||
          item.title === "Tài khoản" && <NavItem isMobile={isMobile} key={item.title} item={item} active={match} /> ||
          item.title === "Đăng nhập" && <NavItem isMobile={isMobile} key={item.title} item={item} active={match} /> ||
          item.title === "Đăng kí" && <NavItem isMobile={isMobile} key={item.title} item={item} active={match} /> ||
          item.title === "Hóa Đơn" && <NavItem isMobile={isMobile} key={item.title} item={item} active={match} /> ||
          item.title === "Thu chi" && <NavItem isMobile={isMobile} key={item.title} item={item} active={match} /> ||
          item.title === "Nội dung" && <NavItem isMobile={isMobile} key={item.title} item={item} active={match} />
        ))}
      </List>}
      {isGuest && <List disablePadding>
        {navConfig.map((item) => (
          item.title === "Trang chủ" && <NavItem isMobile={isMobile} key={item.title} item={item} active={match} /> ||
          item.title === "Giỏ hàng" && <NavItem isMobile={isMobile} key={item.title} item={item} active={match} /> ||
          item.title === "Đặt hàng" && <NavItem isMobile={isMobile} key={item.title} item={item} active={match} /> ||
          item.title === "Tài khoản" && <NavItem isMobile={isMobile} key={item.title} item={item} active={match} /> ||
          item.title === "Đăng nhập" && <NavItem isMobile={isMobile} key={item.title} item={item} active={match} /> ||
          item.title === "Đăng kí" && <NavItem isMobile={isMobile} key={item.title} item={item} active={match} />
        ))}
      </List>}
    </Box>
  );
}
