import { Button } from '@mui/material';
import constS from '../const/constS';

export default function ExButton({ props, inputRef, valDefault }) {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  return (
    <Button variant="contained" onClickCapture={() => inputRef(props.code, valDefault)}
      sx={{ p: 0, mt: 1, borderRadius: 0, fontSize: isMobile ? constS.fontSizeM : constS.fontSize, backgroundColor: (valDefault === constS.BLANK ? "Silver" : constS.BLANK) }}>{props.name}</Button>
  );
}