import ThemeProvider from "./theme"
import ScrollToTop from "./components/ScrollToTop";
import Router from "./routes";


export default function P0001() {
    return (
        <ThemeProvider>
            <ScrollToTop />
            <Router />
        </ThemeProvider>
    );
}