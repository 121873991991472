import { getDatabase, ref, onValue, push, set } from "firebase/database";
import firebaseDB from '../initfirebase'
import * as React from 'react';
import { merge } from 'lodash';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import constS from '../const/constS';
import { Button, Grid, Divider, Avatar } from "@mui/material";
import { useState } from 'react';
import ExMessageOK from "./ExMessageOK";
import ExModalProduct from "./ExModalProduct";
import ExButtonIconBef from "./ExButtonIconBef";

export default function ExCard({ DataSource, cardName, loginUser, dataTypeCombo, isMobile }) {
  const formatter = new Intl.NumberFormat('vi', { style: 'currency', currency: 'VND' });
  const date = new Date();
  const curDate = date.getFullYear().toString()
    + (date.getMonth() + 1).toString().padStart(2, "0")
    + date.getDate().toString().padStart(2, "0");
  const db = getDatabase(firebaseDB);
  const [showOKMessage, setShowOKMessage] = useState(constS.BLANK);
  const [showModalProduct, setShowModalProduct] = useState(false);

  async function fetchData(tbName) {
    const dbRef = ref(db, tbName);
    const result = [];
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        result.push(merge({ id: childSnapshot.key }, childSnapshot.exportVal()));
      });
      const sortData = [].concat(result)
        .sort((a, b) => Number(a.Cols01) > Number(b.Cols01) ? 1 : -1)
      const data = sortData.filter(p => (String(p.Cols01).includes(DataSource.Cols01)) && (String(p.Cols06).includes(loginUser.Cols02)));
      if (!data || data.length <= 0) {
        const table = ref(db, constS.OrderCardTBL);
        const row = push(table);
        const rowData = {
          Cols01: DataSource.Cols01,
          Cols02: DataSource.Cols05,
          Cols03: DataSource.Cols08,
          Cols04: constS.Code1,
          Cols05: DataSource.Cols05,
          Cols06: loginUser.Cols02,
          Cols96: curDate,
          Cols97: loginUser.Cols02,
          Cols98: curDate,
          Cols99: loginUser.Cols02,
        }
        set(row, rowData);
      } else {
        setShowOKMessage("Sản phẩm đã có trong giỏ hàng!");
      };
    }, {
      onlyOnce: true
    });
    return result;
  };

  const onClickOrderAdd = () => {
    if (!loginUser) {
      setShowOKMessage("Vui lòng đăng nhập để có thông tin giỏ hàng!");
      return;
    }

    if (Number(DataSource.Cols02) <= 0) {
      setShowOKMessage("Sản phẩm này tạm thời kho hết hàng!");
      return;
    }
    fetchData(constS.OrderCardTBL);
  };

  const onClickOpenDetail = () => {
    setShowModalProduct(true);
  };

  const refOK = () => {
    setShowOKMessage(constS.BLANK);
  };
  const refClose = () => {
    setShowModalProduct(false);
  };

  let image = constS.BLANK;
  if (DataSource.Cols08.length > 0) {
    const listImage = String(DataSource.Cols08).split(constS.END);
    if (listImage && listImage.length > 0) {
      image = listImage[0];
    }
  }

  return (
    <Card sx={{ height: isMobile ? 180 : 130, p: 0, m: 0 }}>
      <ExMessageOK isMobile={isMobile} message={showOKMessage} refOK={refOK}></ExMessageOK>
      <ExModalProduct isMobile={isMobile} isShow={showModalProduct} refClose={refClose} DataSource={DataSource} dataTypeCombo={dataTypeCombo} loginUser={loginUser}></ExModalProduct>
      <Grid container spacing={0} sx={{ m: 0, p: 0 }}>
        <Grid item md={3}>
          <Button><CardMedia
            component="img"
            height={isMobile ? 160 : 100}
            image={image}
            onClick={onClickOpenDetail}
            alt="may shop"
          /></Button>
        </Grid>
        <Grid item md={9}>
          <Grid container spacing={0} sx={{ m: 0, p: 0, fontSize: isMobile ? constS.fontSizeM : constS.fontSize }}>
            <Grid item md={12}>{cardName}</Grid>
            {String(cardName).length <= (isMobile ? 45 : 30) && <Grid item md={12}>{"---"}</Grid>}
            <Grid item md={7} sx={{ color: "red" }}>{Number(DataSource.Cols02) === 0 ? "Tạm hết hàng" : "Giá bán: " + formatter.format(DataSource.Cols05)}</Grid>
            <Grid item md={5} sx={{ textAlign: "right" }}>
              <Button
                disabled={Number(DataSource.Cols02) === 0}
                sx={{ borderRadius: 10, fontSize: 40, mb: 1, textAlign: "center", backgroundColor: "white", color: "black", border: 0 }}
                variant="outlined" onClick={onClickOrderAdd}><Avatar
                  alt="circle"
                  src="\static\illustrations\order.png"

                  sx={{ width: 40, height: 40 }}
                /></Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card >
  );
};