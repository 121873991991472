// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDh9iRiSCoaTmzYc_oF-UVvPGaCTmc9fcU",
  authDomain: "mayshopkid.firebaseapp.com",
  databaseURL: "https://mayshopkid-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "mayshopkid",
  storageBucket: "mayshopkid.appspot.com",
  messagingSenderId: "556560564909",
  appId: "1:556560564909:web:052004ce5c04b1f2dd3c24",
  measurementId: "G-TL3D411THT"
};


// Initialize Firebase
const firebaseDB = initializeApp(firebaseConfig);
export default firebaseDB;