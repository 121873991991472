import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, push, set, onValue, update, remove } from "firebase/database";
import firebaseDB from '../initfirebase'
import { Container, Typography, Button, Paper, Grid, InputLabel } from '@mui/material';
import ExDataTable from '../components/ExDataTable';
import { useEffect, useState } from 'react';
import { merge } from 'lodash';
import Page from "../components/Page";
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import QrCodeOutlinedIcon from '@mui/icons-material/QrCodeOutlined';
import constM from '../const/constM';
import constS from '../const/constS';
import ExTextField from "../components/ExTextField";
import ExNumber from "../components/ExNumber";
import ExComboBox from "../components/ExComboBox";
import { useLocation, useNavigate } from "react-router-dom";
import ExMessageOK from "../components/ExMessageOK";
import ExMessageOKCancel from "../components/ExMessageOKCancel";
import ExCardBillDetail from "../components/ExCardBillDetail";
import ExButtonIconBef from "../components/ExButtonIconBef";
// ----------------------------------------------------------------------

const defaultCombo = { code: constS.ZERO, name: "" };

export default function BillDetail() {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  const formatter = new Intl.NumberFormat('vi', { style: 'currency', currency: 'VND' });
  const date = new Date();
  const curDate = date.getFullYear().toString()
    + (date.getMonth() + 1).toString().padStart(2, "0")
    + date.getDate().toString().padStart(2, "0");
  const { state } = useLocation();
  const navigate = useNavigate();
  const [dataList, setDataList] = useState([]);
  const [dataProductList, setDataProductList] = useState([]);
  const [loginUser, setLoginUser] = useState(null);
  const [dataSelected, setDataSelected] = useState([]);

  const [dataNameCombo, setDataNameCombo] = useState([]);
  const [showOKMessage, setShowOKMessage] = useState(constS.BLANK);
  const [showOKCancelMessage, setShowOKCancelMessage] = useState(constS.BLANK);

  const [valDefaultKey, setValDefaultKey] = useState(constS.BLANK);
  const [valDefault02, setValDefault02] = useState(defaultCombo);
  const [valDefault03, setValDefault03] = useState(constS.ZERO);
  const [valDefault04, setValDefault04] = useState(constS.ONE);
  const [valDefault05, setValDefault05] = useState(constS.ZERO);

  const [valDefaultCreateDate, setValDefaultCreateDate] = useState(constS.BLANK);
  const [valDefaultCreateUser, setValDefaultCreateUser] = useState(constS.BLANK);

  const db = getDatabase(firebaseDB);

  async function CheckDataLogin() {
    const auth = getAuth(firebaseDB);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setLoginUser(user);
      }
    });
  };

  async function fetchDataInit(tbName) {
    const dbRef = ref(db, tbName);
    const result = [];
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        const obj = childSnapshot.exportVal();
        const item = dataNameCombo.filter((p) => String(p.code).includes(obj.Cols02))[0];
        result.push(merge({
          id: childSnapshot.key,
          Cols03Name: formatter.format(obj.Cols03),
          Cols05Name: formatter.format(obj.Cols05),
          Cols02Name: (item ? item.name : constS.BLANK)
        }, obj));
      });
      const sortData = [].concat(result)
        .sort((a, b) => Number(a.Cols02) > Number(b.Cols02) ? 1 : -1)
      if (tbName === constS.BillDetailTBN) {
        setDataList(sortData.filter(p => (state !== constS.BLANK ? String(p.Cols01).includes(state) : true)));
      };

    }, {
      onlyOnce: true
    });
    return result;
  };

  async function fetchDataCombo(tbName) {
    const dbRef = ref(db, tbName);
    const result = [];
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        result.push(childSnapshot.exportVal());
      });
      const sortData = [].concat(result)
        .sort((a, b) => Number(a.code) > Number(b.code) ? 1 : -1)

      if (tbName === constS.ProductNameTBL) {
        setDataNameCombo(sortData);
      }
    }, {
      onlyOnce: true
    });
    return result;
  };

  async function fetchDataProducts(tbName) {
    const dbRef = ref(db, tbName);
    const result = [];
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        result.push(merge({ id: childSnapshot.key }, childSnapshot.exportVal()));
      });

      if (constS.ProductListTBN === tbName) {
        setDataProductList(result);
        let filterVal = constS.BLANK;
        if (valDefault02.code !== constS.ZERO) {
          filterVal = valDefault02.code;
          const ComboData = result.filter(ele => String(ele.Cols01).startsWith(filterVal));
          if (ComboData && ComboData.length > 0) {
            setValDefault03(ComboData[0].Cols05);
            setValDefault05(Number(ComboData[0].Cols05) * Number(valDefault04));
          }
        }
      }
    }, {
      onlyOnce: true
    });
    return result;
  };

  useEffect(() => {
    fetchDataProducts(constS.ProductListTBN);
  }, [valDefault02, valDefault04]);

  useEffect(() => {
    if (dataNameCombo && dataNameCombo.length > 0) {
      fetchDataInit(constS.BillDetailTBN);
    }
  }, [dataNameCombo]);

  useEffect(() => {
    CheckDataLogin();
    fetchDataCombo(constS.ProductNameTBL);
  }, []);

  const getFormData = (mode) => {
    const formData = {
      Cols01: state,
      Cols02: valDefault02.code,
      Cols03: valDefault03,
      Cols04: valDefault04,
      Cols05: valDefault05,
      Cols06: dataProductList.filter(p => p.Cols01 === valDefault02.code)[0].Cols08,
      Cols96: curDate,
      Cols97: loginUser.email.substr(0, loginUser.email.indexOf("@")),
      Cols98: mode === constS.MODE_ADD ? curDate : valDefaultCreateDate,
      Cols99: mode === constS.MODE_ADD ? loginUser.email.substr(0, loginUser.email.indexOf("@")) : valDefaultCreateUser,
    };
    return formData;
  };

  const onHandleAdd = () => {
    if (valDefault02.code === constS.ZERO) {
      setShowOKMessage("Vui lòng chọn tên sản phẩm!");
      return;
    }

    const listbilldetail = dataList.filter(p => (String(p.Cols02).includes(valDefault02.code)) &&
      (String(p.Cols01).includes(state)));
    if (listbilldetail.length > 0) {
      setShowOKMessage("Sản phẩm này đã được đăng kí!");
      return;
    }

    if (valDefault04 === constS.BLANK || valDefault04 === constS.ZERO) {
      setShowOKMessage("Vui lòng nhập số lượng!");
      return;
    }

    const listProducts = dataProductList.filter(ele => String(ele.Cols01).startsWith(valDefault02.code));
    if (listProducts && listProducts.length > 0 && Number(listProducts[0].Cols02) < Number(valDefault04)) {
      setShowOKMessage("Hiện tại số lượng hàng tồn không đủ!");
      return;
    }

    const cusTypes = ref(db, constS.BillDetailTBN);
    const newCusTypes = push(cusTypes);
    set(newCusTypes, getFormData(constS.MODE_ADD));
    if (listProducts && listProducts.length > 0) {
      let sum = Number(listProducts[0].Cols02) - Number(valDefault04);
      update(ref(db, `/` + constS.ProductListTBN + `/${listProducts[0].id}/`),
        {
          Cols02: sum,
          Cols96: loginUser.email.substr(0, loginUser.email.indexOf("@")),
          Cols97: curDate
        });
    }
    fetchDataInit(constS.BillDetailTBN);
  };

  const onHandleUpdate = () => {
    if (valDefaultKey === constS.BLANK) {
      setShowOKMessage("Vui lòng chọn mã chi tiết hóa đơn!");
      return;
    }

    if (valDefault02.code === constS.ZERO) {
      setShowOKMessage("Vui lòng chọn tên sản phẩm!");
      return;
    }

    if (valDefault04 === constS.BLANK || Number(valDefault04) === Number(constS.ZERO)) {
      setShowOKMessage("Vui lòng nhập số lượng!");
      return;
    }

    let minus = 0;
    if (dataSelected) {
      minus = Number(dataSelected.Cols04);
    }
    const listProducts = dataProductList.filter(ele => String(ele.Cols01).startsWith(valDefault02.code));
    if (listProducts && listProducts.length > 0 && Number(listProducts[0].Cols02) + minus < Number(valDefault04)) {
      setShowOKMessage("Hiện tại số lượng hàng tồn không đủ!");
      return;
    }

    update(ref(db, `/` + constS.BillDetailTBN + `/${valDefaultKey}/`), getFormData(''));
    if (listProducts && listProducts.length > 0) {
      let sum = 0;
      if (Number(valDefault04) > minus) {
        sum = Number(listProducts[0].Cols02) - (Number(valDefault04) - Number(minus));
      } else {
        sum = Number(listProducts[0].Cols02) + (Number(minus) - Number(valDefault04));
      }
      update(ref(db, `/` + constS.ProductListTBN + `/${listProducts[0].id}/`),
        {
          Cols02: sum,
          Cols96: loginUser.email.substr(0, loginUser.email.indexOf("@")),
          Cols97: curDate
        });
    }
    fetchDataInit(constS.BillDetailTBN);
  };

  const onHandleDelete = () => {
    if (valDefaultKey === constS.BLANK) {
      setShowOKMessage("Vui lòng chọn mã chi tiết hóa đơn!");
      return;
    }

    setShowOKCancelMessage("Xác nhận có xóa chi tiết hóa đơn hay không?");
  };

  const onHandleClear = () => {
    setValDefaultKey(constS.BLANK);
    setValDefault02(defaultCombo);
    setValDefault03(constS.ZERO);
    setValDefault04(constS.ONE);
    setValDefault05(constS.ZERO);
    setDataSelected([]);
  };

  const refColsKey = (e) => {
    setValDefaultKey(e.target.value);
  };

  const refCols02 = (event, value) => {
    if (value !== null) {
      setValDefault02(value);
    }
    else {
      setValDefault02(defaultCombo);
    }
  };

  const refCols03 = (e) => {
    setValDefault03(e.target.value);
  };

  const refCols04 = (e) => {
    setValDefault04(e.target.value);
  };

  const refCols05 = (e) => {
    setValDefault05(e.target.value);
  };

  const refOK = () => {
    setShowOKMessage(constS.BLANK);
    setShowOKCancelMessage(constS.BLANK);
  };

  const refConfirm = (message) => {
    if ("Xác nhận có xóa chi tiết hóa đơn hay không?" === message) {
      setShowOKCancelMessage(constS.BLANK);
      remove(ref(db, `/` + constS.BillDetailTBN + `/${valDefaultKey}`));
      if (dataSelected) {
        const listProducts = dataProductList.filter(ele => String(ele.Cols01).startsWith(dataSelected.Cols02));
        if (listProducts && listProducts.length > 0) {
          update(ref(db, `/` + constS.ProductListTBN + `/${listProducts[0].id}/`),
            {
              Cols02: Number(listProducts[0].Cols02) + Number(dataSelected.Cols04),
              Cols96: loginUser.email.substr(0, loginUser.email.indexOf("@")),
              Cols97: curDate
            });
        }
      }
      fetchDataInit(constS.BillDetailTBN);
    }
  };

  const onClickSelected = (val) => {
    setDataSelected(val);
    setValDefaultKey(val.id);
    if (val.Cols02 === constS.ZERO) {
      setValDefault02(defaultCombo);
    }
    else {
      dataNameCombo.forEach((ele) => {
        if (ele.code === val.Cols02) { setValDefault02(ele); };
      });
    };
    setValDefault03(val.Cols03);
    setValDefault04(val.Cols04);
    setValDefault05(val.Cols05);
    setValDefaultCreateDate(val.Cols98);
    setValDefaultCreateUser(val.Cols99);
  };

  const onHandleReturn = () => {
    navigate('/listbills', { replace: false, state: state });
  }

  return [
    <Page title={constM.listBills}>
      <ExMessageOK message={showOKMessage} refOK={refOK}></ExMessageOK>
      <ExMessageOKCancel isMobile={isMobile} message={showOKCancelMessage} refOK={refOK} refConfirm={refConfirm}></ExMessageOKCancel>
      <Container maxWidth="xl">
        <Typography variant={"h1"}>{constM.listMainternance + " " + constM.placeDetail + " " + constM.placeBill + constS.SPACE + state}</Typography>
        <Paper variant="outlined">
          <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 2, fontSize: 40 }}>
            <Grid item md={11.5}>{"Mã chi tiết"}
            </Grid>
            <Grid item md={11.5}>
              <ExTextField
                id={constS.id} inputRef={refColsKey} valDefault={valDefaultKey}
                placeholder={constM.placeKey} Icon={<QrCodeOutlinedIcon />} maxLength={50} disabled={true}></ExTextField>
            </Grid>
            <Grid item md={11.5}>{"Tên sản phẩm"}
            </Grid>
            <Grid item md={11.5}>
              <ExComboBox
                id={constS.Cols02} comboRef={refCols02} valDefault={valDefault02}
                placeholder={constM.placeName} DataSource={dataNameCombo}></ExComboBox>
            </Grid>

            <Grid item md={4}>{constM.placeBillPrice}
            </Grid>
            <Grid item md={7.5}>
              <ExNumber
                id={constS.Cols03} inputRef={refCols03} valDefault={valDefault03}
                placeholder={constM.placeBillPrice} disabled Icon={<AddCardOutlinedIcon />} maxLength={100}></ExNumber>
            </Grid>
            <Grid item md={4}>{constM.placeQuantity}
            </Grid>
            <Grid item md={7.5}>
              <ExNumber
                id={constS.Cols04} inputRef={refCols04} valDefault={valDefault04}
                placeholder={constM.placeQuantity} Icon={<AttachMoneyOutlinedIcon />} maxLength={100}></ExNumber>
            </Grid>
            <Grid item md={4}>{constM.placeBillPayment}
            </Grid>
            <Grid item md={7.5}>
              <ExNumber
                id={constS.Cols05} inputRef={refCols05} valDefault={valDefault05}
                placeholder={constM.placeBillPayment} disabled Icon={<LocalAtmOutlinedIcon />} maxLength={100}></ExNumber>
            </Grid>

            <Grid item>
              <ExButtonIconBef title={"Đăng kí"} icon='\static\illustrations\add.png' onClick={onHandleAdd} />
            </Grid>
            <Grid item>
              <ExButtonIconBef title={"Chỉnh sửa"} icon='\static\illustrations\edit.png' onClick={onHandleUpdate} />
            </Grid>
            <Grid item>
              <ExButtonIconBef title={"Xóa nhập"} icon='\static\illustrations\delete.png' onClick={onHandleClear} />
            </Grid>
            <Grid item>
              <ExButtonIconBef title={"Xóa"} icon='\static\illustrations\delete.png' onClick={onHandleDelete} />
            </Grid>

            <Grid item>
              <ExButtonIconBef title={"Quay lại"} icon='\static\illustrations\return.png' onClick={onHandleReturn} />
            </Grid>

          </Grid>
        </Paper>

        <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 2 }}>
          {dataList.map((item) => (
            <Grid item md={12}>
              <ExCardBillDetail DataSource={item} onClick={onClickSelected} selectedId={valDefaultKey}></ExCardBillDetail>
            </Grid>
          ))}
        </Grid>
      </Container >
    </Page >
  ];
}
