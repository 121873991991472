import { getDatabase, ref, onValue, update } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import firebaseDB from '../initfirebase'
import { Container, Typography, Paper, Grid, Button, InputLabel } from '@mui/material';
import ExMessageOK from "../components/ExMessageOK";
import { useEffect, useState } from 'react';
import { merge } from 'lodash';
import Page from "../components/Page";
import QrCodeOutlinedIcon from '@mui/icons-material/QrCodeOutlined';
import constM from "../const/constM";
import constS from "../const/constS";
import ExTextField from "../components/ExTextField";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import ExNumber from "../components/ExNumber";
import ExComboBox from "../components/ExComboBox";
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import { useNavigate } from 'react-router-dom';
import ExCardCustomer from "../components/ExCardCustomer";
import ExButtonIconBef from "../components/ExButtonIconBef";
import ExButtonIconAft from "../components/ExButtonIconAft";
// ----------------------------------------------------------------------

const defaultCombo = { code: constS.ZERO, name: "" };

export default function AccountMainternance() {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  const date = new Date();
  const curDate = date.getFullYear().toString()
    + (date.getMonth() + 1).toString().padStart(2, "0")
    + date.getDate().toString().padStart(2, "0");

  const navigate = useNavigate();
  const [dataList, setDataList] = useState([]);
  const [dataSexCombo, setDataSexCombo] = useState([]);
  const [dataAccountPermissionCombo, setDataAccountPermissionCombo] = useState([]);
  const [loginUser, setLoginUser] = useState(null);
  const [showOKMessage, setShowOKMessage] = useState(constS.BLANK);

  const [valDefaultKey, setValDefaultKey] = useState(constS.BLANK);
  const [valDefault01, setValDefault01] = useState(constS.BLANK);
  const [valDefault02, setValDefault02] = useState(constS.BLANK);
  const [valDefault03, setValDefault03] = useState(defaultCombo);
  const [valDefault04, setValDefault04] = useState(defaultCombo);
  const [valDefault05, setValDefault05] = useState(constS.BLANK);
  const [valDefault06, setValDefault06] = useState(constS.BLANK);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);


  const db = getDatabase(firebaseDB);

  async function CheckDataLogin() {
    const auth = getAuth(firebaseDB);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        dataList.forEach((row) => {
          if (row.Cols02 === user.email.substring(0, user.email.length - 10)) {
            setLoginUser(row);
            if (row && row.Cols03 !== "9") {
              navigate('/', { replace: false });
            }
            return;
          }
        });
      }

      if (!user) { navigate('/', { replace: false }); }
    });
  };

  async function fetchDataCombo(tbName) {
    const dbRef = ref(db, tbName);
    const result = [];
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        result.push(childSnapshot.exportVal());
      });
      const sortData = [].concat(result)
        .sort((a, b) => Number(a.code) > Number(b.code) ? 1 : -1)

      if (tbName === constS.AccountPermissionTBL) {
        setDataAccountPermissionCombo(sortData);
      }
      if (tbName === constS.SexTBL) {
        setDataSexCombo(sortData);
      }
    }, {
      onlyOnce: true
    });
    return result;
  };


  async function fetchDataInit(tbName) {
    const dbRef = ref(db, tbName);
    const result = [];
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        const obj = childSnapshot.exportVal();
        const item1 = dataSexCombo.filter((p) => String(p.code).includes(obj.Cols04))[0];
        const item2 = dataAccountPermissionCombo.filter((p) => String(p.code).includes(obj.Cols03))[0];
        result.push(merge({
          id: childSnapshot.key,
          Cols03Name: (item2 ? item2.name : constS.BLANK),
          Cols04Name: (item1 ? item1.name : constS.BLANK),
        }, childSnapshot.exportVal()));
      });
      const sortData = [].concat(result)
        .sort((a, b) => Number(a.Cols01) > Number(b.Cols01) ? 1 : -1);

      const curpage = Number(currentPage);
      const last = Number(curpage) * 10 > sortData.length ? sortData.length : Number(curpage) * 10;
      const first = Number(curpage - 1) * 10;
      const data = [].concat(sortData).splice(first, last - first);
      setDataList(sortData);
      setCurrentData(data);
    }, {
      onlyOnce: true
    });
    return result;
  }

  async function fetchDatabyCombo(tbName) {
    const dbRef = ref(db, tbName);
    const result = [];
    let obj = null;
    const listSearch = String(valDefault01).split(constS.SPACE);
    let isValid = false;
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        obj = childSnapshot.exportVal();
        const item1 = dataSexCombo.filter((p) => String(p.code).includes(obj.Cols04))[0];
        const item2 = dataAccountPermissionCombo.filter((p) => String(p.code).includes(obj.Cols03))[0];

        if (valDefault01 !== constS.BLANK) {
          isValid = false;
          if (listSearch && listSearch.length > 0) {
            listSearch.forEach((it) => {
              if (it !== constS.BLANK && String(obj.Cols01).toLowerCase().includes(it.toLowerCase())) {
                isValid = true;
              }
            });
          }
          if (isValid) {
            result.push(merge({
              id: childSnapshot.key,
              Cols03Name: (item2 ? item2.name : constS.BLANK),
              Cols04Name: (item1 ? item1.name : constS.BLANK),
            }, obj));
          }
        }
        else {
          result.push(merge({
            id: childSnapshot.key,
            Cols03Name: (item2 ? item2.name : constS.BLANK),
            Cols04Name: (item1 ? item1.name : constS.BLANK),
          }, obj));
        }
      });

      const sortData = [].concat(result)
        .sort((a, b) => Number(a.Cols01) > Number(b.Cols01) ? 1 : -1);

      const data = [].concat(sortData).splice(0, 12);
      setDataList(sortData);
      setCurrentData(data);
      setCurrentPage(1);
    }, {
      onlyOnce: true
    });
    return result;
  };

  useEffect(() => {
    fetchDataCombo(constS.AccountPermissionTBL);
    fetchDataCombo(constS.SexTBL);
  }, []);

  useEffect(() => {
    if (dataAccountPermissionCombo.length > 0 && dataSexCombo.length > 0) {
      fetchDataInit(constS.AccountListTBL);
    }

  }, [dataAccountPermissionCombo, dataSexCombo]);

  useEffect(() => {
    CheckDataLogin();
  }, [dataList]);

  const getFormData = () => {

    const formData = {
      Cols01: valDefault01,
      Cols02: valDefault02,
      Cols03: valDefault03.code,
      Cols04: valDefault04.code,
      Cols05: valDefault05,
      Cols06: valDefault06,
      Cols96: curDate,
      Cols97: loginUser.Cols02,
    };
    return formData;
  };

  const onHandleUpdate = () => {
    if (valDefaultKey === constS.BLANK) {
      setShowOKMessage("Hãy chọn mã để cập nhật!");
      return;
    }

    update(ref(db, `/` + constS.AccountListTBL + `/${valDefaultKey}/`), getFormData());
    fetchDataInit(constS.AccountListTBL);
  }

  const onHandleClear = () => {
    setValDefaultKey(constS.BLANK);
    setValDefault01(constS.BLANK);
    setValDefault02(constS.BLANK);
    setValDefault03(defaultCombo);
    setValDefault04(defaultCombo);
    setValDefault05(constS.BLANK);
    setValDefault06(constS.BLANK);
    fetchDataInit(constS.AccountListTBL);
  }

  const refColsKey = (e) => {
    setValDefaultKey(e.target.value);
  };

  const refCols01 = (e) => {
    setValDefault01(e.target.value);
  };

  const refCols02 = (e) => {
    setValDefault02(e.target.value);
  };

  const refCols03 = (event, value) => {
    if (value !== null) { setValDefault03(value); }
    else {
      setValDefault03(defaultCombo);
    }
  };

  const refCols04 = (event, value) => {
    if (value !== null) { setValDefault04(value); }
    else {
      setValDefault04(defaultCombo);
    }
  };

  const refCols05 = (e) => {
    setValDefault05(e.target.value);
  };

  const refCols06 = (e) => {
    setValDefault06(e.target.value);
  };

  const onClickSelected = (val) => {
    setValDefaultKey(val.id);
    setValDefault01(val.Cols01);
    setValDefault02(val.Cols02);
    if (val.Cols03 === constS.ZERO) {
      setValDefault03(defaultCombo);
    }
    else {
      dataAccountPermissionCombo.forEach((ele) => {
        if (ele.code === val.Cols03) { setValDefault03(ele); };
      });
    };
    if (val.Cols04 === constS.ZERO) {
      setValDefault04(defaultCombo);
    }
    else {
      dataSexCombo.forEach((ele) => {
        if (ele.code === val.Cols04) { setValDefault04(ele); };
      });
    };

    setValDefault05(val.Cols05);
    setValDefault06(val.Cols06);
  };

  const onClickPrev = () => {
    if (currentPage === 1) {
      return;
    }
    const curpage = currentPage - 1;
    const last = Number(curpage) * 10;
    const first = Number(last) - 10;
    const data = [].concat(dataList).splice(first, last - first);
    setCurrentData(data);
    setCurrentPage(curpage);
  };

  const onClickNext = () => {
    if (Number(currentPage) * 10 > dataList.length) {
      return;
    }
    const curpage = Number(currentPage) + 1;
    const last = Number(curpage) * 10 > dataList.length ? dataList.length : Number(curpage) * 10;
    const first = Number(curpage - 1) * 10;
    const data = [].concat(dataList).splice(first, last - first);
    setCurrentData(data);
    setCurrentPage(curpage);
  };

  const onHandleSearch = () => {
    fetchDatabyCombo(constS.AccountListTBL);
  }

  const refOK = () => {
    setShowOKMessage(constS.BLANK);
  };

  return [
    <Page title="Quản lý danh sách khách hàng">
      <Container maxWidth="xl">
        <ExMessageOK message={showOKMessage} refOK={refOK}></ExMessageOK>
        <Typography variant={"h1"}>{"Quản lý danh sách khách hàng"}</Typography>
        <Paper variant="outlined">
          <Grid container spacing={3} sx={{ mt: 0, ml: 0, mb: 2, fontSize: 40 }}>
            <Grid item md={4}>{"Mã khách hàng"}
            </Grid>
            <Grid item md={7.5}>
              <ExTextField
                id={constS.id} inputRef={refColsKey} valDefault={valDefaultKey}
                placeholder={constM.placeKey} Icon={<QrCodeOutlinedIcon />} maxLength={50} disabled={true}></ExTextField>
            </Grid>

            <Grid item md={4}>{"Tên khách hàng"}
            </Grid>
            <Grid item md={7.5}>
              <ExTextField
                id={constS.Cols01} inputRef={refCols01} valDefault={valDefault01}
                placeholder={constM.placeName} Icon={<DriveFileRenameOutlineIcon />} maxLength={100}></ExTextField>
            </Grid>

            <Grid item md={4}>{"Điện thoại"}
            </Grid>
            <Grid item md={7.5}>
              <ExNumber
                id={constS.Cols02} inputRef={refCols02} valDefault={valDefault02}
                placeholder={constM.placeCustomerPhone} Icon={<PhoneInTalkIcon />} maxLength={12} disabled={true}></ExNumber>
            </Grid>

            <Grid item md={4}>{"Quyền hạn"}
            </Grid>
            <Grid item md={7.5}>
              <ExComboBox
                id={constS.Cols03} comboRef={refCols03} valDefault={valDefault03}
                placeholder={constM.placePermission} DataSource={dataAccountPermissionCombo}></ExComboBox>
            </Grid>

            <Grid item md={4}>{"Giới tính"}
            </Grid>
            <Grid item md={7.5}>
              <ExComboBox
                id={constS.Cols04} comboRef={refCols04} valDefault={valDefault04}
                placeholder={constM.placeCustomerSex} DataSource={dataSexCombo}></ExComboBox>
            </Grid>

            <Grid item md={4}>{"Thư điện tử"}
            </Grid>
            <Grid item md={7.5}>
              <ExTextField
                id={constS.Cols05} inputRef={refCols05} valDefault={valDefault05}
                placeholder={constM.placeCustomerEmail} Icon={<ForwardToInboxOutlinedIcon />} maxLength={100}></ExTextField>
            </Grid>

            <Grid item md={4}>{"Hình đại diện"}
            </Grid>
            <Grid item md={7.5}>
              <ExTextField
                id={constS.Cols06} inputRef={refCols06} valDefault={valDefault06}
                placeholder={constM.placeAccountAvatar} Icon={<ContactPageOutlinedIcon />} maxLength={400}></ExTextField>
            </Grid>
            <Grid item >
              <ExButtonIconBef title={"Tìm kiếm theo tên"} icon='\static\illustrations\search.png' onClick={onHandleSearch} />
            </Grid>
            <Grid item >
              <ExButtonIconBef title={"Chỉnh sửa"} icon='\static\illustrations\edit.png' onClick={onHandleUpdate} />
            </Grid>
            <Grid item >
              <ExButtonIconBef title={"Xóa nhập"} icon='\static\illustrations\delete.png' onClick={onHandleClear} />
            </Grid>
          </Grid>
        </Paper>

        <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 2 }}>
          {currentData.map((item) => (
            <Grid item md={12}>
              <ExCardCustomer DataSource={item} onClick={onClickSelected} selectedId={valDefaultKey}></ExCardCustomer>
            </Grid>
          ))}

          <Grid item md={6} sx={{ textAlign: "left" }}>
            <ExButtonIconBef title={"Trang trước"} icon='\static\illustrations\prev.png' onClick={onClickPrev} />
          </Grid>
          <Grid item md={6} sx={{ textAlign: "right" }}>
            <ExButtonIconAft title={"Trang sau"} icon='\static\illustrations\next.png' onClick={onClickNext} />
          </Grid>

        </Grid>
      </Container >
    </Page>
  ];
}
