import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, onValue } from "firebase/database";
import firebaseDB from '../initfirebase'
import { Avatar, Button, Card, Container, Grid, Divider, Typography, CardMedia } from '@mui/material';
import ExCard from '../components/ExCard';
import Page from '../components/Page';
import { merge } from 'lodash';
import { useEffect, useState, useRef } from 'react';
import constS from "../const/constS";
import ExCardMenu from "../components/ExCardMenu";
import ExModalRuler from "../components/ExModalRuler";
import ExTextField from "../components/ExTextField";
import ExButtonIconBef from "../components/ExButtonIconBef";
import ExButtonIconAft from "../components/ExButtonIconAft";
// ----------------------------------------------------------------------
export default function Home() {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

  const [loginUser, setLoginUser] = useState(null);
  const [subMenuSelected, setSubMenuSelected] = useState(constS.BLANK);
  const [listAccount, setListAccount] = useState([]);
  const [dataNameCombo, setDataNameCombo] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [dataTypeCombo, setDataTypeCombo] = useState([]);
  const [dataRulerCombo, setDataRulerCombo] = useState([]);
  const [showModalRuler, setShowModalRuler] = useState(constS.BLANK);
  const [valDefault01, setValDefault01] = useState(constS.BLANK);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);

  const db = getDatabase(firebaseDB);

  useEffect(() => {
    fetchDataAccount();
    fetchDataProduct();
    fetchDataCombo(constS.ProductTypeTBL);
    fetchDataCombo(constS.ProductNameTBL);
    fetchDataCombo(constS.RulerTBL);
  }, []);

  useEffect(() => {
    if (!listAccount || listAccount.length <= 0) {
      return;
    }

    CheckDataLogin();
  }, [listAccount]);

  useEffect(() => {
    if (subMenuSelected !== constS.BLANK) {
      fetchDataProduct();
    };
  }, [subMenuSelected]);

  async function CheckDataLogin() {
    const auth = getAuth(firebaseDB);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        listAccount.forEach((row) => {
          if (row.Cols02 === user.email.substring(0, user.email.length - 10)) {
            setLoginUser(row);
            return;
          }
        });
      }
    });
  };

  async function fetchDataAccount() {
    const dbRef = ref(db, constS.AccountListTBL);
    const result = [];
    let obj = null;
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        obj = childSnapshot.exportVal();
        result.push(merge({ id: childSnapshot.key }, obj));
      });
      const sortData = [].concat(result)
        .sort((a, b) => Number(a.Cols02) > Number(b.Cols02) ? 1 : -1);
      setListAccount(sortData);
    }, {
      onlyOnce: true
    });
    return result;
  };

  async function fetchDataProduct() {
    const dbRef = ref(db, constS.ProductListTBN);
    const result = [];
    let obj = null;
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        obj = childSnapshot.exportVal();
        if (obj.Cols09 === "1") {
          result.push(merge({ id: childSnapshot.key }, obj));
        }
      });
      const sortData = [].concat(result)
        .sort((a, b) => Number(a.Cols01) > Number(b.Cols01) ? 1 : -1);

      if (subMenuSelected !== constS.BLANK) {
        const listData = sortData.filter((el) => String(el.Cols03).includes(subMenuSelected));
        const data = [].concat(listData).splice(0, 12);
        setDataList(listData);
        setCurrentData(data);
        setCurrentPage(1);
      } else {
        const data = [].concat(sortData).splice(0, 12);
        setDataList(sortData);
        setCurrentData(data);
        setCurrentPage(1);
      }
    }, (error) => {
      console.log(error);
    });
  };

  async function fetchDatabyCombo(tbName) {
    const dbRef = ref(db, tbName);
    const result = [];
    let obj = null;
    const listSearch = String(valDefault01).split(constS.SPACE);
    let isValid = false;
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        obj = childSnapshot.exportVal();
        const item = dataNameCombo.filter((p) => String(p.code).includes(obj.Cols01))[0];

        if (valDefault01 !== constS.BLANK) {
          isValid = false;
          if (listSearch && listSearch.length > 0) {
            listSearch.forEach((it) => {
              if (it !== constS.BLANK && String(item.name).toLowerCase().includes(it.toLowerCase())) {
                isValid = true;
              }
            });
          }
          if (isValid) {
            if (obj.Cols09 === "1") {
              result.push(merge({ id: childSnapshot.key }, obj));
            }
          }
        }
        else {
          if (obj.Cols09 === "1") {
            result.push(merge({ id: childSnapshot.key }, obj));
          }
        }
      });

      const sortData = [].concat(result)
        .sort((a, b) => Number(a.Cols01) > Number(b.Cols01) ? 1 : -1);

      const data = [].concat(sortData).splice(0, 12);
      setDataList(sortData);
      setCurrentData(data);
      setCurrentPage(1);
    }, {
      onlyOnce: true
    });
    return result;
  };

  async function fetchDataCombo(tbName) {
    const dbRef = ref(db, tbName);
    const result = [];
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        result.push(childSnapshot.exportVal());
      });
      const sortData = [].concat(result)
        .sort((a, b) => Number(a.code) > Number(b.code) ? 1 : -1);

      if (tbName === constS.ProductNameTBL) {
        setDataNameCombo(sortData);
      }
      if (tbName === constS.ProductTypeTBL) {
        const filter = sortData.filter(item => item.code !== "0");
        setDataTypeCombo(filter);
      }
      if (tbName === constS.RulerTBL) {
        const filter = sortData.filter(item => item.code !== "0");
        setDataRulerCombo(filter);
      }
    }, {
      onlyOnce: true
    });
    return result;
  };

  const onGetProductName = (val) => {
    const res = dataNameCombo.filter(p => String(p.code).startsWith(val));
    if (res && res.length <= 0) {
      return constS.BLANK;
    }
    return res[0].name;
  };

  const onClickSubMenu = (val) => {
    setSubMenuSelected(val);
  };

  const onClickShopee = () => {
    window.location.href = "https://shopee.vn/shop/311456682/?fbclid=IwAR0rB";
  };

  const onClickFacebook = () => {
    window.location.href = "https://www.facebook.com/tinh.vothiphuoc/";
  };

  const onClickShowModal = (val) => {
    setShowModalRuler(val);
  };

  const refClose = () => {
    setShowModalRuler(constS.BLANK);
  };

  const refCols01 = (e) => {
    setValDefault01(e.target.value);
  };

  const onSearchProducts = () => {
    fetchDatabyCombo(constS.ProductListTBN);
  }

  const onClickPrev = () => {
    if (currentPage === 1) {
      return;
    }
    const curpage = currentPage - 1;
    const last = Number(curpage) * 12;
    const first = Number(last) - 12;
    const data = [].concat(dataList).splice(first, last - first);
    setCurrentData(data);
    setCurrentPage(curpage);
  };

  const onClickNext = () => {
    if (Number(currentPage) * 12 > dataList.length) {
      return;
    }
    const curpage = Number(currentPage) + 1;
    const last = Number(curpage) * 12 > dataList.length ? dataList.length : Number(curpage) * 12;
    const first = Number(curpage - 1) * 12;
    const data = [].concat(dataList).splice(first, last - first);
    setCurrentData(data);
    setCurrentPage(curpage);
  };

  const onClickZalo = () => {
    window.location.href = "https://zalo.me/0938123358";
  };

  return (
    <Page title={"Trang chủ"}>
      <ExModalRuler isShow={showModalRuler} DataSource={dataRulerCombo} refClose={refClose}></ExModalRuler>
      <Container key={"Home"} maxWidth="xl">
        {!isMobile && <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 2 }}>
          {dataTypeCombo.map((item) => (
            <Grid item md={1}>
              <ExCardMenu onClick={onClickSubMenu} DataSource={item}></ExCardMenu>
            </Grid>
          ))}
        </Grid>}

        {isMobile && <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 2 }}>
          {dataTypeCombo.map((item) => (
            <Grid item md={2}>
              <ExCardMenu isMobile={isMobile} onClick={onClickSubMenu} DataSource={item}></ExCardMenu>
            </Grid>
          ))}
        </Grid>}

        <Divider sx={{ borderStyle: 'dashed' }} />
        <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 2 }}>
          <Grid item md={isMobile ? 8 : 10}>
            <ExTextField
              id={constS.Cols01} inputRef={refCols01} inputRefCapture={refCols01} valDefault={valDefault01}
              placeholder={"Tìm theo tên sản phẩm"}></ExTextField>
          </Grid>
          <Grid item md={isMobile ? 4 : 2}>
            <ExButtonIconBef fullWidth title={"Tìm kiếm"} icon='\static\illustrations\search.png' onClick={onSearchProducts} />
          </Grid>
        </Grid>

        {!isMobile && <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 2 }}>
          {currentData.map((item) => (
            <Grid item md={3}>
              <ExCard DataSource={item} dataTypeCombo={dataTypeCombo} cardName={onGetProductName(item.Cols01)} loginUser={loginUser}></ExCard>
            </Grid>
          ))}
          <Grid item md={12}></Grid>
          <Grid item md={6} sx={{ textAlign: "left" }}>
            <ExButtonIconBef title={"Trang trước"} icon='\static\illustrations\prev.png' onClick={onClickPrev} />
          </Grid>
          <Grid item md={6} sx={{ textAlign: "right" }}>
            <ExButtonIconAft title={"Trang sau"} icon='\static\illustrations\next.png' onClick={onClickNext} />
          </Grid>

        </Grid>}

        {isMobile && <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 2 }}>
          {currentData.map((item) => (
            <Grid item md={12}>
              <ExCard isMobile={isMobile} DataSource={item} dataTypeCombo={dataTypeCombo} cardName={onGetProductName(item.Cols01)} loginUser={loginUser}></ExCard>
            </Grid>
          ))}

          <Grid item md={6} sx={{ textAlign: "left" }}>
            <ExButtonIconBef title={"Trang trước"} icon='\static\illustrations\prev.png' onClick={onClickPrev} />
          </Grid>
          <Grid item md={6} sx={{ textAlign: "right" }}>
            <ExButtonIconAft title={"Trang sau"} icon='\static\illustrations\next.png' onClick={onClickNext} />
          </Grid>

        </Grid>}

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 2 }}>

          <Grid item md={isMobile ? 6 : 3}>
            <Card sx={{ padding: 0, margin: 0, boxShadow: 0, border: 1, borderRadius: 2, height: isMobile ? 250 : 120 }}>
              <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 0 }}>
                <Grid item md={isMobile ? 3 : 2}>
                  <CardMedia
                    alt="rectangle"
                    component="img"
                    height={isMobile ? 110 : 50}
                    image="\static\illustrations\chinhsach_1.webp"
                  />
                </Grid>
                <Grid item md={isMobile ? 9 : 10}>
                  <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 0 }}>
                    <Grid item xs={12} sm={12} md={12} sx={{ fontSize: isMobile ? constS.fontSizeM : constS.fontSize, color: "green" }}>{"Miễn phí vận chuyển"}</Grid>
                    <Grid item xs={12} sm={12} md={12} sx={{ fontSize: isMobile ? constS.fontSizeM : constS.fontSize, mr: 2 }}>{"Đơn từ 10tr trong nội thành TP.HCM và Bình Dương"}</Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card >
          </Grid>

          <Grid item md={isMobile ? 6 : 3}>
            <Card sx={{ padding: 0, margin: 0, boxShadow: 0, border: 1, borderRadius: 2, height: isMobile ? 250 : 120 }}>
              <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 0 }}>
                <Grid item md={isMobile ? 3 : 2}>
                  <CardMedia
                    component="img"
                    alt="rectangle"
                    height={isMobile ? 110 : 50}
                    image="\static\illustrations\chinhsach_2.webp"
                  />
                </Grid>
                <Grid item md={isMobile ? 9 : 10}>
                  <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 0 }}>
                    <Grid item xs={12} sm={12} md={12} sx={{ fontSize: isMobile ? constS.fontSizeM : constS.fontSize, color: "green" }}>{"Miễn phí đổi trả"}</Grid>
                    <Grid item xs={12} sm={12} md={12} sx={{ fontSize: isMobile ? constS.fontSizeM : constS.fontSize, mr: 2 }}>{"Đối với sản phẩm lỗi sản xuất hoặc vận chuyển"}</Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card >
          </Grid>

          <Grid item md={isMobile ? 6 : 3}>
            <Card sx={{ padding: 0, margin: 0, boxShadow: 0, border: 1, borderRadius: 2, height: isMobile ? 250 : 120 }}>
              <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 0 }}>
                <Grid item md={isMobile ? 3 : 2}><CardMedia
                  component="img"
                  alt="rectangle"
                  height={isMobile ? 110 : 50}
                  image="\static\illustrations\chinhsach_3.webp"
                />
                </Grid>
                <Grid item md={isMobile ? 9 : 10}>
                  <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 0 }}>
                    <Grid item xs={12} sm={12} md={12} sx={{ fontSize: isMobile ? constS.fontSizeM : constS.fontSize, color: "green" }}>{"Điều kiện mua giá sỉ"}</Grid>
                    <Grid item xs={12} sm={12} md={12} sx={{ fontSize: isMobile ? constS.fontSizeM : constS.fontSize, mr: 2 }}>{"Đối với hóa đơn từ 3 triệu"}</Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card >
          </Grid>

          <Grid item md={isMobile ? 6 : 3}>
            <Card sx={{ padding: 0, margin: 0, boxShadow: 0, border: 1, borderRadius: 2, height: isMobile ? 250 : 120 }}>
              <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 0 }}>
                <Grid item md={isMobile ? 3 : 2}><CardMedia
                  component="img"
                  alt="rectangle"
                  height={isMobile ? 110 : 50}
                  image="\static\illustrations\chinhsach_4.webp" />
                </Grid>
                <Grid item md={isMobile ? 9 : 10}>
                  <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 0 }}>
                    <Grid item xs={12} sm={12} md={12} sx={{ fontSize: isMobile ? constS.fontSizeM : constS.fontSize, color: "green" }}>{"Ưu đãi khi mua SLL"}</Grid>
                    <Grid item xs={12} sm={12} md={12} sx={{ fontSize: isMobile ? constS.fontSizeM : constS.fontSize, mr: 2 }}>{"Được mua với mức giá rẻ nhất thị trường"}</Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card >
          </Grid>

        </Grid>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {!isMobile && <Grid container spacing={1} sx={{ m: 0, p: 0 }}>

          <Grid item md={3}><Typography variant="h6">{"Chính sách"}</Typography></Grid>
          <Grid item md={3}><Typography variant="h6">{"Hướng dẫn"}</Typography></Grid>
          <Grid item md={6}><Typography variant="h6">{"Liên hệ"}</Typography></Grid>

          <Grid item md={3}><Button onClick={() => onClickShowModal("01")} sx={{ color: "black" }}>{"Chính sách bảo mật"}</Button></Grid>
          <Grid item md={3}><Button onClick={() => onClickShowModal("05")} sx={{ color: "black" }}>{"Hướng dẫn mua hàng"}</Button></Grid>
          <Grid item md={6}>{"Văn phòng: Chung cư Samsora Riverside Bình Thắng, Dĩ An, Bình Dương"}</Grid>

          <Grid item md={3}><Button onClick={() => onClickShowModal("02")} sx={{ color: "black" }}>{"Chính sách vận chuyển"}</Button></Grid>
          <Grid item md={3}><Button onClick={() => onClickShowModal("06")} sx={{ color: "black" }}>{"Hướng dẫn thanh toán"}</Button></Grid>
          <Grid item md={6}>{"Kho hàng: Bình Thắng, Dĩ An, Bình Dương"}</Grid>

          <Grid item md={3}><Button onClick={() => onClickShowModal("03")} sx={{ color: "black" }}>{"Chính sách đổi trả"}</Button></Grid>
          <Grid item md={3}><Button onClick={() => onClickShowModal("07")} sx={{ color: "black" }}>{"Hướng dẫn giao nhận"}</Button></Grid>
          <Grid item md={6}>{"Điện thoại: 093.8123.258"}</Grid>

          <Grid item md={3}><Button onClick={() => onClickShowModal("04")} sx={{ color: "black" }}>{"Chính sách kiểm hàng"}</Button></Grid>
          <Grid item md={3}><Button onClick={() => onClickShowModal("08")} sx={{ color: "black" }}>{"Điều khoản dịch vụ"}</Button></Grid>
          <Grid item md={6}>{"Email: vothiphuoctinh@gmail.com"}</Grid>

          <Grid item md={3}><Button onClick={onClickShopee} sx={{ color: "black" }}>{"Liên kết Shopee     "}<Avatar
            alt="rectangle"
            src="\static\illustrations\shopee.png"
          /></Button></Grid>
          <Grid item md={3}><Button onClick={onClickFacebook} sx={{ color: "black" }}>{"Mạng xã hội FaceBook    "}<Avatar
            alt="rectangle"
            src="\static\illustrations\facebook.webp"
          /></Button></Grid>
          <Grid item md={6}><Button onClick={onClickZalo} sx={{ color: "black" }}>{"Nhắn tin Zalo"}<Avatar
            alt="rectangle"
            src="\static\illustrations\zalo-icon.png"
          /></Button></Grid>
        </Grid>}

        {isMobile && <Grid container spacing={1} sx={{ m: 0, p: 0 }}>

          <Grid item md={6}><Typography variant="h2">{"Chính sách"}</Typography></Grid>
          <Grid item md={6}><Typography variant="h2">{"Hướng dẫn"}</Typography></Grid>

          <Grid item md={6}><Button onClick={() => onClickShowModal("01")} sx={{ color: "black", fontSize: constS.fontSizeM }}>{"Chính sách bảo mật"}</Button></Grid>
          <Grid item md={6}><Button onClick={() => onClickShowModal("05")} sx={{ color: "black", fontSize: constS.fontSizeM }}>{"Hướng dẫn mua hàng"}</Button></Grid>

          <Grid item md={6}><Button onClick={() => onClickShowModal("02")} sx={{ color: "black", fontSize: constS.fontSizeM }}>{"Chính sách vận chuyển"}</Button></Grid>
          <Grid item md={6}><Button onClick={() => onClickShowModal("06")} sx={{ color: "black", fontSize: constS.fontSizeM }}>{"Hướng dẫn thanh toán"}</Button></Grid>

          <Grid item md={6}><Button onClick={() => onClickShowModal("03")} sx={{ color: "black", fontSize: constS.fontSizeM }}>{"Chính sách đổi trả"}</Button></Grid>
          <Grid item md={6}><Button onClick={() => onClickShowModal("07")} sx={{ color: "black", fontSize: constS.fontSizeM }}>{"Hướng dẫn giao nhận"}</Button></Grid>

          <Grid item md={6}><Button onClick={() => onClickShowModal("04")} sx={{ color: "black", fontSize: constS.fontSizeM }}>{"Chính sách kiểm hàng"}</Button></Grid>
          <Grid item md={6}><Button onClick={() => onClickShowModal("08")} sx={{ color: "black", fontSize: constS.fontSizeM }}>{"Điều khoản dịch vụ"}</Button></Grid>

          <Grid item md={12}><Typography variant="h2">{"Liên hệ"}</Typography></Grid>
          <Grid item md={12} sx={{ fontSize: constS.fontSizeM }}>{"Văn phòng: Chung cư Samsora Riverside Bình Thắng, Dĩ An, Bình Dương"}</Grid>
          <Grid item md={12} sx={{ fontSize: constS.fontSizeM }}>{"Kho hàng: Bình Thắng, Dĩ An, Bình Dương"}</Grid>
          <Grid item md={12} sx={{ fontSize: constS.fontSizeM }}>{"Điện thoại: 093.8123.258"}</Grid>
          <Grid item md={12} sx={{ fontSize: constS.fontSizeM }}>{"Email: vothiphuoctinh@gmail.com"}</Grid>
          <Grid item md={6}><Button onClick={onClickShopee} sx={{ color: "black", fontSize: constS.fontSizeM }}>{"Liên kết Shopee     "}<Avatar
            alt="rectangle"
            src="\static\illustrations\shopee.png"
          /></Button></Grid>
          <Grid item md={6}><Button onClick={onClickFacebook} sx={{ color: "black", fontSize: constS.fontSizeM }}>{"Mạng xã hội FaceBook    "}<Avatar
            alt="rectangle"
            src="\static\illustrations\facebook.webp"
          /></Button></Grid>
          <Grid item md={6}><Button onClick={onClickZalo} sx={{ color: "black", fontSize: constS.fontSizeM }}>{"Nhắn tin Zalo"}<Avatar
            alt="rectangle"
            src="\static\illustrations\zalo-icon.png"
          /></Button></Grid>
        </Grid>}

      </Container>
    </Page>
  );
}
