import { Navigate, useRoutes } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import firebaseDB from './initfirebase'
// layouts
import MainLayout from './layouts/MainLayout';
import Home from './pages/Home';
import CodeList from './pages/CodeList';
import CodeMainternance from './pages/CodeMainternance';
import ProductList from './pages/ProductList';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import Login from './pages/Login';
import Register from './pages/Register';
import AccountManagement from './pages/AccountManagement';
import AccountMainternance from './pages/AccountMainternance';
import BillList from './pages/BillList';
import BillDetail from './pages/BillDetail';
import OrderCard from './pages/OrderCard';
import MonneyManager from './pages/MonneyManager';
import OrderBillCard from './pages/OrderBillCard';
// ----------------------------------------------------------------------

export default function Router() {
  const auth = getAuth(firebaseDB);

  return useRoutes([
    {
      path: '',
      element: <MainLayout />,
      children: [
        { path: '', element: <Home /> },
        { path: 'ordercard', element: <OrderCard /> },
        { path: 'orderbillcard', element: <OrderBillCard /> },
        { path: 'codelist', element: <CodeList /> },
        { path: 'codemainternance', element: <CodeMainternance /> },
        { path: 'accountmanagement', element: <AccountManagement /> },
        { path: 'listproducts', element: <ProductList /> },
        { path: 'listbills', element: <BillList /> },
        { path: 'monneymanager', element: <MonneyManager /> },
        { path: 'billdetail', element: <BillDetail /> },
        { path: 'accountmainternance', element: <AccountMainternance /> },
      ],
    },
    {
      path: '',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
      ],
    },
  ]);
}
