import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import firebaseDB from '../initfirebase'
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography, Stack, Button, Divider, FormControlLabel, Checkbox } from '@mui/material';
import { useState } from 'react';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import ExTextField from '../components/ExTextField';
import ExNumber from '../components/ExNumber';
import constS from '../const/constS';
import constM from '../const/constM';
import Iconify from '../components/Iconify';
import ExMessageOK from '../components/ExMessageOK';
import ExButtonIconBef from '../components/ExButtonIconBef';
const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  const navigate = useNavigate();
  const [showOKMessage, setShowOKMessage] = useState(constS.BLANK);
  const smUp = useResponsive('up', 'sm');
  const mdUp = useResponsive('up', 'md');
  const [valDefault01, setValDefault01] = useState(constS.BLANK);
  const [valDefault02, setValDefault02] = useState(constS.BLANK);

  const refCols01 = (e) => {
    setValDefault01(e.target.value);
  };

  const refCols02 = (e) => {
    setValDefault02(e.target.value);
  };

  const CheckInput = () => {
    if (valDefault01 === constS.BLANK) {
      setShowOKMessage("Vui lòng nhập số điện thoại!");
      return false;
    }

    if (valDefault02 === constS.BLANK) {
      setShowOKMessage("Vui lòng nhập mật khẩu!");
      return false;
    }

    if (valDefault02.length < constS.Code6) {
      setShowOKMessage("Vui lòng nhập mật khẩu từ 6 kí tự!");
      return false;
    }

    return true;
  };

  const onHandleLogin = () => {
    if (!CheckInput()) {
      return;
    }

    const auth = getAuth(firebaseDB);
    signInWithEmailAndPassword(auth, valDefault01 + constS.EMAILENDFIXED, valDefault02)
      .then((userCredential) => {
        navigate('/');
      })
      .catch((error) => {
      });
  };

  const refOK = () => {
    setShowOKMessage(constS.BLANK);
  };

  return (
    <Page title="Đăng nhập">
      <ExMessageOK message={showOKMessage} refOK={refOK}></ExMessageOK>
      <RootStyle>
        <HeaderStyle>
          <Logo />

          {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              <Link variant="subtitle2" component={RouterLink} sx={{ fontSize: isMobile ? constS.fontSizeM : constS.fontSize }} to="/register">{"Đăng kí tài khoản mới"}</Link>
            </Typography>
          )}
        </HeaderStyle>

        {!isMobile && mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>{"Chào mừng đến với website mayshopkids.com"}</Typography>
            <img src="/static/illustrations/illustration_login.png" alt="login" />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Typography variant={isMobile ? "h1" : "h4"} gutterBottom>{"Đăng nhập mayshopkids.com"}</Typography>
            <Typography sx={{ color: 'text.secondary', mb: 5, fontSize: isMobile ? constS.fontSizeM : constS.fontSize }}>{"Vui lòng nhập thông tin"}</Typography>

            <Stack direction="row" spacing={2}>
              <Button fullWidth size="large" color="inherit" variant="outlined">
                <GoogleIcon />
              </Button>

              <Button fullWidth size="large" color="inherit" variant="outlined">
                <FacebookIcon />
              </Button>

              <Button fullWidth size="large" color="inherit" variant="outlined">
                <TwitterIcon />
              </Button>
            </Stack>

            <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: isMobile ? constS.fontSizeM : constS.fontSize }}>{"Hoặc"}</Typography>
            </Divider>

            <Stack spacing={3}>
              <ExNumber
                id={constS.Cols01} inputRef={refCols01} inputRefCapture={refCols01} valDefault={valDefault01}
                placeholder={"Số điện thoại"} Icon={getIcon('eva:phone-outline')} maxLength={11}>
              </ExNumber>

              <ExTextField
                id={constS.Cols02} inputRef={refCols02} inputRefCapture={refCols02} valDefault={valDefault02}
                placeholder={"Mật khẩu"} Icon={getIcon('eva:edit-2-outline')} maxLength={50} isPassWord={true}>
              </ExTextField>
            </Stack>
            <Divider sx={{ my: 3 }}></Divider>

            <ExButtonIconBef title={"Đăng nhập"} icon='\static\illustrations\login.png' onClick={onHandleLogin} />
            {!smUp && (
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                <Link variant="subtitle2" component={RouterLink} to="/register">{"Đăng kí tài khoản mới"}</Link>
              </Typography>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page >
  );
}
