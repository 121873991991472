import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Iconify from "./Iconify";
import { Button, Grid } from "@mui/material";
import constS from '../const/constS';

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

export default function ExCardMenu({ DataSource, onClick, isMobile }) {
  return (
    <Card sx={{ padding: 0, margin: 0, boxShadow: 0, border: 0, borderRadius: 0 }}>
      <Grid container rowSpacing={0} spacing={0} sx={{ m: 0, p: 0 }}>
        <Grid item md={12}>
          <Button onClick={() => onClick(DataSource.code)} sx={{ p: 0, m: 0 }}><CardMedia
            component="img"
            height={isMobile ? 130 : 100}
            width={isMobile ? 130 : 100}
            image={DataSource.image}
            alt="may shop"
          /></Button>
        </Grid>
        <Grid item md={12}>
          <CardContent sx={{ p: 0, m: 0, height: isMobile ? 110 : 60 }}>
            <Button onClick={() => onClick(DataSource.code)} sx={{ p: 0, fontSize: isMobile ? constS.fontSizeM : constS.fontSize, m: 0 }}>{DataSource.name}</Button>
          </CardContent>
        </Grid>
      </Grid>
    </Card >
  );
};