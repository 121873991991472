import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, onValue, update } from "firebase/database";
import firebaseDB from '../initfirebase'
import { Container, Typography, Button, Paper, Grid, InputLabel } from '@mui/material';
import { useEffect, useState } from 'react';
import Page from "../components/Page";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import constM from '../const/constM';
import constS from '../const/constS';
import ExTextField from "../components/ExTextField";
import ExNumber from "../components/ExNumber";
import ExComboBox from "../components/ExComboBox";
import ImageIcon from '@mui/icons-material/Image';
import { merge } from 'lodash';
import ExMessageOK from "../components/ExMessageOK";
import ExMessageYes from "../components/ExMessageYes";
import ExButtonIconBef from "../components/ExButtonIconBef";

// ----------------------------------------------------------------------

const defaultCombo = { code: constS.ZERO, name: "" };
export default function AccountManagement() {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  const date = new Date();
  const curDate = date.getFullYear().toString()
    + (date.getMonth() + 1).toString().padStart(2, "0")
    + date.getDate().toString().padStart(2, "0");

  const [showOKMessage, setShowOKMessage] = useState(constS.BLANK);
  const [showYesMessage, setShowYesMessage] = useState(constS.BLANK);

  const [dataSexCombo, setDataSexCombo] = useState([]);
  const [listAccount, setListAccount] = useState([]);
  const [loginUser, setLoginUser] = useState(null);

  const [valDefault01, setValDefault01] = useState(constS.BLANK);
  const [valDefault02, setValDefault02] = useState(constS.BLANK);
  const [valDefault03, setValDefault03] = useState(defaultCombo);
  const [valDefault04, setValDefault04] = useState(constS.BLANK);
  const [valDefault05, setValDefault05] = useState(constS.BLANK);
  const [valDefault06, setValDefault06] = useState(constS.BLANK);

  const db = getDatabase(firebaseDB);

  async function CheckDataLogin() {
    const auth = getAuth(firebaseDB);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        listAccount.forEach((row) => {
          if (row.Cols02 === user.email.substring(0, user.email.length - 10)) {
            setValDefault01(row.Cols02);
            setValDefault02(row.Cols01);

            if (row.Cols04 === constS.ZERO) {
              setValDefault03(defaultCombo);
            }
            else {
              dataSexCombo.forEach((ele) => {
                if (ele.code === row.Cols04) { setValDefault03(ele); };
              });
            };

            setValDefault04(row.Cols05);
            setValDefault05(row.Cols06);
            setValDefault06(row.Cols07);
            setLoginUser(row);
            return;
          }
        });
      }
    });
  };

  async function fetchDataInit(tbName) {
    const dbRef = ref(db, tbName);
    const result = [];
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        result.push(merge({ id: childSnapshot.key }, childSnapshot.exportVal()));
      });
      setListAccount(result)
    }, {
      onlyOnce: true
    });
    return result;
  };

  async function fetchDataCombo(tbName) {
    const dbRef = ref(db, tbName);
    const result = [];
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        result.push(childSnapshot.exportVal());
      });
      const sortData = [].concat(result)
        .sort((a, b) => Number(a.code) > Number(b.code) ? 1 : -1)

      if (tbName === constS.SexTBL) {
        setDataSexCombo(sortData);
      }
    }, {
      onlyOnce: true
    });
    return result;
  };

  useEffect(() => {
    fetchDataInit(constS.AccountListTBL);
    fetchDataCombo(constS.SexTBL);
  }, []);

  useEffect(() => {
    CheckDataLogin();
  }, [listAccount && dataSexCombo]);

  const getFormData = () => {
    const formData = {
      Cols01: valDefault02,
      Cols02: valDefault01,
      Cols04: valDefault03.code,
      Cols05: valDefault04,
      Cols06: valDefault05,
      Cols07: valDefault06,
      Cols96: curDate,
      Cols97: loginUser.Cols02,
    };
    return formData;
  };

  const onHandleUpdate = () => {
    update(ref(db, `/` + constS.AccountListTBL + `/${loginUser.id}/`), getFormData(''));
    setShowYesMessage("Cập nhật thành công!");
  };

  const refCols01 = (e) => {
    setValDefault01(e.target.value);
  };

  const refCols02 = (e) => {
    setValDefault02(e.target.value);
  };

  const refCols03 = (event, value) => {
    if (value !== null) { setValDefault03(value); }
    else {
      setValDefault03(defaultCombo);
    }
  };

  const refCols04 = (e) => {
    setValDefault04(e.target.value);
  };

  const refCols05 = (e) => {
    setValDefault05(e.target.value);
  };

  const refCols06 = (e) => {
    setValDefault06(e.target.value);
  };

  const refOK = () => {
    setShowOKMessage(constS.BLANK);
    setShowYesMessage(constS.BLANK);
  };

  return [
    <Page title="Quản lý tài khoản">
      <ExMessageOK message={showOKMessage} refOK={refOK}></ExMessageOK>
      <ExMessageYes message={showYesMessage} refOK={refOK}></ExMessageYes>
      <Container maxWidth="xl">
        <Typography variant={"h1"}>{"Quản lý tài khoản"}</Typography>
        <Paper variant="outlined">
          <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 2, fontSize: 40 }}>
            <Grid item md={4}>{"Số điện thoại"}
            </Grid>
            <Grid item md={7.5}>
              <ExNumber
                id={constS.Cols01} inputRef={refCols01} valDefault={valDefault01}
                placeholder={constM.placeCustomerPhone} Icon={<PhoneInTalkIcon />} maxLength={12} disabled={true}></ExNumber>
            </Grid>
            <Grid item md={4}>{"Giới tính"}
            </Grid>
            <Grid item md={7.5}>
              <ExComboBox
                id={constS.Cols03} comboRef={refCols03} valDefault={valDefault03}
                placeholder={constM.placeCustomerSex} DataSource={dataSexCombo}></ExComboBox>
            </Grid>
            <Grid item md={4}>{"Họ tên"}
            </Grid>
            <Grid item md={7.5}>
              <ExTextField
                id={constS.Cols02} inputRef={refCols02} valDefault={valDefault02}
                placeholder={constM.placeCustomerName} Icon={<DriveFileRenameOutlineIcon />} maxLength={100}></ExTextField>
            </Grid>

            <Grid item md={4}>{"Thư điện tử"}
            </Grid>
            <Grid item md={7.5}>
              <ExTextField
                id={constS.Cols04} inputRef={refCols04} valDefault={valDefault04}
                placeholder={constM.placeCustomerEmail} Icon={<ForwardToInboxIcon />} maxLength={50}></ExTextField>
            </Grid>
            <Grid item md={11.5}>{"Địa chỉ giao hàng"}
            </Grid>
            <Grid item md={11.5}>
              <ExTextField
                id={constS.Cols06} inputRef={refCols06} valDefault={valDefault06}
                placeholder={"Địa chỉ giao hàng"} Icon={<ForwardToInboxIcon />} maxLength={500}></ExTextField>
            </Grid>
            <Grid item md={11.5}>{"Hình đại diện"}
            </Grid>
            <Grid item md={11.5}>
              <ExTextField
                id={constS.Cols05} inputRef={refCols05} valDefault={valDefault05}
                placeholder={constM.placeAccountAvatar} Icon={<ImageIcon />} maxLength={500}></ExTextField>
            </Grid>

            <Grid item md={12} sx={{ textAlign: "left" }}>
              <ExButtonIconBef title={"Chỉnh sửa"} icon='\static\illustrations\edit.png' onClick={onHandleUpdate} />
            </Grid>
          </Grid>
        </Paper>
      </Container >
    </Page >
  ];
}
