import { useEffect, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import constS from '../const/constS';

export default function ExDate({ id, inputRef, valDefault, placeholder, disabled }) {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  const fonts = isMobile ? 50 : 32;
  const [value, changeValue] = useState(valDefault);

  useEffect(() => {
    changeValue(valDefault);
  }, [valDefault]);

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          id={id}
          slotProps={{
            textField: {
              disabled: true, fullWidth: true, p: 0, m: 0,
              sx: {
                "& .MuiInputBase-input": {
                  height: fonts,
                  fontSize: 40
                }
              }
            }
          }}
          value={dayjs(value)}
          placeholder={placeholder}
          disabled={disabled || false}
          onChange={inputRef}
          format="DD-MM-YYYY" />
      </LocalizationProvider>
    </div >
  );
}