import * as React from 'react';
import Card from '@mui/material/Card';
import { Grid } from "@mui/material";


export default function ExCardCode({ DataSource, onClick, selectedId }) {
  return (
    <Card key={DataSource.id} onClick={() => onClick(DataSource)} sx={{
      height: 260, p: 0, m: 0,
      backgroundColor: selectedId === DataSource.id ? "greenyellow" : "white"
    }}>
      <Grid container rowSpacing={0} spacing={0} sx={{ m: 0, p: 0, fontSize: 40 }}>
        <Grid item md={9}>{"Khóa : " + DataSource.id}</Grid>
        <Grid item md={3}>{"Mã : " + DataSource.code}</Grid>
        <Grid item md={12}>{"Nội Dung : " + DataSource.name}</Grid>
        <Grid item md={12}>{"Hình Ảnh : " + DataSource.image}</Grid>
      </Grid>
    </Card >
  );
};