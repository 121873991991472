import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, push, set, onValue, update } from "firebase/database";
import firebaseDB from '../initfirebase'
import { Container, Typography, Button, Paper, Grid, InputLabel } from '@mui/material';
import ExDataTable from '../components/ExDataTable';
import { useEffect, useState } from 'react';
import { merge } from 'lodash';
import Page from "../components/Page";
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import QrCodeOutlinedIcon from '@mui/icons-material/QrCodeOutlined';
import constM from '../const/constM';
import constS from '../const/constS';
import ExTextField from "../components/ExTextField";
import ExNumber from "../components/ExNumber";
import ExDate from "../components/ExDate";
import ExComboBox from "../components/ExComboBox";
import { useLocation, useNavigate } from 'react-router-dom';
import ExMessageYes from "../components/ExMessageYes";
import ExMessageOK from "../components/ExMessageOK";
import ExModalBill from "../components/ExModalBill";
import ExCardBill from "../components/ExCardBill";
import ExCardBillDetail from "../components/ExCardBillDetail";
import ExButtonIconBef from "../components/ExButtonIconBef";
import ExButtonIconAft from "../components/ExButtonIconAft";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
// ----------------------------------------------------------------------


// const columns2Mobile = [
//   { field: constS.id, headerName: "Mã", width: 300 },
//   { field: constS.Cols02Name, headerName: "Tên sản phẩm", width: 500 },
// ];
const defaultCombo = { code: constS.ZERO, name: "" };
const defaultCombo1 = { code: constS.Code1, name: "Đang xử lý" };

export default function BillList() {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  const formatter = new Intl.NumberFormat('vi', { style: 'currency', currency: 'VND' });
  const date = new Date();
  const curDate = date.getFullYear().toString()
    + (date.getMonth() + 1).toString().padStart(2, "0")
    + date.getDate().toString().padStart(2, "0");
  const navigate = useNavigate();
  const [showYesMessage, setShowYesMessage] = useState(constS.BLANK);
  const [showOKMessage, setShowOKMessage] = useState(constS.BLANK);

  const [showModalBill, setShowModalBill] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [dataSubList, setDataSubList] = useState([]);
  const [loginUser, setLoginUser] = useState(null);
  const [listAccount, setListAccount] = useState([]);
  const [listProducts, setListProducts] = useState([]);
  const [dataSelected, setDataSelected] = useState([]);
  const [state, setState] = useState(useLocation().state);

  const [dataOperatorTypeCombo, setDataOperatorTypeCombo] = useState([]);
  const [dataStatusCombo, setDataStatusCombo] = useState([]);
  const [dataCustomNameCombo, setDataCustomNameCombo] = useState([]);
  const [dataPhoneNumberCombo, setDataPhoneNumberCombo] = useState([]);
  const [dataProductNameCombo, setDataProductNameCombo] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);

  const [valDefaultKey, setValDefaultKey] = useState(constS.BLANK);
  const [valDefault01, setValDefault01] = useState(defaultCombo);
  const [valDefault02, setValDefault02] = useState(defaultCombo);
  const [valDefault03, setValDefault03] = useState(constS.ZERO);
  const [valDefault04, setValDefault04] = useState(constS.ZERO);
  const [valDefault05, setValDefault05] = useState(constS.ZERO);
  const [valDefault06, setValDefault06] = useState(curDate);
  const [valDefault07, setValDefault07] = useState(defaultCombo1);
  const [valDefault08, setValDefault08] = useState(constS.ZERO);
  const [valDefault10, setValDefault10] = useState(constS.BLANK);

  const [valDefault89, setValDefault89] = useState(defaultCombo);

  const [valDefaultCreateDate, setValDefaultCreateDate] = useState(constS.BLANK);
  const [valDefaultCreateUser, setValDefaultCreateUser] = useState(constS.BLANK);

  const db = getDatabase(firebaseDB);

  async function CheckDataLogin() {
    const auth = getAuth(firebaseDB);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        listAccount.forEach((row) => {
          if (row.Cols02 === user.email.substring(0, user.email.length - 10)) {
            setLoginUser(row);
            if (row && row.Cols03 !== "9") {
              navigate('/', { replace: true });
            }
            return;
          }
        });
      }

      if (!user) { navigate('/', { replace: false }); }
    });
  };

  async function fetchDataSearch(tbName) {
    const dbRef = ref(db, tbName);
    const result = [];
    onValue(dbRef, (snapshot) => {
      if (tbName === constS.BillListTBN) {
        snapshot.forEach((childSnapshot) => {
          const obj = childSnapshot.exportVal();
          const item1 = dataCustomNameCombo.filter((p) => String(p.code).includes(obj.Cols02))[0];
          const item2 = dataStatusCombo.filter((p) => String(p.code).includes(obj.Cols07))[0];
          result.push(merge({
            id: childSnapshot.key,
            Cols02Name: (item1 && item1.code !== constS.ZERO ? item1.name : obj.Cols10),
            Cols03Name: formatter.format(obj.Cols03),
            Cols04Name: formatter.format(obj.Cols04),
            Cols05Name: formatter.format(obj.Cols05),
            Cols09Name: formatter.format(obj.Cols09),
            Cols06Name: obj.Cols06.substring(6, 8) + "-" + obj.Cols06.substring(4, 6) + "-" + obj.Cols06.substring(0, 4),
            Cols07Name: (item2 ? item2.name : constS.BLANK)
          }, obj));
        });

        const sortData = [].concat(result)
          .sort((a, b) => Number(a.Cols01) > Number(b.Cols01) ? 1 : -1)
        const dataSearch = sortData.filter((p) => (valDefault01.code !== constS.ZERO ? String(p.Cols01).includes(valDefault01.code) : true) &&
          (valDefault02.code !== constS.ZERO ? String(p.Cols02).includes(valDefault02.code) : true) &&
          (valDefault10 !== constS.BLANK ? String(p.Cols10).includes(valDefault10) : true) &&
          (valDefault89.code === constS.ZERO ? true : valDefault89.code === constS.Code1 ? Number(valDefault06) < Number(p.Cols06) : valDefault89.code === constS.Code2 ? Number(valDefault06) === Number(p.Cols06) : Number(valDefault06) > Number(p.Cols06)) &&
          (valDefault07.code !== constS.ZERO ? String(p.Cols07).includes(valDefault07.code) : true)
        );

        const data = [].concat(dataSearch).splice(0, 10);
        setDataList(dataSearch);
        setCurrentData(data);
        setCurrentPage(1);
      };
    }, {
      onlyOnce: true
    });
    return result;
  };


  async function fetchDataInit(tbName) {
    const dbRef = ref(db, tbName);
    const result = [];
    onValue(dbRef, (snapshot) => {
      if (tbName === constS.BillListTBN) {
        snapshot.forEach((childSnapshot) => {
          if (state) {
            if (state === childSnapshot.key) {
              setState(constS.BLANK);
              const obj = childSnapshot.exportVal();
              const item1 = dataCustomNameCombo.filter((p) => String(p.code).includes(obj.Cols02))[0];
              const item2 = dataStatusCombo.filter((p) => String(p.code).includes(obj.Cols07))[0];
              const item3 = merge({
                id: childSnapshot.key,
                Cols02Name: (item1 && item1.code !== constS.ZERO ? item1.name : obj.Cols10),
                Cols03Name: formatter.format(obj.Cols03),
                Cols04Name: formatter.format(obj.Cols04),
                Cols05Name: formatter.format(obj.Cols05),
                Cols09Name: formatter.format(obj.Cols09),
                Cols06Name: obj.Cols06.substring(6, 8) + "-" + obj.Cols06.substring(4, 6) + "-" + obj.Cols06.substring(0, 4),
                Cols07Name: (item2 ? item2.name : constS.BLANK)
              }, obj);
              result.push(item3);
              setDataSelected(item3);
              onClickSelected(item3);
            }
          } else {
            const obj = childSnapshot.exportVal();
            const item1 = dataCustomNameCombo.filter((p) => String(p.code).includes(obj.Cols02))[0];
            const item2 = dataStatusCombo.filter((p) => String(p.code).includes(obj.Cols07))[0];
            result.push(merge({
              id: childSnapshot.key,
              Cols02Name: (item1 && item1.code !== constS.ZERO ? item1.name : obj.Cols10),
              Cols03Name: formatter.format(obj.Cols03),
              Cols04Name: formatter.format(obj.Cols04),
              Cols05Name: formatter.format(obj.Cols05),
              Cols09Name: formatter.format(obj.Cols09),
              Cols06Name: obj.Cols06.substring(6, 8) + "-" + obj.Cols06.substring(4, 6) + "-" + obj.Cols06.substring(0, 4),
              Cols07Name: (item2 ? item2.name : constS.BLANK)
            }, obj));
          }

        });

        const sortData = [].concat(result)
          .sort((a, b) => Number(a.Cols01) > Number(b.Cols01) ? 1 : -1)
        setDataList(sortData);

        const curpage = Number(currentPage);
        const last = Number(curpage) * 10 > sortData.length ? sortData.length : Number(curpage) * 10;
        const first = Number(curpage - 1) * 10;
        const data = [].concat(sortData).splice(first, last - first);
        setDataList(sortData);
        setCurrentData(data);
      };

      if (tbName === constS.BillDetailTBN) {
        snapshot.forEach((childSnapshot) => {
          const obj = childSnapshot.exportVal();
          const item1 = dataProductNameCombo.filter((p) => String(p.code).includes(obj.Cols02))[0];
          result.push(merge({
            id: childSnapshot.key,
            Cols03Name: formatter.format(obj.Cols03),
            Cols05Name: formatter.format(obj.Cols05),
            Cols02Name: (item1 ? item1.name : constS.BLANK)
          }, obj));
        });

        const sortData = [].concat(result)
          .sort((a, b) => Number(a.Cols02) > Number(b.Cols02) ? 1 : -1)
        const dataSub = sortData.filter(p => (valDefaultKey === constS.BLANK ? false : String(p.Cols01).includes(valDefaultKey)));
        setDataSubList(dataSub);
      }
      if (tbName === constS.AccountListTBL) {
        snapshot.forEach((childSnapshot) => {
          result.push(childSnapshot.exportVal());
        });
        setListAccount(result);
      }

      if (tbName === constS.ProductListTBN) {
        snapshot.forEach((childSnapshot) => {
          result.push(merge({
            id: childSnapshot.key,
          }, childSnapshot.exportVal()));
        });
        setListProducts(result);
      }

    }, {
      onlyOnce: true
    });
    return result;
  };

  async function fetchDataCombo(tbName) {
    const dbRef = ref(db, tbName);
    const result = [];
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        result.push(childSnapshot.exportVal());
      });
      const sortData = [].concat(result)
        .sort((a, b) => Number(a.code) > Number(b.code) ? 1 : -1)

      if (tbName === constS.BillStatusTBL) {
        setDataStatusCombo(sortData);
      }
      if (tbName === constS.OperatorTBL) {
        setDataOperatorTypeCombo(sortData);
      }
      if (tbName === constS.ProductNameTBL) {
        setDataProductNameCombo(sortData);
      }
    }, {
      onlyOnce: true
    });
    return result;
  };

  async function fetchDataComboPhoneNumber(tbName) {
    const dbRef = ref(db, tbName);
    const result = [];
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        const data = childSnapshot.exportVal();
        result.push({ code: data.Cols02, name: data.Cols02 });
      });
      result.push({ code: constS.ZERO, name: constS.SPACE });
      const sortData = [].concat(result)
        .sort((a, b) => Number(a.code) > Number(b.code) ? 1 : -1);
      setDataPhoneNumberCombo(sortData);
    }, {
      onlyOnce: true
    });
    return result;
  };

  async function fetchDataComboCustomerName(tbName) {
    const dbRef = ref(db, tbName);
    const result = [];
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        const data = childSnapshot.exportVal();
        result.push({ code: data.Cols02, name: data.Cols01 });
      });
      result.push({ code: constS.ZERO, name: constS.SPACE });
      const sortData = [].concat(result)
        .sort((a, b) => Number(a.code) > Number(b.code) ? 1 : -1);
      setDataCustomNameCombo(sortData);
    }, {
      onlyOnce: true
    });
    return result;
  };

  useEffect(() => {
    if (valDefault02 && valDefault02.code != constS.ZERO) {
      const selectData = dataPhoneNumberCombo.filter(ele => String(ele.code).startsWith(valDefault02.code));
      if (selectData && selectData.length > 0) {
        setValDefault01(selectData[0]);
      }
    } else {
      setValDefault01(defaultCombo);
    }
  }, [valDefault02]);

  useEffect(() => {
    if (valDefault01 && valDefault01.code != constS.ZERO) {
      const selectData = dataCustomNameCombo.filter(ele => String(ele.code).startsWith(valDefault01.code));
      if (selectData && selectData.length > 0) {
        setValDefault02(selectData[0]);
      }
    } else {
      setValDefault02(defaultCombo);
    }
  }, [valDefault01]);

  useEffect(() => {
    if (valDefaultKey && valDefaultKey !== constS.BLANK) {
      fetchDataInit(constS.BillDetailTBN);
    }
  }, [valDefaultKey]);

  useEffect(() => {
    if (dataProductNameCombo && dataProductNameCombo.length > 0 &&
      dataStatusCombo && dataStatusCombo.length > 0 &&
      dataOperatorTypeCombo && dataOperatorTypeCombo.length > 0 &&
      dataPhoneNumberCombo && dataPhoneNumberCombo.length > 0 &&
      dataCustomNameCombo && dataCustomNameCombo.length > 0) {
      fetchDataInit(constS.BillListTBN);
    }
  }, [dataProductNameCombo, dataStatusCombo, dataOperatorTypeCombo, dataPhoneNumberCombo, dataCustomNameCombo]);

  useEffect(() => {
    fetchDataInit(constS.AccountListTBL);
    fetchDataInit(constS.ProductListTBN);
  }, []);

  useEffect(() => {
    CheckDataLogin();
    fetchDataCombo(constS.ProductNameTBL);
    fetchDataCombo(constS.BillStatusTBL);
    fetchDataCombo(constS.OperatorTBL);
    fetchDataComboPhoneNumber(constS.AccountListTBL);
    fetchDataComboCustomerName(constS.AccountListTBL);
  }, [listAccount]);

  const getFormData = (mode) => {
    let order = 0;
    if (mode === constS.MODE_ADD) {
      const tel = loginUser.Cols02;
      const listBill = dataList.filter((p) =>
        String(p.Cols01).startsWith(tel) &&
        String(p.Cols06).startsWith(curDate));

      if (listBill && listBill.length > 0) {
        const sortData = [].concat(listBill)
          .sort((a, b) => Number(a.Cols08) < Number(b.Cols08) ? 1 : -1);
        order = Number(order) + Number(sortData[0].Cols08) + 1;
      };
    };

    const formData = {
      Cols01: valDefault01.code,
      Cols02: valDefault02.code,
      Cols03: valDefault03,
      Cols04: valDefault04,
      Cols05: valDefault05,
      Cols06: valDefault06,
      Cols07: valDefault07.code,
      Cols08: order,
      Cols09: valDefault08,
      Cols10: valDefault10,
      Cols96: curDate,
      Cols97: loginUser.Cols02,
      Cols98: mode === constS.MODE_ADD ? curDate : valDefaultCreateDate,
      Cols99: mode === constS.MODE_ADD ? loginUser.Cols02 : valDefaultCreateUser,
    };
    return formData;
  };

  const onHandleSearch = () => {
    if (valDefault01.code === constS.ZERO && valDefault02.code === constS.ZERO &&
      valDefault10 === constS.BLANK &&
      valDefault89.code === constS.ZERO && valDefault07.code === constS.ZERO) {
      setShowOKMessage("Hãy nhập thông tin tìm kiếm!");
      return;
    }

    fetchDataSearch(constS.BillListTBN);
  };

  const onHandleAdd = () => {
    if (valDefault10 === constS.BLANK && valDefault01.code === constS.ZERO &&
      valDefault02.code === constS.ZERO) {
      setShowOKMessage("Vui lòng nhập tên hóa đơn hoặc chọn khách hàng!");
      return;
    }

    if (valDefault10 === constS.BLANK) {
      if (valDefault01.code === constS.ZERO) {
        setShowOKMessage("Vui lòng chọn số điện thoại!");
        return;
      }

      if (valDefault02.code === constS.ZERO) {
        setShowOKMessage("Vui lòng chọn tên khách hàng!");
        return;
      }
    }

    if (valDefault07.code === constS.ZERO) {
      setShowOKMessage("Vui lòng chọn trạng thái hóa đơn!");
      return;
    }

    if (valDefault07.code === constS.Code9 || valDefault07.code === constS.Code4) {
      setShowOKMessage("Hóa đơn hoàn thành hoặc hủy không thể tiếp tục đăng kí!");
      return;
    }

    const cusTypes = ref(db, constS.BillListTBN);
    const newCusTypes = push(cusTypes);
    set(newCusTypes, getFormData(constS.MODE_ADD));
    fetchDataInit(constS.BillListTBN);
    setShowYesMessage("Đăng kí hóa đơn thành công!");
  };

  const onHandleUpdate = () => {
    if (valDefaultKey === constS.BLANK) {
      setShowOKMessage("Vui lòng chọn hóa đơn!");
      return;
    }

    if (valDefault10 === constS.BLANK && valDefault01.code === constS.ZERO &&
      valDefault02.code === constS.ZERO) {
      setShowOKMessage("Vui lòng nhập tên hóa đơn hoặc chọn khách hàng!");
      return;
    }

    if (valDefault10 === constS.BLANK) {
      if (valDefault01.code === constS.ZERO) {
        setShowOKMessage("Vui lòng chọn số điện thoại!");
        return;
      }

      if (valDefault02.code === constS.ZERO) {
        setShowOKMessage("Vui lòng chọn tên khách hàng!");
        return;
      }
    }

    if (valDefault07.code === constS.ZERO) {
      setShowOKMessage("Vui lòng chọn trạng thái hóa đơn!");
      return;
    }

    if (dataSelected && (dataSelected.Cols07 === constS.Code9 || dataSelected.Cols07 === constS.Code4)) {
      setShowOKMessage("Hóa đơn đã hoàn thành hoặc hủy không được phép chỉnh sửa?");
      return;
    }

    let total = 0;
    let payment = 0;
    if (dataSubList.length > 0) {
      dataSubList.forEach((ele) => {
        total = Number(total) + Number(ele.Cols05)
      });
    }

    payment = Number(total) - Number(valDefault04) + Number(valDefault08);
    setValDefault03(total);
    setValDefault05(payment);
    const formData = {
      Cols01: valDefault01.code,
      Cols02: valDefault02.code,
      Cols03: total,
      Cols04: valDefault04,
      Cols05: payment,
      Cols06: valDefault06,
      Cols07: valDefault07.code,
      Cols09: valDefault08,
      Cols10: valDefault10,
      Cols96: curDate,
      Cols97: loginUser.Cols02,
      Cols98: valDefaultCreateDate,
      Cols99: valDefaultCreateUser,
    };
    update(ref(db, `/` + constS.BillListTBN + `/${valDefaultKey}/`), formData);

    if (valDefault07.code === constS.Code9) {
      const cusTypes = ref(db, constS.MonneyListTBL);
      const newCusTypes = push(cusTypes);
      const formData = {
        Cols01: valDefault05,
        Cols02: "01",
        Cols96: curDate,
        Cols97: loginUser.Cols02,
        Cols98: curDate,
        Cols99: loginUser.Cols02,
      };
      set(newCusTypes, formData);
    }

    if (valDefault07.code === constS.Code4 && dataSubList.length > 0) {
      dataSubList.forEach((item) => {
        const list = listProducts.filter(ele => String(ele.Cols01).startsWith(item.Cols02));
        if (list && list.length > 0) {
          let sum = Number(list[0].Cols02) + Number(item.Cols04);
          update(ref(db, `/` + constS.ProductListTBN + `/${list[0].id}/`),
            {
              Cols02: sum,
              Cols96: loginUser.Cols02,
              Cols97: curDate
            });
        }
      });
    }

    fetchDataInit(constS.BillListTBN);
    setShowYesMessage("Chỉnh sửa tính toán hóa đơn thành công!");
  };

  const onHandleUpdateDetail = () => {
    if (valDefaultKey === constS.BLANK) {
      setShowOKMessage("Vui lòng chọn hóa đơn cần chỉnh sửa chi tiết!");
      return;
    }

    if (dataSelected && (dataSelected.Cols07 === constS.Code9 || dataSelected.Cols07 === constS.Code4)) {
      setShowOKMessage("Hóa đơn đã hoàn thành hoặc hủy không được phép chỉnh sửa");
      return;
    }

    navigate('/billdetail', { replace: true, state: valDefaultKey });
  };

  const onHandleExportBill = () => {
    if (valDefaultKey === constS.BLANK) {
      setShowOKMessage("Vui lòng chọn hóa đơn cần xuất!");
      return;
    }

    setShowModalBill(true);
  };

  const onHandleClear = () => {
    setValDefaultKey(constS.BLANK);
    setValDefault01(defaultCombo);
    setValDefault02(defaultCombo);
    setValDefault03(constS.ZERO);
    setValDefault04(constS.ZERO);
    setValDefault05(constS.ZERO);
    setValDefault06(curDate);
    setValDefault07(defaultCombo1);
    setValDefault08(constS.ZERO);
    setValDefault10(constS.BLANK);

    setValDefault89(defaultCombo);
    setDataSubList([]);
    fetchDataInit(constS.BillListTBN);
    setDataSelected([]);
  };

  const refColsKey = (e) => {
    setValDefaultKey(e.target.value);
  };

  const refCols10 = (e) => {
    setValDefault10(e.target.value);
  };

  const refCols01 = (event, value) => {
    if (value !== null) { setValDefault01(value); }
    else {
      setValDefault01(defaultCombo);
    }
  };

  const refCols02 = (event, value) => {
    if (value !== null) { setValDefault02(value); }
    else {
      setValDefault02(defaultCombo);
    }
  };

  const refCols03 = (e) => {
    setValDefault03(e.target.value);
  };

  const refCols04 = (e) => {
    setValDefault04(e.target.value);
    if (e.target.value !== constS.BLANK && Number(e.target.value) !== Number(constS.ZERO)) {
      setValDefault05(Number(valDefault03) - Number(e.target.value));
    }
    else {
      setValDefault05(Number(valDefault03));
    }
  };

  const refCols08 = (e) => {
    setValDefault08(e.target.value);
    if (e.target.value !== constS.BLANK && Number(e.target.value) !== Number(constS.ZERO)) {
      setValDefault05(Number(valDefault03) - Number(valDefault04) + Number(e.target.value));
    }
    else {
      setValDefault05(Number(valDefault03) - Number(valDefault04));
    }
  };

  const refCols05 = (e) => {
    setValDefault05(e.target.value);
  };

  const refCols06 = (e) => {
    const refDate = e.$y
      + (e.$M + 1).toString().padStart(2, "0")
      + e.$D.toString().padStart(2, "0");
    setValDefault06(refDate);
  };


  const refCols07 = (event, value) => {
    if (value !== null) { setValDefault07(value); }
    else {
      setValDefault07(defaultCombo);
    }
  };

  const refCols89 = (event, value) => {
    if (value !== null) { setValDefault89(value); }
    else {
      setValDefault89(defaultCombo);
    }
  };

  const refCols70 = () => {
    setShowModalBill(false);
  };

  const refOK = () => {
    setShowOKMessage(constS.BLANK);
    setShowYesMessage(constS.BLANK);
  };

  const onClickSelected = (val) => {
    setDataSelected(val);
    setValDefaultKey(val.id);
    if (val.Cols01 === constS.ZERO) {
      setValDefault01(defaultCombo);
    }
    else {
      dataPhoneNumberCombo.forEach((ele) => {
        if (ele.code === val.Cols01) { setValDefault01(ele); };
      });
    };
    if (val.Cols02 === constS.ZERO) {
      setValDefault02(defaultCombo);
    }
    else {
      dataCustomNameCombo.forEach((ele) => {
        if (ele.code === val.Cols02) { setValDefault02(ele); };
      });
    };
    setValDefault03(val.Cols03);
    setValDefault04(val.Cols04);
    setValDefault05(val.Cols05);
    setValDefault06(val.Cols06);
    if (val.Cols07 === constS.ZERO) {
      setValDefault07(defaultCombo);
    }
    else {
      dataStatusCombo.forEach((ele) => {
        if (ele.code === val.Cols07) { setValDefault07(ele); };
      });
    };
    setValDefault08(val.Cols09);

    setValDefault10(val.Cols10 ? val.Cols10 : constS.BLANK);

    setValDefaultCreateDate(val.Cols98);
    setValDefaultCreateUser(val.Cols99);
  };

  const onClickPrev = () => {
    if (currentPage === 1) {
      return;
    }
    const curpage = currentPage - 1;
    const last = Number(curpage) * 10;
    const first = Number(last) - 10;
    const data = [].concat(dataList).splice(first, last - first);
    setCurrentData(data);
    setCurrentPage(curpage);
  };

  const onClickNext = () => {
    if (Number(currentPage) * 10 > dataList.length) {
      return;
    }
    const curpage = Number(currentPage) + 1;
    const last = Number(curpage) * 10 > dataList.length ? dataList.length : Number(curpage) * 10;
    const first = Number(curpage - 1) * 10;
    const data = [].concat(dataList).splice(first, last - first);
    setCurrentData(data);
    setCurrentPage(curpage);
  };

  const onClickBillDetial = () => {

  }

  return [
    <Page title={constM.listBills}>
      <ExMessageYes message={showYesMessage} refOK={refOK}></ExMessageYes>
      <ExMessageOK message={showOKMessage} refOK={refOK}></ExMessageOK>

      <ExModalBill isShow={showModalBill} inputRef={refCols70}
        listBillDetail={dataSubList} dataProductNameCombo={dataProductNameCombo} valDefault02={valDefault02} valDefault10={valDefault10}
        valDefault03={valDefault03} valDefault04={valDefault04} valDefault08={valDefault08} valDefault05={valDefault05} valDefaultKey={valDefaultKey}></ExModalBill>

      <Container maxWidth="xl">
        <Typography variant={"h1"}>{constM.listMainternance + " " + constM.listBills}</Typography>
        <Paper variant="outlined">
          <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 2, fontSize: 40 }}>
            <Grid item md={4}>{"Mã hóa đơn"}</Grid>
            <Grid item md={7.5}>
              <ExTextField
                id={constS.id} inputRef={refColsKey} valDefault={valDefaultKey}
                placeholder={constM.placeKey} Icon={<QrCodeOutlinedIcon />} maxLength={50} disabled={true}></ExTextField>
            </Grid>
            <Grid item md={4}>{"Tên hóa đơn"}</Grid>
            <Grid item md={7.5}>
              <ExTextField
                id={constS.Cols10} inputRef={refCols10} valDefault={valDefault10}
                placeholder={"Tên hóa đơn"} Icon={<DriveFileRenameOutlineIcon />} maxLength={500}></ExTextField>
            </Grid>
            <Grid item md={4}>{"Điện thoại"}</Grid>
            <Grid item md={7.5}>
              <ExComboBox
                id={constS.Cols01} comboRef={refCols01} valDefault={valDefault01}
                placeholder={constM.placeCustomerPhone} DataSource={dataPhoneNumberCombo}></ExComboBox>
            </Grid>
            <Grid item md={4}>{"Tên "}
            </Grid>
            <Grid item md={7.5}>
              <ExComboBox
                id={constS.Cols02} comboRef={refCols02} valDefault={valDefault02}
                placeholder={constM.placeName} DataSource={dataCustomNameCombo}></ExComboBox>
            </Grid>

            <Grid item md={4}>{constM.placeTotalPrice}
            </Grid>
            <Grid item md={7.5}>
              <ExNumber
                id={constS.Cols03} inputRef={refCols03} valDefault={valDefault03}
                placeholder={constM.placeTotalPrice} disabled Icon={<AddCardOutlinedIcon />} maxLength={100}></ExNumber>
            </Grid>
            <Grid item md={4}>{constM.placeDiscount}
            </Grid>
            <Grid item md={7.5}>
              <ExNumber
                id={constS.Cols04} inputRef={refCols04} valDefault={valDefault04}
                placeholder={constM.placeDiscount} Icon={<AttachMoneyOutlinedIcon />} maxLength={100}></ExNumber>
            </Grid>
            <Grid item md={4}>{constM.placePayment}
            </Grid>
            <Grid item md={7.5}>
              <ExNumber
                id={constS.Cols05} inputRef={refCols05} valDefault={valDefault05}
                placeholder={constM.placePayment} disabled Icon={<LocalAtmOutlinedIcon />} maxLength={100}></ExNumber>
            </Grid>

            <Grid item md={4}>{constM.placeCreateDate}
            </Grid>
            <Grid item md={4}>
              <ExDate
                id={constS.Cols06} inputRef={refCols06} valDefault={valDefault06}
                placeholder={constM.placeCreateDate}></ExDate>
            </Grid>
            <Grid item md={3.5}>
              <ExComboBox
                id={constS.Cols89} comboRef={refCols89} valDefault={valDefault89}
                placeholder={constM.placeOperatorType} DataSource={dataOperatorTypeCombo}></ExComboBox>
            </Grid>
            <Grid item md={4}>{"Trạng thái"}
            </Grid>
            <Grid item md={7.5}>
              <ExComboBox
                id={constS.Cols07} comboRef={refCols07} valDefault={valDefault07}
                placeholder={constM.placeStatus} DataSource={dataStatusCombo}></ExComboBox>
            </Grid>

            <Grid item md={4}>{"Phí Ship"}
            </Grid>
            <Grid item md={7.5}>
              <ExNumber
                id={constS.Cols08} inputRef={refCols08} valDefault={valDefault08}
                placeholder={"Phí ship"} Icon={<AttachMoneyOutlinedIcon />} maxLength={100}></ExNumber>
            </Grid>
            <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 2 }}>
              {dataSubList.map((item) => (
                <Grid item md={12}>
                  <ExCardBillDetail DataSource={item} onClick={onClickBillDetial}></ExCardBillDetail>
                </Grid>
              ))}
            </Grid>

            <Grid item>
              <ExButtonIconBef title={"Tìm kiếm"} icon='\static\illustrations\search.png' onClick={onHandleSearch} />
            </Grid>
            <Grid item>
              <ExButtonIconBef title={"Đăng kí"} icon='\static\illustrations\add.png' onClick={onHandleAdd} />
            </Grid>
            <Grid item>
              <ExButtonIconBef title={"Đăng kí chi tiết"} icon='\static\illustrations\add.png' onClick={onHandleUpdateDetail} />
            </Grid>

            <Grid item>
              <ExButtonIconBef title={"Chỉnh sửa tính toán"} icon='\static\illustrations\edit.png' onClick={onHandleUpdate} />
            </Grid>
            <Grid item>
              <ExButtonIconBef title={"Xuất hóa đơn"} icon='\static\illustrations\export.png' onClick={onHandleExportBill} />
            </Grid>
            <Grid item>
              <ExButtonIconBef title={"Xóa nhập"} icon='\static\illustrations\delete.png' onClick={onHandleClear} />
            </Grid>
          </Grid>
        </Paper>

        <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 2 }}>
          {currentData.map((item) => (
            <Grid item md={12}>
              <ExCardBill DataSource={item} onClick={onClickSelected} selectedId={valDefaultKey}></ExCardBill>
            </Grid>
          ))}

          <Grid item md={6} sx={{ textAlign: "left" }}>
            <ExButtonIconBef title={"Trang trước"} icon='\static\illustrations\prev.png' onClick={onClickPrev} />
          </Grid>
          <Grid item md={6} sx={{ textAlign: "right" }}>
            <ExButtonIconAft title={"Trang sau"} icon='\static\illustrations\next.png' onClick={onClickNext} />
          </Grid>
        </Grid>
      </Container >
    </Page >
  ];
}
