import * as React from 'react';
import Card from '@mui/material/Card';
import { Grid } from "@mui/material";


export default function ExCardProduct({ DataSource, onClick, selectedId }) {
  return (
    <Card key={DataSource.id} onClick={() => onClick(DataSource)} sx={{
      height: 260, p: 0, m: 0,
      backgroundColor: selectedId === DataSource.id ? "greenyellow" : "white"
    }}>
      <Grid container rowSpacing={0} spacing={0} sx={{ m: 0, p: 0, fontSize: 40 }}>
        <Grid item md={8}>{"Mã : " + DataSource.id}</Grid>
        <Grid item md={4}>{"Số lượng : " + DataSource.Cols02}</Grid>
        <Grid item md={12}>{"Tên : " + DataSource.Cols01Name}</Grid>
        <Grid item md={4}>{"Giá nhập"}</Grid>
        <Grid item md={4}>{"Giá bán lẻ"}</Grid>
        <Grid item md={4}>{"Giá bán sỉ"}</Grid>
        <Grid item md={4}>{DataSource.Cols04Name}</Grid>
        <Grid item md={4}>{DataSource.Cols05Name}</Grid>
        <Grid item md={4}>{DataSource.Cols06Name}</Grid>
      </Grid>
    </Card >
  );
};