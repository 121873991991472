import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button, Grid } from "@mui/material";
import constS from '../const/constS';

export default function ExOrderCard({ isMobile, DataSource, cardName, onOrderCardDelete, onOrderCardMinus, onOrderCardPlus }) {
  const formatter = new Intl.NumberFormat('vi', { style: 'currency', currency: 'VND' });
  let images = [];
  let defaultImage = "";
  if (DataSource.Cols03 && DataSource.Cols03.length > 0) {
    images = DataSource.Cols03.split(constS.END);
    if (images && images.length > 0) {
      defaultImage = images[0];
    }
    else {
      defaultImage = DataSource.Cols03;
    }
  }

  return (
    <Card sx={{ padding: 0, margin: 0, boxShadow: 0, borderBottom: 1, borderRadius: 0 }}>
      <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 0 }}>
        <Grid item md={isMobile ? 2 : 1}>
          <CardMedia
            component="img"
            height={isMobile ? 120 : 80}
            image={defaultImage}
            alt="may shop"
          />
        </Grid>
        {isMobile && <Grid item md={10}>
          <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 0 }}>
            <Grid item md={7} sx={{ fontSize: constS.fontSizeM }}>{cardName}</Grid>
            <Grid item md={5} sx={{ fontSize: constS.fontSizeM }}>
              {formatter.format(DataSource.Cols02)}
            </Grid>
            <Grid item md={1}>
              <Button sx={{ backgroundColor: 'limegreen' }} onClick={event => onOrderCardMinus(DataSource.id)}>
                <Typography variant="button" fontSize={constS.fontSizeM} color={"text.secondary"}>{"-"}</Typography></Button>
            </Grid>
            <Grid item md={2} sx={{ fontSize: constS.fontSizeM, textAlign: "center" }}>{DataSource.Cols04}</Grid>
            <Grid item md={4}>
              <Button sx={{ backgroundColor: 'limegreen' }}
                onClick={event => onOrderCardPlus(DataSource.id, DataSource.Cols01, DataSource.Cols04)}>
                <Typography variant="button" fontSize={constS.fontSizeM} color={"text.secondary"}>{"+"}</Typography></Button>
            </Grid>
            <Grid item md={3} sx={{ fontSize: constS.fontSizeM }}>{formatter.format(DataSource.Cols05)}</Grid>
            <Grid item md={1}>
              <CardContent sx={{ p: 0, pl: 1 }}><Button sx={{ backgroundColor: 'limegreen' }}
                onClick={() => onOrderCardDelete(DataSource.id)}><Typography variant="button" fontSize={constS.fontSizeM} color={"red"}>{"Xóa"}</Typography></Button></CardContent>
            </Grid>
          </Grid>
        </Grid>}
        {!isMobile && <Grid item md={10}>
          <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 0, fontSize: constS.fontSize }}>
            <Grid item md={4.6} >{cardName}</Grid>
            <Grid item md={1.7} >
              {formatter.format(DataSource.Cols02)}
            </Grid>
            <Grid item md={1}>
              <Button sx={{ backgroundColor: 'limegreen' }} onClick={event => onOrderCardMinus(DataSource.id)}>
                <Typography variant="button" fontSize={constS.fontSize} color={"text.secondary"}>{"-"}</Typography></Button>
            </Grid>
            <Grid item md={0.5}>{DataSource.Cols04}</Grid>
            <Grid item md={1.7}>
              <Button sx={{ backgroundColor: 'limegreen' }}
                onClick={event => onOrderCardPlus(DataSource.id, DataSource.Cols01, DataSource.Cols04)}>
                <Typography variant="button" fontSize={constS.fontSize} color={"text.secondary"}>{"+"}</Typography></Button>
            </Grid>
            <Grid item md={1.5} >{formatter.format(DataSource.Cols05)}</Grid>
            <Grid item md={1}>
              <CardContent sx={{ p: 0, pl: 1 }}><Button sx={{ backgroundColor: 'limegreen' }}
                onClick={() => onOrderCardDelete(DataSource.id)}><Typography variant="button"
                  fontSize={constS.fontSize} color={"red"}>{"Xóa"}</Typography></Button></CardContent>
            </Grid>
          </Grid>
        </Grid>}
      </Grid>
    </Card >
  );
};