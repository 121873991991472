import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, push, set, onValue, update } from "firebase/database";
import firebaseDB from '../initfirebase'
import { Container, Typography, Box, Paper, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { merge } from 'lodash';
import Page from "../components/Page";
import constS from '../const/constS';
import { useNavigate } from 'react-router-dom';
import ExMessageYes from "../components/ExMessageYes";
import ExMessageOK from "../components/ExMessageOK";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ExOrderCardLast from "../components/ExOrderCardLast";
// ----------------------------------------------------------------------
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function OrderBillCard() {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  const formatter = new Intl.NumberFormat('vi', { style: 'currency', currency: 'VND' });
  const date = new Date();
  const curDate = date.getFullYear().toString()
    + (date.getMonth() + 1).toString().padStart(2, "0")
    + date.getDate().toString().padStart(2, "0");
  const navigate = useNavigate();
  const [showYesMessage, setShowYesMessage] = useState(constS.BLANK);
  const [showOKMessage, setShowOKMessage] = useState(constS.BLANK);

  const [dataList01, setDataList01] = useState(constS.BLANK);
  const [dataList02, setDataList02] = useState(constS.BLANK);
  const [dataList04, setDataList04] = useState(constS.BLANK);
  const [dataList09, setDataList09] = useState(constS.BLANK);

  const [dataListDetail01, setDataListDetail01] = useState([]);
  const [dataListDetail02, setDataListDetail02] = useState([]);
  const [dataListDetail04, setDataListDetail04] = useState([]);
  const [dataListDetail09, setDataListDetail09] = useState([]);
  const [dataNameCombo, setDataNameCombo] = useState([]);

  const [loginUser, setLoginUser] = useState(null);
  const [listAccount, setListAccount] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const db = getDatabase(firebaseDB);

  async function CheckDataLogin() {
    const auth = getAuth(firebaseDB);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        listAccount.forEach((row) => {
          if (row.Cols02 === user.email.substring(0, user.email.length - 10)) {
            setLoginUser(row);
            return;
          }
        });
      }

      if (!user) { navigate('/', { replace: false }); }
    });
  };

  async function fetchDataInit(tbName) {
    const dbRef = ref(db, tbName);
    const result = [];
    onValue(dbRef, (snapshot) => {
      if (tbName === constS.BillListTBN) {
        snapshot.forEach((childSnapshot) => {
          const obj = childSnapshot.exportVal();
          if (obj.Cols01 === loginUser.Cols02) {
            result.push(merge({ id: childSnapshot.key, }, obj));
          }
        });

        const sortData = [].concat(result)
          .sort((a, b) => Number(a.Cols01) > Number(b.Cols01) ? 1 : -1)
        let data = sortData.filter(p => (p.Cols07 === "1"));
        let res = "";
        data.forEach((ele) => {
          res = res + ele.id + ", ";
        });
        setDataList01(res);

        data = sortData.filter(p => (p.Cols07 === "2"));
        res = "";
        data.forEach((ele) => {
          res = res + ele.id + ", ";
        });
        setDataList02(res);

        data = sortData.filter(p => (p.Cols07 === "4"));
        res = "";
        data.forEach((ele) => {
          res = res + ele.id + ", ";
        });
        setDataList04(res);

        data = sortData.filter(p => (p.Cols07 === "9"));
        res = "";
        data.forEach((ele) => {
          res = res + ele.id + ", ";
        });
        setDataList09(res);
      };

      if (tbName === constS.AccountListTBL) {
        snapshot.forEach((childSnapshot) => {
          const obj = childSnapshot.exportVal();
          result.push(merge({ id: childSnapshot.key, }, obj));
        });

        const sortData = [].concat(result)
          .sort((a, b) => Number(a.Cols02) > Number(b.Cols02) ? 1 : -1);
        setListAccount(sortData);
      };

    }, {
      onlyOnce: true
    });
    return result;
  };

  async function fetchDataInitDetail(tbName, mode) {
    const dbRef = ref(db, tbName);
    const result = [];
    onValue(dbRef, (snapshot) => {
      if (mode === constS.Code1) {
        snapshot.forEach((childSnapshot) => {
          const obj = childSnapshot.exportVal();
          if (String(dataList01).includes(obj.Cols01)) {
            result.push(merge({ id: childSnapshot.key, }, obj));
          }
        });

        const sortData = [].concat(result)
          .sort((a, b) => Number(a.Cols02) > Number(b.Cols02) ? 1 : -1)
        setDataListDetail01(sortData);
      };

      if (mode === constS.Code2) {
        snapshot.forEach((childSnapshot) => {
          const obj = childSnapshot.exportVal();
          if (String(dataList02).includes(obj.Cols01)) {
            result.push(merge({ id: childSnapshot.key, }, obj));
          }
        });

        const sortData = [].concat(result)
          .sort((a, b) => Number(a.Cols02) > Number(b.Cols02) ? 1 : -1)
        setDataListDetail02(sortData);
      };

      if (mode === constS.Code4) {
        snapshot.forEach((childSnapshot) => {
          const obj = childSnapshot.exportVal();
          if (String(dataList04).includes(obj.Cols01)) {
            result.push(merge({ id: childSnapshot.key, }, obj));
          }
        });

        const sortData = [].concat(result)
          .sort((a, b) => Number(a.Cols02) > Number(b.Cols02) ? 1 : -1)
        setDataListDetail04(sortData);
      };

      if (mode === constS.Code9) {
        snapshot.forEach((childSnapshot) => {
          const obj = childSnapshot.exportVal();
          if (String(dataList09).includes(obj.Cols01)) {
            result.push(merge({ id: childSnapshot.key, }, obj));
          }
        });

        const sortData = [].concat(result)
          .sort((a, b) => Number(a.Cols02) > Number(b.Cols02) ? 1 : -1)
        setDataListDetail09(sortData);
      };

    }, {
      onlyOnce: true
    });
    return result;
  };

  async function fetchDataCombo(tbName) {
    const dbRef = ref(db, tbName);
    const result = [];
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        result.push(childSnapshot.exportVal());
      });
      const sortData = [].concat(result)
        .sort((a, b) => Number(a.code) > Number(b.code) ? 1 : -1);

      if (tbName === constS.ProductNameTBL) {
        setDataNameCombo(sortData);
      }

    }, {
      onlyOnce: true
    });
    return result;
  };

  useEffect(() => {
    fetchDataInit(constS.AccountListTBL);
    fetchDataCombo(constS.ProductNameTBL);
  }, []);

  useEffect(() => {
    if (listAccount && listAccount.length > 0) {
      CheckDataLogin();
    }

  }, [listAccount]);

  useEffect(() => {
    if (loginUser) {
      fetchDataInit(constS.BillListTBN);
    }
  }, [loginUser]);

  useEffect(() => {
    if (dataList01 && dataList01.length > 0) {
      fetchDataInitDetail(constS.BillDetailTBN, constS.Code1);
    }
  }, [dataList01]);

  useEffect(() => {
    if (dataList02 && dataList02.length > 0) {
      fetchDataInitDetail(constS.BillDetailTBN, constS.Code2);
    }
  }, [dataList02]);

  useEffect(() => {
    if (dataList04 && dataList04.length > 0) {
      fetchDataInitDetail(constS.BillDetailTBN, constS.Code4);
    }
  }, [dataList04]);

  useEffect(() => {
    if (dataList09 && dataList09.length > 0) {
      fetchDataInitDetail(constS.BillDetailTBN, constS.Code9);
    }
  }, [dataList09]);

  const onChangeTab = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const refOK = () => {
    setShowOKMessage(constS.BLANK);
    setShowYesMessage(constS.BLANK);
  };

  const onGetProductName = (val) => {
    const res = dataNameCombo.filter(p => String(p.code).startsWith(val));
    if (res && res.length <= 0) {
      return constS.BLANK;
    }
    return res[0].name;
  };


  return [
    <Page title={"Thông tin đặt hàng"}>
      <ExMessageYes message={showYesMessage} refOK={refOK}></ExMessageYes>
      <ExMessageOK message={showOKMessage} refOK={refOK}></ExMessageOK>
      <Container maxWidth="xl">
        <Typography variant={isMobile ? "h2" : "h6"}>{"Thông tin đặt hàng"}</Typography>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabIndex} onChange={onChangeTab} aria-label="basic tabs example">
              <Tab sx={{ fontSize: isMobile ? constS.fontSizeM : constS.fontSize }} label="Đang xử lý" {...a11yProps(0)} />
              <Tab sx={{ fontSize: isMobile ? constS.fontSizeM : constS.fontSize }} label="Đang giao" {...a11yProps(1)} />
              <Tab sx={{ fontSize: isMobile ? constS.fontSizeM : constS.fontSize }} label="Đã hủy" {...a11yProps(2)} />
              <Tab sx={{ fontSize: isMobile ? constS.fontSizeM : constS.fontSize }} label="Đã hoàn thành" {...a11yProps(3)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={tabIndex} index={0}>
            {isMobile && <Container maxWidth="xl">
              <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 2, fontSize: constS.fontSizeM }}>
                <Grid item md={8}>{"Thông tin sản phẩm"}
                </Grid>
                <Grid item md={4}>{"Đơn giá"}
                </Grid>
                <Grid item md={6} sx={{ textAlign: "center" }}>{"Số lượng"}
                </Grid>
                <Grid item md={4}>{"Thành tiền"}
                </Grid>
                {dataListDetail01.map((item) => (
                  <Grid item md={12}>
                    <ExOrderCardLast isMobile={isMobile} DataSource={item} cardName={onGetProductName(item.Cols02)}></ExOrderCardLast>
                  </Grid>
                ))}

              </Grid>
            </Container>}

            {!isMobile && <Container maxWidth="xl">
              <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 2, fontSize: constS.fontSize }}>
                <Grid item md={5} >{"Thông tin sản phẩm"}
                </Grid>
                <Grid item md={1.5} >{"Đơn giá"}
                </Grid>
                <Grid item md={1.5} >{"Số lượng"}
                </Grid>
                <Grid item md={1.5} >{"Thành tiền"}
                </Grid>
                {dataListDetail01.map((item) => (
                  <Grid item md={12}>
                    <ExOrderCardLast DataSource={item} cardName={onGetProductName(item.Cols02)}></ExOrderCardLast>
                  </Grid>
                ))}

              </Grid>
            </Container>}
          </CustomTabPanel>
          <CustomTabPanel value={tabIndex} index={1}>
            {isMobile && <Container maxWidth="xl">
              <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 2, fontSize: constS.fontSizeM }}>
                <Grid item md={8}>{"Thông tin sản phẩm"}
                </Grid>
                <Grid item md={4}>{"Đơn giá"}
                </Grid>
                <Grid item md={6} sx={{ textAlign: "center" }}>{"Số lượng"}
                </Grid>
                <Grid item md={4}>{"Thành tiền"}
                </Grid>
                {dataListDetail02.map((item) => (
                  <Grid item md={12}>
                    <ExOrderCardLast isMobile={isMobile} DataSource={item} cardName={onGetProductName(item.Cols02)}></ExOrderCardLast>
                  </Grid>
                ))}

              </Grid>
            </Container>}

            {!isMobile && <Container maxWidth="xl">
              <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 2, fontSize: constS.fontSize }}>
                <Grid item md={5} >{"Thông tin sản phẩm"}
                </Grid>
                <Grid item md={1.5} >{"Đơn giá"}
                </Grid>
                <Grid item md={1.5} >{"Số lượng"}
                </Grid>
                <Grid item md={1.5} >{"Thành tiền"}
                </Grid>
                {dataListDetail02.map((item) => (
                  <Grid item md={12}>
                    <ExOrderCardLast DataSource={item} cardName={onGetProductName(item.Cols02)}></ExOrderCardLast>
                  </Grid>
                ))}

              </Grid>
            </Container>}
          </CustomTabPanel>
          <CustomTabPanel value={tabIndex} index={2}>
            {isMobile && <Container maxWidth="xl">
              <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 2, fontSize: constS.fontSizeM }}>
                <Grid item md={8}>{"Thông tin sản phẩm"}
                </Grid>
                <Grid item md={4}>{"Đơn giá"}
                </Grid>
                <Grid item md={6} sx={{ textAlign: "center" }}>{"Số lượng"}
                </Grid>
                <Grid item md={4}>{"Thành tiền"}
                </Grid>
                {dataListDetail04.map((item) => (
                  <Grid item md={12}>
                    <ExOrderCardLast isMobile={isMobile} DataSource={item} cardName={onGetProductName(item.Cols02)}></ExOrderCardLast>
                  </Grid>
                ))}

              </Grid>
            </Container>}

            {!isMobile && <Container maxWidth="xl">
              <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 2, fontSize: constS.fontSize }}>
                <Grid item md={5} >{"Thông tin sản phẩm"}
                </Grid>
                <Grid item md={1.5} >{"Đơn giá"}
                </Grid>
                <Grid item md={1.5} >{"Số lượng"}
                </Grid>
                <Grid item md={1.5} >{"Thành tiền"}
                </Grid>
                {dataListDetail04.map((item) => (
                  <Grid item md={12}>
                    <ExOrderCardLast DataSource={item} cardName={onGetProductName(item.Cols02)}></ExOrderCardLast>
                  </Grid>
                ))}

              </Grid>
            </Container>}
          </CustomTabPanel>
          <CustomTabPanel value={tabIndex} index={3}>
            {isMobile && <Container maxWidth="xl">
              <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 2, fontSize: constS.fontSizeM }}>
                <Grid item md={8}>{"Thông tin sản phẩm"}
                </Grid>
                <Grid item md={4}>{"Đơn giá"}
                </Grid>
                <Grid item md={6} sx={{ textAlign: "center" }}>{"Số lượng"}
                </Grid>
                <Grid item md={4}>{"Thành tiền"}
                </Grid>
                {dataListDetail09.map((item) => (
                  <Grid item md={12}>
                    <ExOrderCardLast isMobile={isMobile} DataSource={item} cardName={onGetProductName(item.Cols02)}></ExOrderCardLast>
                  </Grid>
                ))}

              </Grid>
            </Container>}

            {!isMobile && <Container maxWidth="xl">
              <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 2, fontSize: constS.fontSize }}>
                <Grid item md={5} >{"Thông tin sản phẩm"}
                </Grid>
                <Grid item md={1.5} >{"Đơn giá"}
                </Grid>
                <Grid item md={1.5} >{"Số lượng"}
                </Grid>
                <Grid item md={1.5} >{"Thành tiền"}
                </Grid>
                {dataListDetail09.map((item) => (
                  <Grid item md={12}>
                    <ExOrderCardLast DataSource={item} cardName={onGetProductName(item.Cols02)}></ExOrderCardLast>
                  </Grid>
                ))}

              </Grid>
            </Container>}
          </CustomTabPanel>
        </Box>
      </Container >
    </Page >
  ];
}
