import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import constS from '../const/constS';
import { Fragment, useEffect, useState } from 'react';

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const defaultCombo = { code: constS.ZERO, name: "" };
export default function ExComboBox({ DataSource, valDefault, withCode, comboRef, readOnly, placeholder }) {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  const fonts = isMobile ? 44 : 32;

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState({ code: constS.ZERO, name: "" });
  const loading = open && options.length === 0;
  const [value, changeValue] = useState(valDefault)

  useEffect(() => {
    changeValue(valDefault)
  }, [valDefault]);


  const onIputChangeCus = (event, newInputValue) => {
    // changeValue(defaultCombo);
    DataSource.forEach(ele => {
      if (ele.name === newInputValue) {
        changeValue(ele);
        return;
      };
    });
  };

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(200);

      if (active) {
        setOptions([...DataSource]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);


  return (
    <Autocomplete
      id="asynchronous-demo"
      sx={{ p: 0, m: 0 }}
      size="small"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={value}
      onInputChange={onIputChangeCus}
      isOptionEqualToValue={(option, value) => option.code === value.code}
      getOptionLabel={(option) => option.code === constS.ZERO ? constS.BLANK : ((withCode ? option.code : constS.BLANK) + option.name)}
      options={options}
      loading={loading}
      onChange={comboRef}
      readOnly={readOnly || false}
      renderInput={(params) => (
        <TextField
          sx={{ m: 0, p: 0 }}
          {...params}
          label=""
          value={options}
          placeholder={placeholder}
          InputProps={{
            style: { fontSize: fonts },
            sx: { fontSize: fonts },
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
}