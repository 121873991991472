const constS = {
    SPACE: ' ',
    BLANK: '',
    COMPA: ', ',
    ENDLINE: '<br/>',
    END: '\n',
    ZERO: '0',
    ONE: '1',
    CITYHCM: '01',
    CITYHCMQ1: '0101',
    EMAILENDFIXED: '@gmail.com',
    MODE_ADD: 'ADD',
    MODE_INIT: 'INIT',
    cusTypeTableName: 'CustomerTypes',
    cusSexTableName: 'CustomerSex',
    shopStatusTableName: 'ShopStatus',
    shopJuridicalTableName: 'ShopJuridical',
    shopObjectTableName: 'ShopObject',
    shopTypeTableName: 'ShopType',
    shopWayRoadTableName: 'ShopWayRoad',
    shopTypeStreetTableName: 'ShopTypeStreet',
    shopUseAisleTableName: 'ShopUseAisle',
    shopDirectionTableName: 'shopDirection',
    shopModalTableName: 'ShopModal',
    demandStatusTableName: 'DemandStatus',
    cityObjectTableName: 'CityObject',
    districtObjectTableName: 'DistrictObject',
    wardObjectTableName: 'WardObject',
    operatorTableName: 'OperatorType',
    priceOrder: 'PriceOrder',
    areaOrder: 'AreaOrder',
    accountPermission: 'AccountPermission',
    houseStatusTableName: 'HouseStatus',

    homeListTableName: 'HomeLists',
    demandListTableName: 'DemandLists',
    accountListTableName: 'AccountLists',



    OperatorTBL: 'OperatorTBL',
    ProductNameTBL: 'ProductNameTBL',
    ProductTypeTBL: 'ProductTypeTBL',
    ProductListTBN: 'ProductListTBN',
    AccountListTBL: 'AccountListTBL',
    BillListTBN: 'BillListTBN',
    BillStatusTBL: 'BillStatusTBL',
    BillDetailTBN: 'BillDetailTBN',
    AccountPermissionTBL: 'AccountPermissionTBL',
    SexTBL: 'SexTBL',
    OrderCardTBL: 'OrderCardTBL',
    StatusActiveTBL: 'StatusActiveTBL',
    RulerTBL: 'RulerTBL',
    MonneyManagerTBL: 'MonneyManagerTBL',
    MonthManagerTBL: 'MonthManagerTBL',
    YearManagerTBL: 'YearManagerTBL',
    MonneyListTBL: 'MonneyListTBL',


    Code1: '1',
    Code2: '2',
    Code3: '3',
    Code4: '4',
    Code5: '5',
    Code6: '6',
    Code7: '7',
    Code8: '8',
    Code9: '9',
    fontSizeM: 35,
    fontSize: 20,

    id: 'id',
    code: 'code',
    name: 'name',
    image: 'image',

    Cols01: 'Cols01',
    Cols01Name: 'Cols01Name',
    Cols02: 'Cols02',
    Cols02Name: 'Cols02Name',
    Cols03: 'Cols03',
    Cols03Name: 'Cols03Name',
    Cols04: 'Cols04',
    Cols04Name: 'Cols04Name',
    Cols05: 'Cols05',
    Cols05Name: 'Cols05Name',
    Cols06: 'Cols06',
    Cols06Name: 'Cols06Name',
    Cols07: 'Cols07',
    Cols07Name: 'Cols07Name',
    Cols08: 'Cols08',
    Cols09: 'Cols09',
    Cols09Name: 'Cols09Name',
    Cols10: 'Cols10',
    Cols11: 'Cols11',
    Cols12: 'Cols12',
    Cols13: 'Cols13',
    Cols14: 'Cols14',
    Cols15: 'Cols15',
    Cols16: 'Cols16',
    Cols17: 'Cols17',
    Cols18: 'Cols18',
    Cols19: 'Cols19',
    Cols20: 'Cols20',
    Cols21: 'Cols21',
    Cols22: 'Cols22',
    Cols23: 'Cols23',
    Cols24: 'Cols24',
    Cols25: 'Cols25',
    Cols26: 'Cols26',
    Cols27: 'Cols27',
    Cols28: 'Cols28',
    Cols29: 'Cols29',
    Cols30: 'Cols30',
    Cols31: 'Cols31',
    Cols32: 'Cols32',
    Cols33: 'Cols33',
    Cols34: 'Cols34',
    Cols35: 'Cols35',


    Cols99: 'Cols99',
    Cols98: 'Cols98',
    Cols98Name: 'Cols98Name',
    Cols97: 'Cols97',
    Cols96: 'Cols96',

    Cols95: 'Cols95',
    Cols94: 'Cols94',
    Cols93: 'Cols93',
    Cols92: 'Cols92',

    Cols89: 'Cols89',

    Button01: '01',
    Button02: '02',
    Button03: '03',
    Button04: '04',
    Button05: '05',
    Button06: '06',
    Button07: '07',
    Button08: '08',
    Button09: '09',
    Button10: '10',
    Button11: '11',
    Button12: '12',
    Button13: '13',
    Button14: '14',
    Button15: '15',
    Button16: '16',
    Button17: '17',
    Button18: '18',
    Button19: '19',
    Button20: '20',

};

export default constS;