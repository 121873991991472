import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { getDatabase, ref, push, set, onValue } from "firebase/database";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import firebaseDB from '../initfirebase'
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography, Stack, Button, Divider } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
import { useEffect, useState } from 'react';

// components
import Page from '../components/Page';
import Logo from '../components/Logo';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import ExTextField from '../components/ExTextField';
import constS from '../const/constS';
import constM from '../const/constM';
import ExNumber from '../components/ExNumber';
import Iconify from '../components/Iconify';
import ExMessageOK from '../components/ExMessageOK';
import ExButtonIconBef from '../components/ExButtonIconBef';
const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Register() {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  const date = new Date();
  const curDate = date.getFullYear().toString()
    + (date.getMonth() + 1).toString().padStart(2, "0")
    + date.getDate().toString().padStart(2, "0");
  const db = getDatabase(firebaseDB);
  const [showOKMessage, setShowOKMessage] = useState(constS.BLANK);
  const [dataAccounts, setDataAccounts] = useState([]);

  const navigate = useNavigate();
  const smUp = useResponsive('up', 'sm');
  const mdUp = useResponsive('up', 'md');
  const [valDefault01, setValDefault01] = useState(constS.BLANK);
  const [valDefault02, setValDefault02] = useState(constS.BLANK);
  const [valDefault03, setValDefault03] = useState(constS.BLANK);
  const [valDefault04, setValDefault04] = useState(constS.BLANK);

  async function fetchData(tbName) {
    const dbRef = ref(db, tbName);
    const result = [];
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        result.push(childSnapshot.exportVal());
      });
      setDataAccounts(result);
    }, {
      onlyOnce: true
    });
    return result;
  };

  useEffect(() => {
    fetchData(constS.AccountListTBL);
  }, []);

  const CheckInput = () => {
    if (valDefault01 === constS.BLANK) {
      setShowOKMessage("Vui lòng nhập tên!");
      return false;
    }

    if (valDefault02 === constS.BLANK) {
      setShowOKMessage("Vui lòng nhập số điện thoại!");
      return false;
    }

    if (valDefault03 === constS.BLANK) {
      setShowOKMessage("Vui lòng nhập mật khẩu!");
      return false;
    }

    if (valDefault04 === constS.BLANK) {
      setShowOKMessage("Vui lòng nhập mật khẩu xác nhận!");
      return false;
    }

    if (valDefault03.length < constS.Code6) {
      setShowOKMessage("Vui lòng nhập mật khẩu từ 6 kí tự!");
      return false;
    }

    if (valDefault03 !== valDefault04) {
      setShowOKMessage("Mật khẩu và xác nhận không giống nhau!");
      return false;
    }

    return true;
  };

  const CheckExist = () => {
    let isExist = false;
    dataAccounts.forEach((ele) => {
      if (ele.Cols02 === valDefault02) {
        isExist = true;
      }
    });
    return isExist;
  };

  const onHandleRegister = () => {
    if (!CheckInput()) {
      return;
    }

    if (CheckExist()) {
      setShowOKMessage("Số điện thoại này đã tồn tại!");
      return;
    }

    const auth = getAuth(firebaseDB);
    createUserWithEmailAndPassword(auth, valDefault02 + constS.EMAILENDFIXED, valDefault03)
      .then((userCredential) => {
        const refData = ref(db, constS.AccountListTBL);
        const newData = push(refData);
        set(newData, getFormData());
        navigate('/login');
      })
      .catch((error) => {
      });
  };

  const getFormData = () => {
    const formData = {
      Cols01: valDefault01,
      Cols02: valDefault02,
      Cols03: constS.Code1,
      Cols04: constS.ZERO,
      Cols05: constS.BLANK,
      Cols06: constS.BLANK,
      Cols07: constS.BLANK,
      Cols08: constS.BLANK,
      Cols09: constS.BLANK,
      Cols10: constS.BLANK,
      Cols11: constS.BLANK,
      Cols12: constS.BLANK,
      Cols13: constS.BLANK,
      Cols14: constS.BLANK,
      Cols15: constS.BLANK,
      Cols96: curDate,
      Cols97: valDefault02,
      Cols98: curDate,
      Cols99: valDefault02,
    };
    return formData;
  };

  const refCols01 = (e) => {
    setValDefault01(e.target.value);
  };
  const refCols02 = (e) => {
    setValDefault02(e.target.value);
  };
  const refCols03 = (e) => {
    setValDefault03(e.target.value);
  };
  const refCols04 = (e) => {
    setValDefault04(e.target.value);
  };
  const refOK = () => {
    setShowOKMessage(constS.BLANK);
  };

  return (
    <Page title="Register">
      <ExMessageOK message={showOKMessage} refOK={refOK}></ExMessageOK>
      <RootStyle>
        <HeaderStyle>
          <Logo />
          {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              <Link variant="subtitle2" component={RouterLink} sx={{ fontSize: isMobile ? constS.fontSizeM : constS.fontSize }} to="/login">{"Đăng nhập"}</Link>
            </Typography>
          )}
        </HeaderStyle>

        {!isMobile && mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>{"Chào mừng đến với website mayshopkids.com"}
            </Typography>
            <img alt="register" src="/static/illustrations/illustration_register.png" />
          </SectionStyle>
        )}

        <Container>
          <ContentStyle>
            <Typography variant={isMobile ? "h1" : "h4"} gutterBottom>{"Đăng kí tài khoản"}</Typography>

            <Stack direction="row" spacing={2}>
              <Button fullWidth size="large" color="inherit" variant="outlined">
                <GoogleIcon />
              </Button>

              <Button fullWidth size="large" color="inherit" variant="outlined">
                <FacebookIcon />
              </Button>

              <Button fullWidth size="large" color="inherit" variant="outlined">
                <TwitterIcon />
              </Button>
            </Stack>

            <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: isMobile ? constS.fontSizeM : constS.fontSize }}>{"Hoặc"}</Typography>
            </Divider>

            <Stack spacing={3}>
              <ExTextField
                id={constS.Cols01} inputRef={refCols01} inputRefCapture={refCols01} valDefault={valDefault01}
                placeholder={"Tên"} Icon={getIcon('eva:person-outline')} maxLength={50}>
              </ExTextField>

              <ExNumber
                id={constS.Cols02} inputRef={refCols02} inputRefCapture={refCols02} valDefault={valDefault02}
                placeholder={"Số điện thoại"} Icon={getIcon('eva:phone-outline')} maxLength={11}>
              </ExNumber>

              <ExTextField
                id={constS.Cols03} inputRef={refCols03} inputRefCapture={refCols03} valDefault={valDefault03}
                placeholder={"Mật khẩu"} Icon={getIcon('eva:edit-2-outline')} maxLength={50} isPassWord={true}>
              </ExTextField>

              <ExTextField
                id={constS.Cols04} inputRef={refCols04} inputRefCapture={refCols04} valDefault={valDefault04}
                placeholder={"Xác nhận mật khẩu"} Icon={getIcon('eva:edit-2-outline')} maxLength={50} isPassWord={true}>
              </ExTextField>

              <ExButtonIconBef title={"Đăng kí"} icon='\static\illustrations\add.png' onClick={onHandleRegister} />
            </Stack>

            {!smUp && (
              <Typography variant="body2" sx={{ mt: 3, textAlign: 'center' }}>{constM.placeAlreadyAccount}
                <Link variant="subtitle2" to="/login" component={RouterLink}>{constM.placeLogin}</Link>
              </Typography>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
