import { getDatabase, ref, onValue, push, set } from "firebase/database";
import firebaseDB from '../initfirebase'
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { CardMedia, Card, Grid, Typography } from '@mui/material';
import constS from '../const/constS';
import { merge } from 'lodash';
import { BarChart } from '@mui/x-charts/BarChart';

import ExButtonIconBef from './ExButtonIconBef';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function ExModalChart({ isShow, DataSource, refClose }) {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  const formatter = new Intl.NumberFormat('vi', { style: 'currency', currency: 'VND' });
  let thu = [1];
  let chi = [1];
  let cols = ["1"];
  let thuTotalSum = 0;
  let chiTotalSum = 0;
  if (isShow.length > 4) {
    thu = [];
    chi = [];
    cols = [];

    const month = String(isShow).substring(4, 6);
    const year = String(isShow).substring(1, 4);
    let date = null;
    for (let i = 1; i < 32; i++) {
      date = new Date(month + "-" + i + "-" + year);
      if (date && date.getMonth() < Number(month)) {
        cols.push(i);
      }
      else {
        break;
      }
    }
    let day = "";
    let data = [];
    let sum = 0;
    if (cols && cols.length > 0) {
      for (let i = 1; i <= cols.length; i++) {
        day = i;
        if (i < 10) {
          day = "0" + i;
        }
        sum = 0;
        data = DataSource.filter(p => (String(p.Cols98).startsWith(isShow + day)) && (String(p.Cols02).startsWith("0")));
        if (data && data.length > 0) {
          for (let j = 0; j < data.length; j++) {
            sum = Number(sum) + Number(data[j].Cols01);
          }
        }
        thu.push(sum);
        thuTotalSum = Number(thuTotalSum) + Number(sum);

        sum = 0;
        data = DataSource.filter(p => (String(p.Cols98).startsWith(isShow + day)) && (String(p.Cols02).startsWith("1")));
        if (data && data.length > 0) {
          for (let j = 0; j < data.length; j++) {
            sum = Number(sum) + Number(data[j].Cols01);
          }
        }
        chi.push(sum);
        chiTotalSum = Number(chiTotalSum) + Number(sum);
      }
    }
  }
  else {
    thu = [];
    chi = [];
    cols = [];
    const year = isShow;
    for (let i = 1; i <= 12; i++) {
      cols.push(i);
    }

    let month = "";
    let data = [];
    let sum = 0;
    if (cols && cols.length > 0) {
      for (let i = 1; i <= cols.length; i++) {
        month = i;
        if (i < 10) {
          month = "0" + i;
        }
        sum = 0;
        data = DataSource.filter(p => (String(p.Cols98).startsWith(isShow + month)) && (String(p.Cols02).startsWith("0")));
        if (data && data.length > 0) {
          for (let j = 0; j < data.length; j++) {
            sum = Number(sum) + Number(data[j].Cols01);
          }
        }
        thu.push(sum);
        thuTotalSum = Number(thuTotalSum) + Number(sum);

        sum = 0;
        data = DataSource.filter(p => (String(p.Cols98).startsWith(isShow + month)) && (String(p.Cols02).startsWith("1")));
        if (data && data.length > 0) {
          for (let j = 0; j < data.length; j++) {
            sum = Number(sum) + Number(data[j].Cols01);
          }
        }
        chi.push(sum);
        chiTotalSum = Number(chiTotalSum) + Number(sum);
      }
    }
  }

  return (
    <div>
      <Dialog
        maxWidth={800}
        open={isShow !== constS.BLANK ? true : false}
        PaperComponent={PaperComponent}
      >
        <DialogTitle style={{ cursor: 'move' }}>
          <Grid container rowSpacing={0} spacing={0} sx={{ m: 0, p: 0, fontSize: isMobile ? constS.fontSizeM : constS.fontSize }}>
            <Grid item md={12} sx={{ textAlign: "right" }}>
              <ExButtonIconBef title={"Đóng"} icon='\static\illustrations\cancle.png'
                onClick={refClose} onTouchStart={refClose} />
            </Grid>
            <Grid item md={12} sx={{ textAlign: "center", fontSize: 50 }}>{"Biểu đồ thu chi của " + isShow}</Grid>
            <Grid item md={12}>
              <BarChart
                series={[
                  { data: thu },
                  { data: chi },
                ]}
                height={300}
                xAxis={[{ data: cols, scaleType: 'band' }]}
                margin={{ top: 10, bottom: 30, left: 60, right: 10 }}
              />
            </Grid>

            <Grid item md={2}><Button sx={{ height: 10, width: 10, backgroundColor: "MediumSeaGreen" }}></Button></Grid>
            <Grid item md={10} sx={{ fontSize: isMobile ? constS.fontSizeM : constS.fontSize }}>{"Doanh thu"}</Grid>
            <Grid item md={2}><Button sx={{ height: 10, width: 10, backgroundColor: "RoyalBlue" }}></Button></Grid>
            <Grid item md={10} sx={{ fontSize: isMobile ? constS.fontSizeM : constS.fontSize }}>{"Chi phí"}</Grid>
            <Grid item md={12}>{"Tổng doanh thu : " + formatter.format(thuTotalSum)}</Grid>
            <Grid item md={12}>{"Tổng chi phí : " + formatter.format(chiTotalSum)}</Grid>
          </Grid>
        </DialogTitle>
      </Dialog>
    </div >
  );
}
