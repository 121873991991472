import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, push, set, onValue, update } from "firebase/database";
import firebaseDB from '../initfirebase'
import { Container, Typography, Button, Paper, Grid, InputLabel } from '@mui/material';
import ExDataTable from '../components/ExDataTable';
import { useEffect, useState } from 'react';
import { merge } from 'lodash';
import Page from "../components/Page";
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import QrCodeOutlinedIcon from '@mui/icons-material/QrCodeOutlined';
import constM from '../const/constM';
import constS from '../const/constS';
import ExTextField from "../components/ExTextField";
import ExNumber from "../components/ExNumber";
import ExComboBox from "../components/ExComboBox";
import ExMessageOK from "../components/ExMessageOK";
import ExButton from "../components/ExButton";
import ExMessageYes from "../components/ExMessageYes";
import ExModalChart from "../components/ExModalChart";
import { useNavigate } from 'react-router-dom';
import ExCardMonney from "../components/ExCardMonney";
import ExButtonIconBef from "../components/ExButtonIconBef";
import ExButtonIconAft from "../components/ExButtonIconAft";
// ----------------------------------------------------------------------

const defaultCombo = { code: constS.ZERO, name: "" };

export default function MonneyManager() {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  const formatter = new Intl.NumberFormat('vi', { style: 'currency', currency: 'VND' });
  const date = new Date();
  const curDate = date.getFullYear().toString()
    + (date.getMonth() + 1).toString().padStart(2, "0")
    + date.getDate().toString().padStart(2, "0");
  const navigate = useNavigate();
  const [showModalChart, setShowModalChart] = useState(constS.BLANK);
  const [showYesMessage, setShowYesMessage] = useState(constS.BLANK);
  const [showOKMessage, setShowOKMessage] = useState(constS.BLANK);
  const [dataList, setDataList] = useState([]);
  const [dataListAll, setDataListAll] = useState([]);
  const [loginUser, setLoginUser] = useState(null);
  const [listAccount, setListAccount] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);

  const [dataSelected, setDataSelected] = useState([]);

  const [dataMonneyTypeCombo, setDataMonneyTypeCombo] = useState([]);
  const [dataYearCombo, setDataYearCombo] = useState([]);
  const [dataMonthCombo, setDataMonthCombo] = useState([]);

  const [valDefaultKey, setValDefaultKey] = useState(constS.BLANK);
  const [valDefault01, setValDefault01] = useState(constS.ZERO);
  const [valDefault03, setValDefault03] = useState(defaultCombo);

  const [valButton01, setValButton01] = useState(constS.BLANK);
  const [valButton02, setValButton02] = useState(constS.BLANK);
  const [valButton03, setValButton03] = useState(constS.BLANK);
  const [valButton04, setValButton04] = useState(constS.BLANK);
  const [valButton05, setValButton05] = useState(constS.BLANK);
  const [valButton06, setValButton06] = useState(constS.BLANK);
  const [valButton07, setValButton07] = useState(constS.BLANK);
  const [valButton08, setValButton08] = useState(constS.BLANK);
  const [valButton09, setValButton09] = useState(constS.BLANK);
  const [valButton10, setValButton10] = useState(constS.BLANK);
  const [valButton11, setValButton11] = useState(constS.BLANK);
  const [valButton12, setValButton12] = useState(constS.BLANK);
  const [valButton13, setValButton13] = useState(constS.BLANK);
  const [valButton14, setValButton14] = useState(constS.BLANK);
  const [valButton15, setValButton15] = useState(constS.BLANK);
  const [valButton16, setValButton16] = useState(constS.BLANK);

  const [valDefaultCreateDate, setValDefaultCreateDate] = useState(constS.BLANK);
  const [valDefaultCreateUser, setValDefaultCreateUser] = useState(constS.BLANK);

  const db = getDatabase(firebaseDB);

  async function CheckDataLogin() {
    const auth = getAuth(firebaseDB);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        listAccount.forEach((row) => {
          if (row.Cols02 === user.email.substring(0, user.email.length - 10)) {
            setLoginUser(row);
            if (row && row.Cols03 !== "9") {
              navigate('/', { replace: false });
            }
            return;
          }
        });
      }

      if (!user) { navigate('/', { replace: false }); }
    });
  };

  async function fetchDataSearch(tbName) {
    const dbRef = ref(db, tbName);
    const result = [];
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        const obj = childSnapshot.exportVal();
        const item = dataMonneyTypeCombo.filter((p) => String(p.code).includes(obj.Cols02))[0];
        result.push(merge({
          id: childSnapshot.key,
          Cols01Name: formatter.format(obj.Cols01),
          Cols98Name: obj.Cols98.substring(6, 8) + "-" + obj.Cols98.substring(4, 6) + "-" + obj.Cols98.substring(0, 4),
          Cols02Name: (item ? item.name : constS.BLANK)
        }, obj));
      });
      const sortData = [].concat(result)
        .sort((a, b) => Number(a.Cols01) > Number(b.Cols01) ? 1 : -1)
      if (tbName === constS.MonneyListTBL) {
        let filter1 = "";
        if (valButton01 !== constS.BLANK || valButton07 !== constS.BLANK ||
          valButton02 !== constS.BLANK || valButton08 !== constS.BLANK ||
          valButton03 !== constS.BLANK || valButton09 !== constS.BLANK ||
          valButton04 !== constS.BLANK || valButton10 !== constS.BLANK ||
          valButton05 !== constS.BLANK || valButton11 !== constS.BLANK ||
          valButton06 !== constS.BLANK || valButton12 !== constS.BLANK) {
          filter1 = valButton01 + constS.COMPA + valButton02 + constS.COMPA + valButton03 + constS.COMPA +
            valButton04 + constS.COMPA + valButton05 + constS.COMPA + valButton06 + constS.COMPA +
            valButton07 + constS.COMPA + valButton08 + constS.COMPA + valButton09 + constS.COMPA +
            valButton10 + constS.COMPA + valButton11 + constS.COMPA + valButton12;
        }

        let filter2 = "";
        if (valButton13 !== constS.BLANK || valButton15 !== constS.BLANK ||
          valButton14 !== constS.BLANK || valButton16 !== constS.BLANK) {
          filter2 = valButton13 + constS.COMPA + valButton14 + constS.COMPA + valButton15 + constS.COMPA +
            valButton16;
        }
        const dataSearch = sortData.filter(p => (valDefault03.code !== constS.ZERO ? String(p.Cols98).startsWith(valDefault03.code) : true) &&
          (filter1 !== constS.BLANK ? filter1.includes(String(p.Cols98).substring(4, 6)) : true) &&
          (filter2 !== constS.BLANK ? filter2.includes(String(p.Cols02)) : true)
        );

        const data = [].concat(dataSearch).splice(0, 10);
        setDataList(dataSearch);
        setCurrentData(data);
        setCurrentPage(1);
      };

    }, {
      onlyOnce: true
    });
    return result;
  };

  async function fetchDataInit(tbName) {
    const dbRef = ref(db, tbName);
    const result = [];
    onValue(dbRef, (snapshot) => {

      if (tbName === constS.MonneyListTBL) {
        snapshot.forEach((childSnapshot) => {
          const obj = childSnapshot.exportVal();
          const item = dataMonneyTypeCombo.filter((p) => String(p.code).includes(obj.Cols02))[0];
          result.push(merge({
            id: childSnapshot.key,
            Cols01Name: formatter.format(obj.Cols01),
            Cols98Name: obj.Cols98.substring(6, 8) + "-" + obj.Cols98.substring(4, 6) + "-" + obj.Cols98.substring(0, 4),
            Cols02Name: (item ? item.name : constS.BLANK)
          }, obj));
        });
        const sortData = [].concat(result)
          .sort((a, b) => Number(a.Cols98) > Number(b.Cols98) ? 1 : -1)
        setDataListAll(sortData);

        const curpage = Number(currentPage);
        const last = Number(curpage) * 10 > sortData.length ? sortData.length : Number(curpage) * 10;
        const first = Number(curpage - 1) * 10;
        const data = [].concat(sortData).splice(first, last - first);
        setDataList(sortData);
        setCurrentData(data);
      };

      if (tbName === constS.AccountListTBL) {
        snapshot.forEach((childSnapshot) => {
          result.push(childSnapshot.exportVal());
        });
        setListAccount(result);
      };

    }, {
      onlyOnce: true
    });
    return result;
  };

  async function fetchDataCombo(tbName) {
    const dbRef = ref(db, tbName);
    const result = [];
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        result.push(childSnapshot.exportVal());
      });
      const sortData = [].concat(result)
        .sort((a, b) => Number(a.code) > Number(b.code) ? 1 : -1)

      if (tbName === constS.MonneyManagerTBL) {
        setDataMonneyTypeCombo(sortData);
      }
      if (tbName === constS.MonthManagerTBL) {
        setDataMonthCombo(sortData);
      }
      if (tbName === constS.YearManagerTBL) {
        setDataYearCombo(sortData);
      }
    }, {
      onlyOnce: true
    });
    return result;
  };

  useEffect(() => {
    if (dataSelected) {
      if (dataSelected.Cols02 === constS.Button01) {
        setValButton13(constS.Button01);
      }
      if (dataSelected.Cols02 === constS.Button11) {
        setValButton14(constS.Button11);
      }
      if (dataSelected.Cols02 === constS.Button12) {
        setValButton15(constS.Button12);
      }
      if (dataSelected.Cols02 === constS.Button13) {
        setValButton16(constS.Button13);
      }
    }
  }, [dataSelected]);

  useEffect(() => {
    if (dataMonneyTypeCombo && dataMonneyTypeCombo.length > 0) {
      fetchDataInit(constS.MonneyListTBL);
    }
  }, [dataMonneyTypeCombo]);

  useEffect(() => {
    fetchDataInit(constS.AccountListTBL);
  }, []);

  useEffect(() => {
    CheckDataLogin();
    fetchDataCombo(constS.MonneyManagerTBL);
    fetchDataCombo(constS.MonthManagerTBL);
    fetchDataCombo(constS.YearManagerTBL);
  }, [listAccount]);

  const getFormData = (mode) => {
    let val02 = constS.BLANK;
    if (valButton13 !== constS.BLANK) {
      val02 = constS.Button01;
    }
    if (valButton14 !== constS.BLANK) {
      val02 = constS.Button11;
    }
    if (valButton15 !== constS.BLANK) {
      val02 = constS.Button12;
    }
    if (valButton16 !== constS.BLANK) {
      val02 = constS.Button13;
    }

    const formData = {
      Cols01: valDefault01,
      Cols02: val02,
      Cols96: curDate,
      Cols97: loginUser.Cols02,
      Cols98: mode === constS.MODE_ADD ? curDate : valDefaultCreateDate,
      Cols99: mode === constS.MODE_ADD ? loginUser.Cols02 : valDefaultCreateUser,
    };
    return formData;
  };

  const onHandleSearch = () => {
    if (valDefault03.code === constS.ZERO &&
      valButton01 === constS.BLANK && valButton08 === constS.BLANK &&
      valButton02 === constS.BLANK && valButton09 === constS.BLANK &&
      valButton03 === constS.BLANK && valButton10 === constS.BLANK &&
      valButton04 === constS.BLANK && valButton11 === constS.BLANK &&
      valButton05 === constS.BLANK && valButton12 === constS.BLANK &&
      valButton06 === constS.BLANK && valButton13 === constS.BLANK &&
      valButton14 === constS.BLANK && valButton15 === constS.BLANK &&
      valButton16 === constS.BLANK &&
      valButton07 === constS.BLANK) {
      setShowOKMessage("Vui lòng nhập điều kiện tìm kiếm!");
      return;
    }

    fetchDataSearch(constS.MonneyListTBL);
  };

  const onHandleAdd = () => {
    if (valDefault01 === constS.BLANK) {
      setShowOKMessage("Hãy nhập số tiền để đăng kí!");
      return;
    }

    if (valButton13 === constS.BLANK && valButton15 === constS.BLANK &&
      valButton14 === constS.BLANK && valButton16 === constS.BLANK) {
      setShowOKMessage("Hãy chọn loại thu chi!");
      return;
    }

    if (String(valButton13 + valButton14 + valButton15 + valButton16).length > 2) {
      setShowOKMessage("Vui lòng chỉ chọn một loại thu chi");
      return;
    }

    const cusTypes = ref(db, constS.MonneyListTBL);
    const newCusTypes = push(cusTypes);
    set(newCusTypes, getFormData(constS.MODE_ADD));
    fetchDataInit(constS.MonneyListTBL);
    setShowYesMessage("Đăng kí thông tin thành công!");
  };

  const onHandleUpdate = () => {
    if (valDefaultKey === constS.BLANK) {
      setShowOKMessage("Vui lòng chọn mã thu chi!");
      return;
    }

    if (valDefault01 === constS.BLANK) {
      setShowOKMessage("Hãy nhập số tiền!");
      return;
    }

    if (valButton13 === constS.BLANK && valButton15 === constS.BLANK &&
      valButton14 === constS.BLANK && valButton16 === constS.BLANK) {
      setShowOKMessage("Hãy chọn loại thu chi!");
      return;
    }

    if (String(valButton13 + valButton14 + valButton15 + valButton16).length > 2) {
      setShowOKMessage("Vui lòng chỉ chọn một loại thu chi");
      return;
    }


    update(ref(db, `/` + constS.MonneyListTBL + `/${valDefaultKey}/`), getFormData(''));
    fetchDataInit(constS.MonneyListTBL);
    setShowYesMessage("Cập nhật thông tin thành công!");
  };

  const onHandleClear = () => {
    setValDefaultKey(constS.BLANK);
    setValDefault01(constS.ZERO);
    setValDefault03(defaultCombo);

    setValButton01(constS.BLANK);
    setValButton02(constS.BLANK);
    setValButton03(constS.BLANK);
    setValButton04(constS.BLANK);
    setValButton05(constS.BLANK);
    setValButton06(constS.BLANK);
    setValButton07(constS.BLANK);
    setValButton08(constS.BLANK);
    setValButton09(constS.BLANK);
    setValButton10(constS.BLANK);
    setValButton11(constS.BLANK);
    setValButton12(constS.BLANK);
    setValButton13(constS.BLANK);
    setValButton14(constS.BLANK);
    setValButton15(constS.BLANK);
    setValButton16(constS.BLANK);

    setDataSelected([]);
    fetchDataInit(constS.MonneyListTBL);
  };

  const refColsKey = (e) => {
    setValDefaultKey(e.target.value);
  };

  const refCols01 = (e) => {
    setValDefault01(e.target.value);
  };

  const refCols03 = (event, value) => {
    if (value !== null) { setValDefault03(value); }
    else {
      setValDefault03(defaultCombo);
    }
  };

  const refButton01 = (code, val) => {
    if (val === constS.BLANK) {
      setValButton01(code);
    }
    else {
      setValButton01(constS.BLANK);
    }
  };

  const refButton02 = (code, val) => {
    if (val === constS.BLANK) {
      setValButton02(code);
    }
    else {
      setValButton02(constS.BLANK);
    }
  };

  const refButton03 = (code, val) => {
    if (val === constS.BLANK) {
      setValButton03(code);
    }
    else {
      setValButton03(constS.BLANK);
    }
  };

  const refButton04 = (code, val) => {
    if (val === constS.BLANK) {
      setValButton04(code);
    }
    else {
      setValButton04(constS.BLANK);
    }
  };

  const refButton05 = (code, val) => {
    if (val === constS.BLANK) {
      setValButton05(code);
    }
    else {
      setValButton05(constS.BLANK);
    }
  };

  const refButton06 = (code, val) => {
    if (val === constS.BLANK) {
      setValButton06(code);
    }
    else {
      setValButton06(constS.BLANK);
    }
  };

  const refButton07 = (code, val) => {
    if (val === constS.BLANK) {
      setValButton07(code);
    }
    else {
      setValButton07(constS.BLANK);
    }
  };

  const refButton08 = (code, val) => {
    if (val === constS.BLANK) {
      setValButton08(code);
    }
    else {
      setValButton08(constS.BLANK);
    }
  };

  const refButton09 = (code, val) => {
    if (val === constS.BLANK) {
      setValButton09(code);
    }
    else {
      setValButton09(constS.BLANK);
    }
  };

  const refButton10 = (code, val) => {
    if (val === constS.BLANK) {
      setValButton10(code);
    }
    else {
      setValButton10(constS.BLANK);
    }
  };

  const refButton11 = (code, val) => {
    if (val === constS.BLANK) {
      setValButton11(code);
    }
    else {
      setValButton11(constS.BLANK);
    }
  };

  const refButton12 = (code, val) => {
    if (val === constS.BLANK) {
      setValButton12(code);
    }
    else {
      setValButton12(constS.BLANK);
    }
  };

  const refButton13 = (code, val) => {
    if (val === constS.BLANK) {
      setValButton13(code);
    }
    else {
      setValButton13(constS.BLANK);
    }
  };

  const refButton14 = (code, val) => {
    if (val === constS.BLANK) {
      setValButton14(code);
    }
    else {
      setValButton14(constS.BLANK);
    }
  };

  const refButton15 = (code, val) => {
    if (val === constS.BLANK) {
      setValButton15(code);
    }
    else {
      setValButton15(constS.BLANK);
    }
  };

  const refButton16 = (code, val) => {
    if (val === constS.BLANK) {
      setValButton16(code);
    }
    else {
      setValButton16(constS.BLANK);
    }
  };

  const refOK = () => {
    setShowOKMessage(constS.BLANK);
    setShowYesMessage(constS.BLANK);
  };

  const onHandleChartMonth = () => {
    if (valDefault03.code === constS.ZERO) {
      setShowOKMessage("Bạn chưa chọn năm!");
      return;
    }

    const fill = valButton01 + valButton02 + valButton03 +
      valButton04 + valButton05 + valButton06 +
      valButton07 + valButton08 + valButton09 +
      valButton10 + valButton11 + valButton12;
    if (fill === constS.BLANK) {
      setShowOKMessage("Bạn chưa chọn tháng!");
      return;
    }

    if (fill.length > 2) {
      setShowOKMessage("Vui lòng chỉ chọn 1 tháng!");
      return;
    }

    setShowModalChart(valDefault03.code + fill);
  };

  const onHandleChartYear = () => {
    if (valDefault03.code === constS.ZERO) {
      setShowOKMessage("Bạn chưa chọn năm!");
      return;
    }

    setShowModalChart(valDefault03.code);
  };

  const refClose = () => {
    setShowModalChart(constS.BLANK);
  };

  const onClickSelected = (val) => {
    setDataSelected(val);
    setValDefaultKey(val.id);
    setValDefault01(val.Cols01);
    setValButton01(constS.BLANK);
    setValButton02(constS.BLANK);
    setValButton03(constS.BLANK);
    setValButton04(constS.BLANK);
    setValButton05(constS.BLANK);
    setValButton06(constS.BLANK);
    setValButton07(constS.BLANK);
    setValButton08(constS.BLANK);
    setValButton09(constS.BLANK);
    setValButton10(constS.BLANK);
    setValButton11(constS.BLANK);
    setValButton12(constS.BLANK);
    setValButton13(constS.BLANK);
    setValButton14(constS.BLANK);
    setValButton15(constS.BLANK);
    setValButton16(constS.BLANK);

    setValDefaultCreateDate(val.Cols98);
    setValDefaultCreateUser(val.Cols99);
  };

  const onClickPrev = () => {
    if (currentPage === 1) {
      return;
    }
    const curpage = currentPage - 1;
    const last = Number(curpage) * 10;
    const first = Number(last) - 10;
    const data = [].concat(dataList).splice(first, last - first);
    setCurrentData(data);
    setCurrentPage(curpage);
  };

  const onClickNext = () => {
    if (Number(currentPage) * 10 > dataList.length) {
      return;
    }
    const curpage = Number(currentPage) + 1;
    const last = Number(curpage) * 10 > dataList.length ? dataList.length : Number(curpage) * 10;
    const first = Number(curpage - 1) * 10;
    const data = [].concat(dataList).splice(first, last - first);
    setCurrentData(data);
    setCurrentPage(curpage);
  };

  return [
    <Page title={"Quản lí thu chi"}>
      <ExMessageYes message={showYesMessage} refOK={refOK}></ExMessageYes>
      <ExMessageOK message={showOKMessage} refOK={refOK}></ExMessageOK>
      <ExModalChart isShow={showModalChart} DataSource={dataListAll} refClose={refClose}></ExModalChart>
      <Container maxWidth="xl">
        <Typography variant={"h1"}>{"Quản lí thu chi"}</Typography>
        <Paper variant="outlined">
          <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 2, fontSize: 40 }}>
            <Grid item md={4}>{"Mã thu chi"}
            </Grid>
            <Grid item md={7.5}>
              <ExTextField
                id={constS.id} inputRef={refColsKey} valDefault={valDefaultKey}
                placeholder={constM.placeKey} Icon={<QrCodeOutlinedIcon />} maxLength={50} disabled={true}></ExTextField>
            </Grid>
            <Grid item md={4}>{"Số tiền"}
            </Grid>
            <Grid item md={7.5}>
              <ExNumber
                id={constS.Cols01} inputRef={refCols01} valDefault={valDefault01}
                placeholder={"Số tiền"} Icon={<AddCardOutlinedIcon />} maxLength={100}></ExNumber>
            </Grid>
            <Grid item md={4}>{"Chọn năm"}
            </Grid>
            <Grid item md={7.5}>
              <ExComboBox
                id={constS.Cols03} comboRef={refCols03} valDefault={valDefault03}
                placeholder={"Năm"} DataSource={dataYearCombo}></ExComboBox>
            </Grid>
            <Grid item md={11.5}>{"Chọn tháng"}
            </Grid>
            {dataMonthCombo[1] && <Grid item  ><ExButton props={dataMonthCombo[1]} inputRef={refButton01} valDefault={valButton01} /></Grid>}
            {dataMonthCombo[2] && <Grid item  ><ExButton props={dataMonthCombo[2]} inputRef={refButton02} valDefault={valButton02} /></Grid>}
            {dataMonthCombo[3] && <Grid item  ><ExButton props={dataMonthCombo[3]} inputRef={refButton03} valDefault={valButton03} /></Grid>}
            {dataMonthCombo[4] && <Grid item  ><ExButton props={dataMonthCombo[4]} inputRef={refButton04} valDefault={valButton04} /></Grid>}
            {dataMonthCombo[5] && <Grid item  ><ExButton props={dataMonthCombo[5]} inputRef={refButton05} valDefault={valButton05} /></Grid>}
            {dataMonthCombo[6] && <Grid item  ><ExButton props={dataMonthCombo[6]} inputRef={refButton06} valDefault={valButton06} /></Grid>}
            {dataMonthCombo[7] && <Grid item  ><ExButton props={dataMonthCombo[7]} inputRef={refButton07} valDefault={valButton07} /></Grid>}
            {dataMonthCombo[8] && <Grid item  ><ExButton props={dataMonthCombo[8]} inputRef={refButton08} valDefault={valButton08} /></Grid>}
            {dataMonthCombo[9] && <Grid item  ><ExButton props={dataMonthCombo[9]} inputRef={refButton09} valDefault={valButton09} /></Grid>}
            {dataMonthCombo[10] && <Grid item ><ExButton props={dataMonthCombo[10]} inputRef={refButton10} valDefault={valButton10} /></Grid>}
            {dataMonthCombo[11] && <Grid item ><ExButton props={dataMonthCombo[11]} inputRef={refButton11} valDefault={valButton11} /></Grid>}
            {dataMonthCombo[12] && <Grid item ><ExButton props={dataMonthCombo[12]} inputRef={refButton12} valDefault={valButton12} /></Grid>}

            <Grid item md={11.5}>{"Loại thu chi"}
            </Grid>
            {dataMonneyTypeCombo[1] && <Grid item ><ExButton props={dataMonneyTypeCombo[1]} inputRef={refButton13} valDefault={valButton13} /></Grid>}
            {dataMonneyTypeCombo[2] && <Grid item ><ExButton props={dataMonneyTypeCombo[2]} inputRef={refButton14} valDefault={valButton14} /></Grid>}
            {dataMonneyTypeCombo[3] && <Grid item ><ExButton props={dataMonneyTypeCombo[3]} inputRef={refButton15} valDefault={valButton15} /></Grid>}
            {dataMonneyTypeCombo[4] && <Grid item ><ExButton props={dataMonneyTypeCombo[4]} inputRef={refButton16} valDefault={valButton16} /></Grid>}
            <Grid item md={11.5}></Grid>
            <Grid item >
              <ExButtonIconBef title={"Tìm kiếm"} icon='\static\illustrations\search.png' onClick={onHandleSearch} />
            </Grid>
            <Grid item >
              <ExButtonIconBef title={"Đăng kí"} icon='\static\illustrations\add.png' onClick={onHandleAdd} />
            </Grid>
            <Grid item >
              <ExButtonIconBef title={"Chỉnh sửa"} icon='\static\illustrations\edit.png' onClick={onHandleUpdate} />
            </Grid>
            <Grid item >
              <ExButtonIconBef title={"Xóa nhập"} icon='\static\illustrations\delete.png' onClick={onHandleClear} />
            </Grid>
            <Grid item >
              <ExButtonIconBef title={"Biểu đồ tháng"} icon='\static\illustrations\export.png' onClick={onHandleChartMonth} />
            </Grid>
            <Grid item >
              <ExButtonIconBef title={"Biểu đồ Năm"} icon='\static\illustrations\export.png' onClick={onHandleChartYear} />
            </Grid>
          </Grid>
        </Paper>

        <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 2 }}>
          {currentData.map((item) => (
            <Grid item md={12}>
              <ExCardMonney DataSource={item} onClick={onClickSelected} selectedId={valDefaultKey}></ExCardMonney>
            </Grid>
          ))}

          <Grid item md={6} sx={{ textAlign: "left" }}>
            <ExButtonIconBef title={"Trang trước"} icon='\static\illustrations\prev.png' onClick={onClickPrev} />
          </Grid>
          <Grid item md={6} sx={{ textAlign: "right" }}>
            <ExButtonIconAft title={"Trang sau"} icon='\static\illustrations\next.png' onClick={onClickNext} />
          </Grid>

        </Grid>
      </Container >
    </Page >
  ];
}
