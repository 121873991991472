import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, onValue, remove, update, push, set } from "firebase/database";
import firebaseDB from '../initfirebase'
import { Button, Container, Grid, InputLabel, Typography } from '@mui/material';
import Page from '../components/Page';
import { merge } from 'lodash';
import { useEffect, useState } from 'react';
import constS from "../const/constS";
import ExOrderCard from "../components/ExOrderCard";
import ExMessageOK from "../components/ExMessageOK";
import ExMessageOKCancel from "../components/ExMessageOKCancel";
import { useNavigate } from 'react-router-dom';
import ExButtonIconBef from "../components/ExButtonIconBef";
// ----------------------------------------------------------------------

export default function OrderCard() {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  const formatter = new Intl.NumberFormat('vi', { style: 'currency', currency: 'VND' });
  const date = new Date();
  const curDate = date.getFullYear().toString()
    + (date.getMonth() + 1).toString().padStart(2, "0")
    + date.getDate().toString().padStart(2, "0");

  const navigate = useNavigate();
  const [dataNameCombo, setDataNameCombo] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [dataProductList, setDataProductList] = useState([]);
  const [showOKMessage, setShowOKMessage] = useState(constS.BLANK);
  const [showOKCancelMessage, setShowOKCancelMessage] = useState(constS.BLANK);
  const [loginUser, setLoginUser] = useState(null);
  const [itemDelete, setItemDelete] = useState(constS.BLANK);
  const [itemMinus, setItemMinus] = useState(constS.BLANK);
  const [itemPlus, setItemPlus] = useState(constS.BLANK);
  const [sumTotal, setSumTotal] = useState(constS.ZERO);
  const [listAccount, setListAccount] = useState([]);
  const [sumPayment, setSumPayment] = useState(constS.ZERO);
  const db = getDatabase(firebaseDB);

  async function CheckDataLogin() {
    const auth = getAuth(firebaseDB);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        listAccount.forEach((row) => {
          if (row.Cols02 === user.email.substring(0, user.email.length - 10)) {
            setLoginUser(row);
            return;
          }
        });
      }

      if (!user) { navigate('/', { replace: false }); }
    });
  };

  async function fetchDataAccount() {
    const dbRef = ref(db, constS.AccountListTBL);
    const result = [];
    let obj = null;
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        obj = childSnapshot.exportVal();
        result.push(merge({ id: childSnapshot.key }, obj));
      });
      const sortData = [].concat(result)
        .sort((a, b) => Number(a.Cols02) > Number(b.Cols02) ? 1 : -1);
      setListAccount(sortData);
    }, {
      onlyOnce: true
    });
    return result;
  };

  async function fetchData(tbName) {
    const dbRef = ref(db, tbName);
    const result = [];
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        result.push(merge({ id: childSnapshot.key }, childSnapshot.exportVal()));
      });
      if (tbName === constS.ProductListTBN) {
        setDataProductList(result);
      }
      if (tbName === constS.OrderCardTBL) {
        const sort = [].concat(result)
          .sort((a, b) => Number(a.Cols96) > Number(b.Cols96) ? 1 : -1);
        const tel = loginUser.Cols02;
        const sortData = sort.filter((p) => String(p.Cols06).startsWith(tel))
        setDataList(sortData);
        let sum = 0;
        let minus = 0;
        sortData.forEach((item) => {
          sum = Number(sum) + Number(item.Cols05);
        });
        setSumTotal(sum);
        // setSumMinus(minus);
        setSumPayment(Number(sum) - Number(minus));
      }
      if (tbName === constS.BillListTBN) {
        const sortData = [].concat(result)
          .sort((a, b) => Number(a.Cols96) > Number(b.Cols96) ? 1 : -1);
        const tel = loginUser.Cols02;
        const listBill = sortData.filter((p) =>
          String(p.Cols01).startsWith(tel) &&
          String(p.Cols06).startsWith(curDate));
        let order = 0;
        if (listBill && listBill.length > 0) {
          const sort = [].concat(listBill)
            .sort((a, b) => Number(a.Cols08) < Number(b.Cols08) ? 1 : -1);
          order = Number(order) + Number(sort[0].Cols08) + 1;
        }
        const formData = {
          Cols01: tel,
          Cols02: tel,
          Cols03: sumTotal,
          Cols04: 0,
          Cols05: sumPayment,
          Cols06: curDate,
          Cols07: constS.Code1,
          Cols08: order,
          Cols09: 0,
          Cols96: curDate,
          Cols97: tel,
          Cols98: curDate,
          Cols99: tel,
        };
        const cusTypes = ref(db, constS.BillListTBN);
        const newCusTypes = push(cusTypes);
        set(newCusTypes, formData);
        fetchDataAfterInsert(constS.BillListTBN);
      }
    }, {
      onlyOnce: true
    });
    return result;
  };

  async function fetchDataAfterInsert(tbName) {
    const dbRef = ref(db, tbName);
    const result = [];
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        result.push(merge({ id: childSnapshot.key }, childSnapshot.exportVal()));
      });
      const sortData = [].concat(result)
        .sort((a, b) => Number(a.Cols96) > Number(b.Cols96) ? 1 : -1);
      const tel = loginUser.Cols02;
      const listBill = sortData.filter((p) =>
        String(p.Cols01).startsWith(tel) &&
        String(p.Cols06).startsWith(curDate));
      if (listBill && listBill.length > 0) {
        const sort = [].concat(listBill)
          .sort((a, b) => Number(a.Cols08) < Number(b.Cols08) ? 1 : -1);
        const lastItem = sort[0];
        dataList.forEach((ele) => {
          const formData = {
            Cols01: lastItem.id,
            Cols02: ele.Cols01,
            Cols03: ele.Cols02,
            Cols04: ele.Cols04,
            Cols05: ele.Cols05,
            Cols06: ele.Cols03,
            Cols96: curDate,
            Cols97: tel,
            Cols98: curDate,
            Cols99: tel,
          };
          const cusTypes = ref(db, constS.BillDetailTBN);
          const newCusTypes = push(cusTypes);
          set(newCusTypes, formData);
          const product = dataProductList.filter(p => String(p.Cols01).startsWith(ele.Cols01));
          if (product && product.length > 0) {
            const numOf = Number(product[0].Cols02) - Number(ele.Cols04);
            update(ref(db, `/` + constS.ProductListTBN + `/${product[0].id}/`), { Cols02: numOf });
          }

          remove(ref(db, `/` + constS.OrderCardTBL + `/${ele.id}`));
        });
        fetchData(constS.OrderCardTBL);
      }
    }, {
      onlyOnce: true
    });
    return result;
  };

  async function fetchDataCombo(tbName) {
    const dbRef = ref(db, tbName);
    const result = [];
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        result.push(childSnapshot.exportVal());
      });
      const sortData = [].concat(result)
        .sort((a, b) => Number(a.code) > Number(b.code) ? 1 : -1);

      if (tbName === constS.ProductNameTBL) {
        setDataNameCombo(sortData);
      }
    }, {
      onlyOnce: true
    });
    return result;
  };

  useEffect(() => {
    fetchDataAccount();
  }, []);

  useEffect(() => {
    if (!listAccount || listAccount.length <= 0) {
      return;
    }

    CheckDataLogin();
  }, [listAccount]);

  useEffect(() => {
    if (loginUser) {
      fetchData(constS.OrderCardTBL);
      fetchData(constS.ProductListTBN);
      fetchDataCombo(constS.ProductNameTBL);
    }
  }, [loginUser]);

  const onGetProductName = (val) => {
    const res = dataNameCombo.filter(p => String(p.code).startsWith(val));
    if (res && res.length <= 0) {
      return constS.BLANK;
    }
    return res[0].name;
  };
  const onOrderCardDelete = (val) => {
    setItemDelete(val);
  };

  const onOrderCardMinus = (val) => {
    setItemMinus(val);
  };

  const onOrderCardPlus = (val, productType, numOf) => {
    const product = dataProductList.filter(p => String(p.Cols01).startsWith(productType));
    if (product && product.length > 0) {
      if (Number(product[0].Cols02) < Number(numOf) + 1) {
        setShowOKMessage("Hiện tại kho hàng chỉ còn lại " + numOf + " sản phẩm");
        return;
      }
    }
    setItemPlus(val);
  };

  useEffect(() => {
    if (itemDelete !== constS.BLANK) {
      setShowOKCancelMessage("Xác nhận có xóa sản phẩm này không?");
    }
  }, [itemDelete]);

  useEffect(() => {
    if (itemMinus !== constS.BLANK) {
      const item = dataList.filter(p => String(p.id).startsWith(itemMinus));
      if (item && item.length > 0) {
        const num = Number(item[0].Cols04) === 1 ? 1 : Number(item[0].Cols04) - 1;
        const data = {
          Cols04: num,
          Cols05: Number(item[0].Cols02) * Number(num),
          Cols96: curDate,
        }
        update(ref(db, `/` + constS.OrderCardTBL + `/${itemMinus}/`), data);
        fetchData(constS.OrderCardTBL);
        setItemMinus(constS.BLANK);
      }
    }
  }, [itemMinus]);

  useEffect(() => {
    if (itemPlus !== constS.BLANK) {
      const item = dataList.filter(p => String(p.id).startsWith(itemPlus));
      if (item && item.length > 0) {
        const num = Number(item[0].Cols04) + 1;
        const data = {
          Cols04: num,
          Cols05: Number(item[0].Cols02) * Number(num),
          Cols96: curDate,
        }
        update(ref(db, `/` + constS.OrderCardTBL + `/${itemPlus}/`), data);
        fetchData(constS.OrderCardTBL);
        setItemPlus(constS.BLANK);
      }
    }
  }, [itemPlus]);

  const onClickAddBill = () => {
    if (!dataList || dataList.length === 0) {
      setShowOKMessage("Vui lòng chọn sản phẩm để đặt hàng!");
      return;
    }

    if (loginUser.Cols07 === constS.BLANK) {
      setShowOKCancelMessage("Vui lòng cập nhập thông tin địa chỉ giao hàng?");
      return;
    }

    setShowOKCancelMessage("Xác nhận thực hiện đặt hàng?");
  };

  const refOK = () => {
    setShowOKMessage(constS.BLANK);
    setShowOKCancelMessage(constS.BLANK);
    setItemDelete(constS.BLANK);
  };

  const refConfirm = (message) => {
    setShowOKCancelMessage(constS.BLANK);
    if ("Xác nhận có xóa sản phẩm này không?" === message) {
      remove(ref(db, `/` + constS.OrderCardTBL + `/${itemDelete}`));
      fetchData(constS.OrderCardTBL);
      setItemDelete(constS.BLANK);
    }
    if ("Xác nhận thực hiện đặt hàng?" === message) {
      fetchData(constS.BillListTBN);
    }

    if ("Vui lòng cập nhập thông tin địa chỉ giao hàng?" === message) {
      navigate('/accountmanagement');
    }
  };

  return (
    <Page title={"Giỏ hàng"}>
      <ExMessageOK message={showOKMessage} refOK={refOK} isMobile={isMobile}></ExMessageOK>
      <ExMessageOKCancel message={showOKCancelMessage} refOK={refOK} refConfirm={refConfirm} isMobile={isMobile}></ExMessageOKCancel>
      {isMobile && <Container maxWidth="xl">
        <Typography variant="h1">{"Giỏ hàng của bạn"}</Typography>
        <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 2, fontSize: constS.fontSizeM }}>
          <Grid item md={8}>{"Thông tin sản phẩm"}
          </Grid>
          <Grid item md={4}>{"Đơn giá"}
          </Grid>
          <Grid item md={8} sx={{ textAlign: "center" }}>{"Số lượng"}
          </Grid>
          <Grid item md={4}>{"Thành tiền"}
          </Grid>
          {dataList.map((item) => (
            <Grid item md={12}>
              <ExOrderCard isMobile={isMobile} DataSource={item} cardName={onGetProductName(item.Cols01)}
                onOrderCardDelete={onOrderCardDelete} onOrderCardMinus={onOrderCardMinus} onOrderCardPlus={onOrderCardPlus}></ExOrderCard>
            </Grid>
          ))}

          <Grid item md={8} sx={{ textAlign: "right" }}>{"Tạm tính:"}
          </Grid>
          <Grid item md={4} sx={{ textAlign: "left" }}>{formatter.format(sumTotal)}
          </Grid>

          <Grid item md={8} sx={{ textAlign: "right" }}>{"Thanh toán:"}
          </Grid>
          <Grid item md={4} sx={{ textAlign: "left" }}>{formatter.format(sumPayment)}
          </Grid>

          <Grid item md={12} sx={{ textAlign: "right" }}>
            <ExButtonIconBef title={"Đặt hàng"}
              icon='\static\illustrations\order.png' onClick={onClickAddBill} />
          </Grid>
        </Grid>
      </Container>}

      {!isMobile && <Container maxWidth="xl">
        <Typography variant="h1">{"Giỏ hàng của bạn"}</Typography>
        <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 2, fontSize: constS.fontSize }}>
          <Grid item md={5} >{"Thông tin sản phẩm"}
          </Grid>
          <Grid item md={2} >{"Đơn giá"}
          </Grid>
          <Grid item md={2} >{"Số lượng"}
          </Grid>
          <Grid item md={2} >{"Thành tiền"}
          </Grid>
          {dataList.map((item) => (
            <Grid item md={12}>
              <ExOrderCard DataSource={item} cardName={onGetProductName(item.Cols01)}
                onOrderCardDelete={onOrderCardDelete} onOrderCardMinus={onOrderCardMinus} onOrderCardPlus={onOrderCardPlus}></ExOrderCard>
            </Grid>
          ))}

          <Grid item md={9} sx={{ textAlign: "right" }}>{"Tạm tính:"}
          </Grid>
          <Grid item md={3} sx={{ textAlign: "left" }}>{formatter.format(sumTotal)}
          </Grid>

          <Grid item md={9} sx={{ textAlign: "right" }}>{"Thanh toán:"}
          </Grid>
          <Grid item md={3} sx={{ textAlign: "left" }}>{formatter.format(sumPayment)}
          </Grid>

          <Grid item md={11} sx={{ textAlign: "right" }}>
            <ExButtonIconBef title={"Đặt hàng"}
              icon='\static\illustrations\order.png' onClick={onClickAddBill} />
          </Grid>
        </Grid>
      </Container>}
    </Page >
  );
}
