const palette = {
  primary: {
    main: '#11cb5f',
  },
  secondary: {
    main: '#11cb5f',
  },
};

export default palette;
