const constM = {
    cusType: 'Customer Type',
    cusSex: 'Customer Sex',
    shopStatus: 'Shop Status',
    shopJuridical: 'Shop Juridical',
    shopObject: 'Shop Object',
    shopType: 'Shop Type',
    shopWayRoad: 'Shop Way Road',
    shopTypeStreet: 'Shop Type Street',
    shopUseAisle: 'Shop Use Aisle',
    shopDirection: 'Shop Direction',
    shopModal: 'Shop Modal',
    demandStatus: 'Demand Status',
    cityObject: 'City Object',
    districtObject: 'District Object',
    wardObject: 'Ward Object',
    operatorType: 'Loại tìm kiếm',
    priceOrder: 'Price Order',
    areaOrder: 'Area Order',
    accountPermission: 'Account Permission',
    houseStatus: 'House Status',

    customerInfor: 'Customer Information',
    houseInfor: 'House Information',
    compactInfor: 'Compact Information',

    contMessCodeRequired: 'Please enter the code',
    contMessNameRequired: 'Please enter the name',
    constMessPasswordRequired: 'Please enter the password',
    contMessPhoneRequired: 'Please enter the phone number',
    contMessNameExist: 'Code already exists...Please enter another',
    contMessPhoneNumberExist: 'Phone Number already exists...Please enter another',
    contMessIdNotExist: 'Vui lòng chọn mã {0} cần xóa!',
    contMessMoveConfirm: 'Do you warn move?',
    constMessConfirmIncorrect: 'Confirmation password is incorrect!',
    constMessPasswordLength: 'Password must be greater than 6 characters',
    constHomeDisplay: 'Home display do not delete',

    contCodeField: 'Code...',
    contNameField: 'Name...',

    ERROR: '*** Lỗi ***',
    CONFIRM: '*** Xác nhận ***',

    constMessBillIdNotExist: 'Vui lòng chọn mã hóa đơn cần xóa!',
    constMessBillIdNotExistUpdate: 'Vui lòng chọn mã hóa đơn cần cập nhật!',
    constMessDeleteConfirmUpdate: 'Bạn có xác nhận cập nhật không?',
    constMessBillIdNotExistUpdateDetail: 'Vui lòng chọn mã hóa đơn cần cập nhật chi tiết!',
    MESSID_0001: 'Chưa chọn tên sản phẩm!',
    MESSID_0002: 'Chưa nhập đơn giá!',
    MESSID_0003: 'Chưa chọn mã chi tiết hóa đơn!',
    MESSID_0004: 'Chưa nhập số lượng!',
    MESSID_0005: 'Tên sản phẩm đã tồn tại!',
    MESSID_0006: 'Bạn có xác nhận xóa không?',

    Home: 'Home',
    masterCodeLists: 'Nội dung',
    listProducts: 'Sản phẩm',
    listBills: 'Hóa đơn',
    listMainternance: 'Quản lý',
    demandList: 'Demand List',
    demandSend: 'Demand Send',
    accountManagerment: 'Account Management',
    accountMainternance: 'Account Mainternance',

    endPrice: 'mi',
    endLength: 'm',
    endArea: 'm2',
    endFloor: 'fl',
    endRoom: 'ro',
    endYear: 'ye',
    endMonth: 'mo',

    id: 'Key',
    code: 'Code',
    name: 'Name',

    placeMove: 'Move',
    placeSearch: 'Tìm kiếm',
    placeAdd: 'Thêm',
    placeUpdate: 'Cập nhật',
    placeDelete: 'Xóa',
    placeClear: 'Nhập lại',
    placeDetail: 'Chi tiết',
    placeSend: 'Send',
    placeSave: 'Save',

    placeProduct: 'sản phẩm',
    placeKey: 'Mã',
    placeName: 'Tên', 
    placeQuantity: 'Số lượng',
    placeInventory: 'tồn kho',
    placeType: 'Phân loại',
    placeImportPrice: 'Giá nhập',
    placeRetailPrice: 'Giá bán lẻ',
    placeWholesalePrice: 'Giá bán sỉ',
    placeDescription: 'Mô tả',
    placeImage: 'Hình ảnh',
    placeOperatorType: 'tìm...',

    placeProductKey: 'Mã sản phẩm',

    placeBill: 'hóa đơn',
    placeCustomer: 'khách hàng',
    placeCustomerPhone: 'Số điện thoại',
    placeTotalPrice: 'Giá tổng',
    placeDiscount: 'Giảm giá',
    placePayment: 'Khách trả',
    placeBillPrice: 'Đơn giá',
    placeBillPayment: 'Thành tiền',
    

    placeUpdateUser: 'Người cập nhật',
    placeUpdateDate: 'Ngày cập nhật',
    placeCreateUser: 'Người tạo',
    placeCreateDate: 'Ngày tạo',
    placeDeleteFlag: 'Cờ xóa',

    placeStatus: 'Trạng thái',

    placeCustomerName: 'Name',
    placeCustomerSex: 'Giới tính',
    placeCustomerEmail: 'Thư điện tử',
    placeCustomerCompany: 'Company',
    placeCustomerType: 'Type',
    placeCustomerStatus: 'Status',
    placeCustomerDescription: 'Description',
    placeCustomerDescription2: 'Cho thuê nhà 125 Trần Đình Xu,Quận 1\nDiện tích: 4x18m, 1 trệt 3 lầu\nGiá thuê: 1 trệt 1 lầu giá 50tr/ tháng; NC 75tr/tháng\nNhà phù Hợp làm văn phòng công ty, spa,..., Không kinh doanh ngành hàng nấu nướng\nHoa hồng: 1 tháng',
    placeHousePrice: 'Price',
    placeHouseCost: 'Cost',
    placeHouseAddress: 'Address',
    placeCityAddress: 'City',
    placeDistrictAddress: 'District',
    placeWardAddress: 'Ward',
    placeStreetAddress: 'Street',
    placeHouseFront: 'Front',
    placeHouseBehind: 'Behind',
    placeHouseLong: 'Long',
    placeHouseNumfloor: 'Num floor',
    placeHouseNumroom: 'Num room',
    placeHouseArea: 'Area',
    placeHouseAreatotal: 'Area total',
    placeCompactTerm: 'Compact Term',
    placeCompactDeposit: 'Compact Deposit',
    placeCompactPay: 'Compact Pay',
    placeCompactJuridical: 'Compact Juridical',
    placePartner: 'Partner',
    placeHouseType: 'House Type',
    placeHousePassage: 'House Passage',
    placeHouseOrientate: 'House Orientate',
    placeUseAisle: 'Use Aisle',
    placeDirection: 'Direction',
    placeBussinessModal: 'Bussiness Modal',
    placeHomeOrder: 'Home Order',
    placeAccountAvatar: 'Avatar Link...https://scontent.fsgn16-1.fna.fbcdn.net/v/t39.30808-6/325276848_1374869163343469_5940554905751267685_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=a2f6c7&_nc_ohc=aQDYNSjeLpoAX_OnsGL&_nc_ht=scontent.fsgn16-1.fna&_nc_e2o=f&oh=00_AfCCXGATbDmIxGT_lpXxvyiY4J69ND6pcL3VIIbi3ZJDfA&oe=6513C0EF',

    placePassword: 'Password',
    placeRePassword: 'Re-Password',
    placeRegisterAccount: 'Register Account',
    placeOr: 'Or',
    placeLogin: 'Login',
    placeRegister: 'Register',
    placeAlreadyAccount: 'Already have an account?',
    placeWelcome: 'Welcome!',
    placeHaveAccount: "if you don't have an account?",
    placeLandRent: "Login to the LandRent system",
    placeLandRent: "Login to the LandRent system",
    placeInputInfor: "Please enter your information",
    placeRemember: "remember",
    placeForgotPassword: "Forgot password?",
    placeLogout: "Logout",
    placeHello: 'Hello! ',
    placeCustomerLink: 'Ảnh đại diện',
    placePermission: 'Quyền',
};

export default constM;