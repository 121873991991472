import { getDatabase, ref, push, set, onValue, update, remove } from "firebase/database";
import firebaseDB from '../initfirebase'
// import { useRef } from 'react';
import { Container, Typography, Paper, Grid, Button } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { merge } from 'lodash';
import Page from "../components/Page";
import { useLocation } from "react-router-dom";
import constM from "../const/constM";
import constS from "../const/constS";
import ExTextField from "../components/ExTextField";
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import QrCodeOutlinedIcon from '@mui/icons-material/QrCodeOutlined';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import ExNumber from "../components/ExNumber";
import ExMessageOK from "../components/ExMessageOK";
import ExCardCode from "../components/ExCardCode";
import ExMessageOKCancel from "../components/ExMessageOKCancel";
import ExButtonIconBef from "../components/ExButtonIconBef";
import ExButtonIconAft from "../components/ExButtonIconAft";
// ----------------------------------------------------------------------


export default function CodeMainternance() {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  const { state } = useLocation();
  const [dataList, setDataList] = useState([]);
  const [showOKMessage, setShowOKMessage] = useState(constS.BLANK);
  const [showOKCancelMessage, setShowOKCancelMessage] = useState(constS.BLANK);


  const [valIdDefault, setValIdDefault] = useState(constS.BLANK);
  const [valCodeDefault, setValCodeDefault] = useState(constS.BLANK);
  const [valNameDefault, setValNameDefault] = useState(constS.BLANK);
  const [valImageDefault, setValImageDefault] = useState(constS.BLANK);

  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);

  const db = getDatabase(firebaseDB);
  const tableName = useRef(constS.BLANK);
  const codeLength = useRef(constS.BLANK);

  async function fetchData(tbName) {
    const dbRef = ref(db, tbName);
    const result = [];
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        result.push(merge({ id: childSnapshot.key }, childSnapshot.exportVal()));
      });
      const sortData = [].concat(result)
        .sort((a, b) => Number(a.code) > Number(b.code) ? 1 : -1)

      const curpage = Number(currentPage);
      const last = Number(curpage) * 10 > sortData.length ? sortData.length : Number(curpage) * 10;
      const first = Number(curpage - 1) * 10;
      const data = [].concat(sortData).splice(first, last - first);
      setDataList(sortData);
      setCurrentData(data);
    }, {
      onlyOnce: true
    });
    return result;
  }

  async function fetchDatabyName(tbName) {
    const dbRef = ref(db, tbName);
    const result = [];
    let obj = null;
    const listSearch = String(valNameDefault).split(constS.SPACE);
    let isValid = false;
    onValue(dbRef, (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        obj = childSnapshot.exportVal();
        const item = obj;

        if (valNameDefault !== constS.BLANK) {
          isValid = false;
          if (listSearch && listSearch.length > 0) {
            listSearch.forEach((it) => {
              if (it !== constS.BLANK && String(item.name).toLowerCase().includes(it.toLowerCase())) {
                isValid = true;
              }
            });
          }
          if (isValid) {
            result.push(merge({ id: childSnapshot.key }, obj));
          }
        }
        else {
          result.push(merge({ id: childSnapshot.key }, obj));
        }
      });

      const sortData = [].concat(result)
        .sort((a, b) => Number(a.code) > Number(b.code) ? 1 : -1);
      const data = [].concat(sortData).splice(0, 10);
      setDataList(sortData);
      setCurrentData(data);
      setCurrentPage(1);
    }, {
      onlyOnce: true
    });
    return result;
  };

  useEffect(() => {
    if (state === constM.placeType + " " + constM.placeSearch) {
      codeLength.current = 1;
      tableName.current = constS.OperatorTBL;
    }

    if (state === constM.placeName + " " + constM.placeProduct) {
      codeLength.current = 4;
      tableName.current = constS.ProductNameTBL;
    }

    if (state === constM.placeType + " " + constM.placeProduct) {
      codeLength.current = 2;
      tableName.current = constS.ProductTypeTBL;
    }

    if (state === constM.placeStatus + " " + constM.placeBill) {
      codeLength.current = 1;
      tableName.current = constS.BillStatusTBL;
    }

    if (state === constM.placeCustomerSex) {
      codeLength.current = 1;
      tableName.current = constS.SexTBL;
    }

    if (state === constM.placePermission) {
      codeLength.current = 1;
      tableName.current = constS.AccountPermissionTBL;
    }

    if (state === "Trạng thái hoạt động") {
      codeLength.current = 1;
      tableName.current = constS.StatusActiveTBL;
    }

    if (state === "Thêm nội dung chính sách") {
      codeLength.current = 2;
      tableName.current = constS.RulerTBL;
    }

    if (state === "Phân loại thu chi") {
      codeLength.current = 2;
      tableName.current = constS.MonneyManagerTBL;
    }

    if (state === "Danh sách tháng") {
      codeLength.current = 2;
      tableName.current = constS.MonthManagerTBL;
    }

    if (state === "Danh sách năm") {
      codeLength.current = 4;
      tableName.current = constS.YearManagerTBL;
    }

    fetchData(tableName.current);
  }, []);

  const getFormData = () => {
    const formData = {
      code: valCodeDefault,
      name: valNameDefault,
      image: valImageDefault,
    };
    return formData;
  };

  const CheckAdd = () => {
    let isExist = true;
    dataList.forEach((ele) => {
      if (state === constM.districtObject || state === constM.wardObject) {
        if (ele.code === valCodeDefault) {
          isExist = false;
        }
      }
      else {
        if (ele.code === valCodeDefault || ele.name === valNameDefault) {
          isExist = false;
        }
      }
    });
    return isExist;
  }

  const CheckUpdate = () => {
    let isExist = true;
    dataList.forEach((ele) => {
      if (ele.id !== valIdDefault) {
        if (state === constM.districtObject || state === constM.wardObject) {
          if (ele.code === valCodeDefault) {
            isExist = false;
          }
        }
        else {
          if (ele.code === valCodeDefault || ele.name === valNameDefault) {
            isExist = false;
          }
        }
      }
    });
    return isExist;
  }

  const onHandleSearch = () => {
    if (valNameDefault === constS.BLANK) {
      setShowOKMessage("Hãy nhập tên để tìm kiếm!");
      return;
    }
    fetchDatabyName(tableName.current);
  }

  const onHandleAdd = () => {
    if (valCodeDefault === constS.BLANK) {
      setShowOKMessage("Nhập mã để đăng kí!");
      return;
    }

    if (valCodeDefault.length !== Number(codeLength.current) && valCodeDefault !== constS.ZERO) {
      setShowOKMessage("Mã chưa đủ " + Number(codeLength.current) + " kí tự!");
      return;
    }

    if (valNameDefault === constS.BLANK) {
      setShowOKMessage("Nhập tên để đăng kí!");
      return;
    }

    if (!CheckAdd()) {
      setShowOKMessage("Mã này đã được đăng kí!");
      return;
    }

    const cusTypes = ref(db, tableName.current);
    const newCusTypes = push(cusTypes);
    set(newCusTypes, getFormData());
    fetchData(tableName.current);
  }

  const onHandleUpdate = () => {
    if (valIdDefault === constS.BLANK) {
      setShowOKMessage("Vui lòng chọn mã cần chỉnh sửa!");
      return;
    }
    if (valCodeDefault === constS.BLANK) {
      setShowOKMessage("Chọn mã để chỉnh sửa!");
      return;
    }

    if (valCodeDefault.length !== Number(codeLength.current) && valCodeDefault !== constS.ZERO) {
      setShowOKMessage("Chưa nhập đủ " + Number(codeLength.current) + " kí tự!");
      return;
    }

    if (valNameDefault === constS.BLANK) {
      setShowOKMessage("Nhập tên để chỉnh sửa!");
      return;
    }

    if (!CheckUpdate()) {
      setShowOKMessage("Mã và tên này đã được đăng kí!");
      return;
    }

    update(ref(db, `/` + tableName.current + `/${valIdDefault}/`), getFormData());
    fetchData(tableName.current);
  }

  const onHandleDelete = () => {
    if (valIdDefault === constS.BLANK) {
      setShowOKMessage("Vui lòng chọn mã cần xóa!");
      return;
    }

    setShowOKCancelMessage("Bạn có chắn chắn xóa không?\nSẽ có thể phát sinh lỗi!");
  }

  const onHandleClear = () => {
    setValIdDefault(constS.BLANK);
    setValCodeDefault(constS.BLANK);
    setValNameDefault(constS.BLANK);
    setValImageDefault(constS.BLANK);
    fetchData(tableName.current);
  }

  const inputIdRef = (e) => {
    setValIdDefault(e.target.value);
  };

  const inputCodeRef = (e) => {
    setValCodeDefault(e.target.value);
  };

  const inputNameRef = (e) => {
    setValNameDefault(e.target.value);
  };

  const inputImageRef = (e) => {
    setValImageDefault(e.target.value);
  };

  const refOK = () => {
    setShowOKMessage(constS.BLANK);
    setShowOKCancelMessage(constS.BLANK);
  };

  const onClickSelected = (val) => {
    setValIdDefault(val.id);
    setValCodeDefault(val.code);
    setValNameDefault(val.name);
    setValImageDefault(val.image);
  };

  const refConfirm = (message) => {
    setShowOKCancelMessage(constS.BLANK);
    if ("Bạn có chắn chắn xóa không?\nSẽ có thể phát sinh lỗi!" === message) {
      remove(ref(db, `/` + tableName.current + `/${valIdDefault}`));
      fetchData(tableName.current);
    }
  };

  const onClickPrev = () => {
    if (currentPage === 1) {
      return;
    }
    const curpage = currentPage - 1;
    const last = Number(curpage) * 10;
    const first = Number(last) - 10;
    const data = [].concat(dataList).splice(first, last - first);
    setCurrentData(data);
    setCurrentPage(curpage);
  };

  const onClickNext = () => {
    if (Number(currentPage) * 10 > dataList.length) {
      return;
    }
    const curpage = Number(currentPage) + 1;
    const last = Number(curpage) * 10 > dataList.length ? dataList.length : Number(curpage) * 10;
    const first = Number(curpage - 1) * 10;
    const data = [].concat(dataList).splice(first, last - first);
    setCurrentData(data);
    setCurrentPage(curpage);
  };

  return [
    <Page title={state}>
      <ExMessageOK message={showOKMessage} refOK={refOK}></ExMessageOK>
      <ExMessageOKCancel message={showOKCancelMessage} refOK={refOK} refConfirm={refConfirm} isMobile={isMobile}></ExMessageOKCancel>
      <Container maxWidth="xl">
        <Typography variant={"h1"}>{state}</Typography>
        <Paper variant="outlined">
          <Grid container spacing={3} sx={{ mt: 0, ml: 0, mb: 2 }}>
            <Grid item md={11.5}>
              <ExTextField
                id={constS.id} inputRef={inputIdRef} valDefault={valIdDefault}
                Icon={<QrCodeOutlinedIcon />} maxLength={50} disabled={true}></ExTextField>
            </Grid>

            <Grid item md={3}>
              <ExNumber
                id={constS.code} inputRef={inputCodeRef} valDefault={valCodeDefault}
                placeholder={"Mã.."} Icon={<FormatListNumberedIcon />} maxLength={codeLength.current || 1}></ExNumber>
            </Grid>
            <Grid item md={8.5}>
              <ExTextField
                id={constS.name} inputRef={inputNameRef} valDefault={valNameDefault}
                placeholder={"Tên.."} Icon={<FormatIndentIncreaseIcon />} maxLength={100}></ExTextField>
            </Grid>
            <Grid item md={11.5}>
              <ExTextField
                id={constS.image} inputRef={inputImageRef} valDefault={valImageDefault} multiline
                placeholder={"Hình.."} Icon={<AccountBoxOutlinedIcon />} maxLength={5000}></ExTextField>
            </Grid>
            <Grid item>
              <ExButtonIconBef title={"Tìm kiếm"} icon='\static\illustrations\search.png' onClick={onHandleSearch} />
            </Grid>
            <Grid item>
              <ExButtonIconBef title={"Đăng kí"} icon='\static\illustrations\add.png' onClick={onHandleAdd} />
            </Grid>
            <Grid item>
              <ExButtonIconBef title={"Chỉnh sửa"} icon='\static\illustrations\edit.png' onClick={onHandleUpdate} />
            </Grid>
            <Grid item>
              <ExButtonIconBef title={"Xóa"} icon='\static\illustrations\delete.png' onClick={onHandleDelete} />
            </Grid>
            <Grid item>
              <ExButtonIconBef title={"Nhập lại"} icon='\static\illustrations\edit.png' onClick={onHandleClear} />
            </Grid>
          </Grid>
        </Paper>

        <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 2 }}>
          {currentData.map((item) => (
            <Grid item md={12}>
              <ExCardCode DataSource={item} onClick={onClickSelected} selectedId={valIdDefault}></ExCardCode>
            </Grid>
          ))}
          <Grid item md={6} sx={{ textAlign: "left" }}>
            <ExButtonIconBef title={"Trang trước"} icon='\static\illustrations\prev.png' onClick={onClickPrev} />
          </Grid>
          <Grid item md={6} sx={{ textAlign: "right" }}>
            <ExButtonIconAft title={"Trang sau"} icon='\static\illustrations\next.png' onClick={onClickNext} />
          </Grid>
        </Grid>

      </Container >
    </Page>
  ];
}
