import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button, Grid } from "@mui/material";
import constS from '../const/constS';

export default function ExOrderCardLast({ isMobile, DataSource, cardName }) {
  const formatter = new Intl.NumberFormat('vi', { style: 'currency', currency: 'VND' });
  let images = [];
  let defaultImage = "";
  if (DataSource.Cols06 && DataSource.Cols06.length > 0) {
    images = DataSource.Cols06.split(constS.END);
    if (images && images.length > 0) {
      defaultImage = images[0];
    }
    else {
      defaultImage = DataSource.Cols06;
    }
  }

  const onOrderCardLastAdd = () => {

  }

  return (
    <Card sx={{ padding: 0, margin: 0, boxShadow: 0, borderBottom: 1, borderRadius: 0 }}>
      <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 0 }}>
        <Grid item md={isMobile ? 2 : 1}>
          <CardMedia
            component="img"
            height={isMobile ? 120 : 80}
            image={defaultImage}
            alt="may shop"
          />
        </Grid>
        {isMobile && <Grid item md={10}>
          <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 0 }}>
            <Grid item md={7} sx={{ fontSize: constS.fontSizeM }}>{cardName}</Grid>
            <Grid item md={5} sx={{ fontSize: constS.fontSizeM }}>
              {formatter.format(DataSource.Cols03)}
            </Grid>
            <Grid item md={5} sx={{ fontSize: constS.fontSizeM, textAlign: "center" }}>{DataSource.Cols04}</Grid>
            <Grid item md={3} sx={{ fontSize: constS.fontSizeM }}>{formatter.format(DataSource.Cols05)}</Grid>
          </Grid>
        </Grid>}
        {!isMobile && <Grid item md={10}>
          <Grid container spacing={1} sx={{ mt: 0, ml: 0, mb: 0, fontSize: constS.fontSize }}>
            <Grid item md={4.6} >{cardName}</Grid>
            <Grid item md={2} >
              {formatter.format(DataSource.Cols03)}
            </Grid>
            <Grid item md={1.8}>{DataSource.Cols04}</Grid>
            <Grid item md={1} >{formatter.format(DataSource.Cols05)}</Grid>
          </Grid>
        </Grid>}
      </Grid>
    </Card >
  );
};