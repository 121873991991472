import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Avatar, Divider, Grid } from '@mui/material';
import constS from '../const/constS';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function ExModalBill({ isShow, inputRef, listBillDetail, dataProductNameCombo, valDefaultKey, valDefault02, valDefault10,
  valDefault08, valDefault03, valDefault04, valDefault05 }) {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  const formatter = new Intl.NumberFormat('vi', { style: 'currency', currency: 'VND' });
  const date = new Date();
  const curDate = date.getDate().toString().padStart(2, "0")
    + "/" + (date.getMonth() + 1).toString().padStart(2, "0")
    + "/" + date.getFullYear().toString();
  const getProductName = (val) => {
    const data = dataProductNameCombo.filter((p) => String(p.code).startsWith(val));
    return data && data.length > 0 ? data[0].name : "";
  };

  return (
    <div>
      {isMobile && <Dialog
        maxWidth={400}
        open={isShow}
        PaperComponent={PaperComponent}
      >
        <DialogTitle style={{ cursor: 'move' }}>
          <Grid container rowSpacing={0} spacing={0} sx={{ m: 0, p: 0, fontSize: isMobile ? constS.fontSizeM : constS.fontSize }}>
            <Grid item md={3}><Avatar sx={{ borderRadius: 0, height: isMobile ? 160 : 80, width: isMobile ? 160 : 80 }} alt="rectangle" src="\static\illustrations\momoQR.png" /></Grid>
            <Grid item md={1}></Grid>
            <Grid item md={8}>
              <Grid container spacing={0} sx={{ m: 0, p: 0, fontSize: isMobile ? 30 : 16 }}>
                <Grid item md={12} sx={{ textAlign: "left" }}>{"Momo : Phước Tính"}</Grid>
                <Grid item md={12} sx={{ textAlign: "left" }}>{"Điện thoại : 0938123358"}</Grid>
                <Grid item md={12} sx={{ textAlign: "left" }}>{"Mã : " + valDefaultKey}</Grid>
              </Grid>
            </Grid>
            <Grid item md={12}><Divider sx={{ border: 1 }} /></Grid>
            <Grid item md={12} sx={{ textAlign: "center" }}>{"HÓA ĐƠN BÁN HÀNG"}</Grid>
            {!isMobile && <Grid item md={12} sx={{ textAlign: "center" }}>{"mayshopkids.com - " + curDate}</Grid>}
            {isMobile && <Grid item md={12} sx={{ textAlign: "center" }}>{"mayshopkids.com"}</Grid>}
            {isMobile && <Grid item md={12} sx={{ textAlign: "center" }}>{curDate}</Grid>}
            <Grid item md={12} sx={{ textAlign: "left" }}>{"Khách hàng : " + (valDefault02.code === constS.ZERO ? valDefault10 : valDefault02.name)}</Grid>
            <Grid item md={12} sx={{ textAlign: "left" }}>{"Điện thoại : " + (valDefault02.code === constS.ZERO ? constS.BLANK : valDefault02.code)}</Grid>
            <Grid item md={12}><Divider sx={{ border: 1 }} /></Grid>
            <Grid item md={4} sx={{ textAlign: "left", fontSize: isMobile ? 30 : 16 }}>{"Đơn giá"}</Grid>
            <Grid item md={4} sx={{ textAlign: "center", fontSize: isMobile ? 30 : 16 }}>{"Số lượng"}</Grid>
            <Grid item md={4} sx={{ textAlign: "right", fontSize: isMobile ? 30 : 16 }}>{"Thành Tiền"}</Grid>
            {listBillDetail.map((item) => (
              <Grid container rowSpacing={0} spacing={0} sx={{ m: 0, p: 0 }}>
                <Grid item md={12} sx={{ textAlign: "left", fontSize: isMobile ? 30 : 16 }}>{getProductName(item.Cols02)}</Grid>
                <Grid item md={4} sx={{ textAlign: "left", fontSize: isMobile ? 30 : 16 }}>{formatter.format(item.Cols03)}</Grid>
                <Grid item md={4} sx={{ textAlign: "center", fontSize: isMobile ? 30 : 16 }}>{item.Cols04}</Grid>
                <Grid item md={4} sx={{ textAlign: "right", fontSize: isMobile ? 30 : 16 }}>{formatter.format(item.Cols05)}</Grid>
              </Grid>
            ))}
            <Grid item md={12}><Divider sx={{ border: 1 }} /></Grid>
            <Grid item md={6} sx={{ textAlign: "left" }}>{"Tạm tính"}</Grid>
            <Grid item md={6} sx={{ textAlign: "right" }}>{formatter.format(valDefault03)}</Grid>
            <Grid item md={6} sx={{ textAlign: "left" }}>{"Giảm giá"}</Grid>
            <Grid item md={6} sx={{ textAlign: "right" }}>{"- " + formatter.format(valDefault04)}</Grid>
            <Grid item md={6} sx={{ textAlign: "left" }}>{"Phí Ship"}</Grid>
            <Grid item md={6} sx={{ textAlign: "right" }}>{"+ " + formatter.format(valDefault08)}</Grid>
            <Grid item md={6} sx={{ textAlign: "left" }}>{"Tổng cộng"}</Grid>
            <Grid item md={6} sx={{ textAlign: "right" }}>{formatter.format(valDefault05)}</Grid>
            <Grid item md={12}><Divider sx={{ border: 1 }} /></Grid>
            <Grid item md={6} sx={{ textAlign: "left" }}>{"Khách trả"}</Grid>
            <Grid item md={6} sx={{ textAlign: "right" }}>{formatter.format(valDefault05)}</Grid>
            <Grid item md={12}><Divider sx={{ border: 1 }} /></Grid>
            <Grid item md={12} sx={{ textAlign: "center" }}>
              <Button onClick={inputRef} sx={{ p: 0, m: 0, fontSize: isMobile ? constS.fontSizeM : constS.fontSize }}>{"Thanh toán tiền mặt hoặc chuyển khoản"}</Button>
            </Grid>
            <Grid item md={12}><Divider sx={{ border: 1 }} /></Grid>
          </Grid>
        </DialogTitle>
      </Dialog>}
      {!isMobile && <Dialog
        open={isShow}
        PaperComponent={PaperComponent}
      >
        <DialogTitle style={{ cursor: 'move' }} sx={{ width: 400 }}>
          <Grid container rowSpacing={0} spacing={0} sx={{ m: 0, p: 0, fontSize: isMobile ? constS.fontSizeM : constS.fontSize }}>
            <Grid item md={3}><Avatar sx={{ borderRadius: 0, height: isMobile ? 160 : 80, width: isMobile ? 160 : 80 }} alt="rectangle" src="\static\illustrations\momoQR.png" /></Grid>
            <Grid item md={1}></Grid>
            <Grid item md={8}>
              <Grid container spacing={0} sx={{ m: 0, p: 0, fontSize: isMobile ? 30 : 16 }}>
                <Grid item md={12} sx={{ textAlign: "left" }}>{"Momo : Phước Tính"}</Grid>
                <Grid item md={12} sx={{ textAlign: "left" }}>{"Điện thoại : 0938123358"}</Grid>
                <Grid item md={12} sx={{ textAlign: "left" }}>{"Mã : " + valDefaultKey}</Grid>
              </Grid>
            </Grid>
            <Grid item md={12}><Divider sx={{ border: 1 }} /></Grid>
            <Grid item md={12} sx={{ textAlign: "center" }}>{"HÓA ĐƠN BÁN HÀNG"}</Grid>
            {!isMobile && <Grid item md={12} sx={{ textAlign: "center" }}>{"mayshopkids.com - " + curDate}</Grid>}
            {isMobile && <Grid item md={12} sx={{ textAlign: "center" }}>{"mayshopkids.com"}</Grid>}
            {isMobile && <Grid item md={12} sx={{ textAlign: "center" }}>{curDate}</Grid>}
            <Grid item md={12} sx={{ textAlign: "left" }}>{"Khách hàng : " + valDefault02.name}</Grid>
            <Grid item md={12} sx={{ textAlign: "left" }}>{"Điện thoại : " + valDefault02.code}</Grid>
            <Grid item md={12}><Divider sx={{ border: 1 }} /></Grid>
            <Grid item md={4} sx={{ textAlign: "left", fontSize: isMobile ? 30 : 16 }}>{"Đơn giá"}</Grid>
            <Grid item md={4} sx={{ textAlign: "center", fontSize: isMobile ? 30 : 16 }}>{"Số lượng"}</Grid>
            <Grid item md={4} sx={{ textAlign: "right", fontSize: isMobile ? 30 : 16 }}>{"Thành Tiền"}</Grid>
            {listBillDetail.map((item) => (
              <Grid container rowSpacing={0} spacing={0} sx={{ m: 0, p: 0 }}>
                <Grid item md={12} sx={{ textAlign: "left", fontSize: isMobile ? 30 : 16 }}>{getProductName(item.Cols02)}</Grid>
                <Grid item md={4} sx={{ textAlign: "left", fontSize: isMobile ? 30 : 16 }}>{formatter.format(item.Cols03)}</Grid>
                <Grid item md={4} sx={{ textAlign: "center", fontSize: isMobile ? 30 : 16 }}>{item.Cols04}</Grid>
                <Grid item md={4} sx={{ textAlign: "right", fontSize: isMobile ? 30 : 16 }}>{formatter.format(item.Cols05)}</Grid>
              </Grid>
            ))}
            <Grid item md={12}><Divider sx={{ border: 1 }} /></Grid>
            <Grid item md={6} sx={{ textAlign: "left" }}>{"Tạm tính"}</Grid>
            <Grid item md={6} sx={{ textAlign: "right" }}>{formatter.format(valDefault03)}</Grid>
            <Grid item md={6} sx={{ textAlign: "left" }}>{"Giảm giá"}</Grid>
            <Grid item md={6} sx={{ textAlign: "right" }}>{"- " + formatter.format(valDefault04)}</Grid>
            <Grid item md={6} sx={{ textAlign: "left" }}>{"Phí Ship"}</Grid>
            <Grid item md={6} sx={{ textAlign: "right" }}>{"+ " + formatter.format(valDefault08)}</Grid>
            <Grid item md={6} sx={{ textAlign: "left" }}>{"Tổng cộng"}</Grid>
            <Grid item md={6} sx={{ textAlign: "right" }}>{formatter.format(valDefault05)}</Grid>
            <Grid item md={12}><Divider sx={{ border: 1 }} /></Grid>
            <Grid item md={6} sx={{ textAlign: "left" }}>{"Khách trả"}</Grid>
            <Grid item md={6} sx={{ textAlign: "right" }}>{formatter.format(valDefault05)}</Grid>
            <Grid item md={12}><Divider sx={{ border: 1 }} /></Grid>
            <Grid item md={12} sx={{ textAlign: "center" }}>
              <Button onClick={inputRef} sx={{ p: 0, m: 0, fontSize: isMobile ? constS.fontSizeM : constS.fontSize }}>{"Thanh toán tiền mặt hoặc chuyển khoản"}</Button>
            </Grid>
            <Grid item md={12}><Divider sx={{ border: 1 }} /></Grid>
          </Grid>
        </DialogTitle>
      </Dialog>}
    </div>
  );
}
