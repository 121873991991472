import PropTypes from 'prop-types';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, CardMedia } from '@mui/material';
// components
import MenuIcon from '@mui/icons-material/Menu';
//
import AccountPopover from './AccountPopover';
import { Fullscreen } from '@mui/icons-material';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 250;
const APPBAR_MOBILE = 160;
const APPBAR_DESKTOP = 160;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)',
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  padding: 0,
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 0),
  },
}));

// ----------------------------------------------------------------------

Navbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function Navbar({ onOpenSidebar }) {
  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton onClick={onOpenSidebar} sx={{ fontSize: 80, mr: 1, color: 'text.primary', display: { lg: 'none' } }}>
          <MenuIcon fontSize='inherit'></MenuIcon>
        </IconButton>

        {/* <Searchbar /> */}
        <CardMedia
          component="img"
          sx={{ height: { md: 160 }, position: 'relative' }}
          image="\static\illustrations\banner.jpg"
          alt="may shop"
        />
        {/* <Box sx={{ flexGrow: 1 }} /> */}

        {/* <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <AccountPopover />
        </Stack> */}
      </ToolbarStyle>
    </RootStyle>
  );
}
