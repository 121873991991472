import * as React from "react";
import * as ReactDOM from "react-dom/client";
import P0001 from "./P0001"
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";

const domNode = document.getElementById('root');
const root = ReactDOM.createRoot(domNode);
root.render(
    <HelmetProvider>
        <BrowserRouter>
            <P0001 />
        </BrowserRouter>
    </HelmetProvider>
);